import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TourListEntry } from '@/components/scheduler/tourListEntry';
import { TourLabel } from '@/types';
import { format } from 'date-fns';
import { Box, Text } from 'grommet';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
export const TourList = inject('dataStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { dataStore } = props;
    const tours = (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getSchedulerTours.filter(t => t.label !== TourLabel.Cleaning)) || [];
    return (_jsxs(Box, Object.assign({ fill: "horizontal", className: "tourList", round: "6px", border: "all", background: "white", height: { max: "320px" } }, { children: [_jsxs(Box, Object.assign({ direction: "row", className: "resource-list-header", border: "bottom", pad: "small", justify: "between", style: { zIndex: 1, minHeight: "50px" } }, { children: [_jsx(Text, Object.assign({ size: "var(--FormComponent-font-lg)" }, { children: t('tour_plural') })), _jsx(Text, Object.assign({ style: { textTransform: "capitalize" }, size: "var(--FormComponent-font-lg)" }, { children: (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) ? format(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate, "EEEE d/M") : null }))] })), _jsx(Box, Object.assign({ className: "tourList-body", pad: "small", direction: "row", gap: "small", wrap: true, height: { max: "420px" }, overflow: "auto" }, { children: tours.map(t => (_jsx(TourListEntry, { tour: t }, t.id))) }))] })));
}));
