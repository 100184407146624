var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction } from "mobx";
export var LoadingStatus;
(function (LoadingStatus) {
    LoadingStatus["Loading"] = "loading";
    LoadingStatus["Finished"] = "finished";
    LoadingStatus["Uninitiated"] = "uninitiated";
    LoadingStatus["Error"] = "error";
})(LoadingStatus || (LoadingStatus = {}));
export class DataPage {
    constructor(_loadPage, _create, data = [], total = 0, page = 0, query = {}, buffer = true, page_size = 10) {
        this._loadPage = _loadPage;
        this._create = _create;
        this.data = data;
        this.total = total;
        this.page = page;
        this.query = query;
        this.buffer = buffer;
        this.page_size = page_size;
        this.max_page = 0;
        this.status = LoadingStatus.Uninitiated;
        this.next = () => __awaiter(this, void 0, void 0, function* () {
            if (this.isLastPage)
                return null;
            let data;
            try {
                if (!this.buffer) {
                    this.status = LoadingStatus.Loading;
                    data = yield this._loadPage(++this.page, this.page_size, this.query);
                    if (!data) {
                        runInAction(() => {
                            this.status = LoadingStatus.Error;
                        });
                        throw new Error(`Failed to load Page ${this.page} ${this.query}`);
                    }
                    runInAction(() => {
                        this.status = LoadingStatus.Finished;
                        this.data = data.data.map(d => this._create(d));
                        this.total = data.total;
                        this.max_page = Math.floor(Math.max(0, this.total - 1) / this.page_size);
                    });
                }
                else if (this._next_page) {
                    this.page++;
                    this._prev_page = this.data;
                    this.data = this._next_page;
                    this._next_page = undefined;
                }
                else {
                    this.status = LoadingStatus.Loading;
                    data = yield this._loadPage(++this.page, this.page_size, this.query);
                    if (!data) {
                        runInAction(() => {
                            this.status = LoadingStatus.Error;
                        });
                        throw new Error(`Failed to load Page ${this.page} ${this.query}`);
                    }
                    runInAction(() => {
                        this.status = LoadingStatus.Finished;
                        this._prev_page = this.data;
                        this.data = data.data.map(d => this._create(d));
                        this.total = data.total;
                        this.max_page = Math.floor(Math.max(0, this.total - 1) / this.page_size);
                    });
                }
                return this.data.length ? this.data : null;
            }
            catch (err) {
                runInAction(() => {
                    this.status = LoadingStatus.Error;
                });
                console.error("Failed to load next page", err);
                return null;
            }
        });
        this.previous = () => __awaiter(this, void 0, void 0, function* () {
            if (!this.page)
                return null;
            let data;
            try {
                if (!this.buffer) {
                    this.status = LoadingStatus.Loading;
                    data = yield this._loadPage(--this.page, this.page_size, this.query);
                    if (!data) {
                        runInAction(() => {
                            this.status = LoadingStatus.Error;
                        });
                        throw new Error(`Failed to load Page ${this.page} ${this.query}`);
                    }
                    runInAction(() => {
                        this.status = LoadingStatus.Finished;
                        this.data = data.data.map(d => this._create(d));
                        this.total = data.total;
                        this.max_page = Math.floor(Math.max(0, this.total - 1) / this.page_size);
                    });
                }
                else if (this._prev_page) {
                    this.page--;
                    this._next_page = this.data;
                    this.data = this._prev_page;
                    this._prev_page = undefined;
                }
                else {
                    this.status = LoadingStatus.Loading;
                    data = yield this._loadPage(--this.page, this.page_size, this.query);
                    if (!data) {
                        runInAction(() => {
                            this.status = LoadingStatus.Error;
                        });
                        throw new Error(`Failed to load Page ${this.page} ${this.query}`);
                    }
                    runInAction(() => {
                        this.status = LoadingStatus.Finished;
                        this._next_page = this.data;
                        this.data = data.data.map(d => this._create(d));
                        this.total = data.total;
                        this.max_page = Math.floor(Math.max(0, this.total - 1) / this.page_size);
                    });
                }
                return this.data.length ? this.data : null;
            }
            catch (err) {
                runInAction(() => {
                    this.status = LoadingStatus.Error;
                });
                console.error("Failed to load prev page", err);
                return null;
            }
        });
        this.loadPage = (page) => __awaiter(this, void 0, void 0, function* () {
            try {
                this.status = LoadingStatus.Loading;
                const data = yield this._loadPage(page, this.page_size, this.query);
                if (!data) {
                    runInAction(() => {
                        this.status = LoadingStatus.Error;
                    });
                    throw new Error(`Failed to load Page ${this.page} ${this.query}`);
                }
                runInAction(() => {
                    this.status = LoadingStatus.Finished;
                    this.page = page;
                    this.data = data.data.map(d => this._create(d));
                    this.total = data.total;
                    this.max_page = Math.floor(Math.max(0, this.total - 1) / this.page_size);
                    this._prev_page = undefined;
                    this._next_page = undefined;
                });
                return true;
            }
            catch (err) {
                runInAction(() => {
                    this.status = LoadingStatus.Error;
                });
                console.error("Failed to load page number " + page, err);
                return false;
            }
        });
        this.refresh = () => __awaiter(this, void 0, void 0, function* () {
            return this.loadPage(this.page);
        });
        this.setQuery = (query, reload) => __awaiter(this, void 0, void 0, function* () {
            this.query = query;
            this._next_page = this._prev_page = undefined;
            if (reload)
                return this.loadPage(0);
            return true;
        });
        this.setQueryValue = (prop, value, reload) => __awaiter(this, void 0, void 0, function* () {
            return this.setQuery(Object.assign(Object.assign({}, this.query), { [prop]: value }), reload);
        });
        this.setPageSize = (pageSize) => __awaiter(this, void 0, void 0, function* () {
            this.page_size = pageSize;
            return this.loadPage(0);
        });
        makeAutoObservable(this);
        this.max_page = Math.floor(Math.max(0, this.total - 1) / page_size);
    }
    get isLastPage() {
        return this.page === this.max_page;
    }
    ;
    get isLoading() {
        return this.status === LoadingStatus.Loading;
    }
}
