import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Box, Text } from "grommet";
import { format } from "date-fns";
import { toCap } from "../../util";
import { sv, de, enUS } from 'date-fns/esm/locale';
const dateLocales = { en: enUS, sv: sv, de: de };
export const WeekCalendarDay = inject('dataStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { dataStore, day, week, selected, onClick, count = 0, complete, year, date } = props;
    return (_jsxs(Box, Object.assign({ hoverIndicator: true, onClick: onClick, className: "weekCalendar-day", pad: {
            top: "xsmall",
            horizontal: "xsmall"
        }, round: "6px", height: "70px", width: "70px", border: {
            side: "all",
            size: "xsmall",
        }, gap: "xsmall", background: selected ? "brand" : complete ? "status-ok-light" : "white", margin: {
            bottom: "2px"
        } }, { children: [_jsxs(Box, Object.assign({ direction: "row", justify: "between", align: 'start' }, { children: [_jsx(Text, Object.assign({ size: "xsmall" }, { children: toCap(format(date, 'E')) })), _jsx(Box, Object.assign({ background: selected ? "white" : "light-3", align: "center", round: "50%", height: "18px", width: "18px" }, { children: _jsx(Text, Object.assign({ size: 'xsmall' }, { children: count > 9 ? "9+" : count })) }))] })), _jsx(Box, Object.assign({ className: "weekCalendar-day-body", justify: "center", fill: "horizontal", align: "center", pad: {
                    bottom: "small",
                } }, { children: _jsx(Text, Object.assign({ weight: "bold", size: "var(--FormComponent-font-lg)" }, { children: format(date, "d/M") })) }))] })));
}));
