var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useDrop } from 'react-dnd';
import { Box, Text } from "grommet";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { TeamCardSection } from "./teamCardSection";
import { TourProps, UserProps, VehicleProps, DataType } from "../../types";
import { inject, observer } from "mobx-react";
import toast from "react-hot-toast";
import { CheckmarkCircle02Icon, Delete02Icon } from "hugeicons-react";
export const TeamCard = inject("teamStore", "userStore")(observer((props) => {
    const { t } = useTranslation();
    const { team, teamStore, readonly } = props;
    const [{ isOver }, drop] = useDrop({
        accept: [DataType.Link, DataType.Tour, DataType.Vehicle, DataType.User],
        drop: (data) => {
            if (data.type === DataType.Link) {
                const item = data.item;
                if (item.team.id === team.id)
                    return;
                teamStore === null || teamStore === void 0 ? void 0 : teamStore.link_data(item.type, team.id, item.data.id);
                teamStore === null || teamStore === void 0 ? void 0 : teamStore.unlink_data(item.type, item.team.id, item.data.id);
            }
            else {
                const item = data.item;
                teamStore === null || teamStore === void 0 ? void 0 : teamStore.link_data(data.type, team.id, item.id);
            }
        },
        collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    });
    const [editName, setEditName] = React.useState(false);
    const [name, setName] = React.useState(team.name || '');
    const [isNotified, setNotified] = React.useState(team.notified);
    const inputRef = React.useRef(null);
    const tours = (team && team.getTeamTours) || [];
    const users = (team && team.getTeamUsers) || [];
    const vehicles = (team && team.getTeamVehicles) || [];
    tours.sort((a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime());
    const data = {
        tours: tours.map(t => ({
            [TourProps.Title]: t.title,
            [TourProps.Start_Time]: t.start_time && format(t.start_time, "HH:mm"),
            [TourProps.End_Time]: t.end_time && format(t.end_time, "HH:mm"),
            data: t,
            type: DataType.Tour,
            team,
            from: null,
            location: undefined,
            [UserProps.FirstName]: null,
            [UserProps.License]: null,
            [VehicleProps.Name]: null,
            vehicleLicense: null,
        })) || [],
        users: users.map(u => {
            var _a;
            const fromValue = (_a = team.user_info[u.id]) === null || _a === void 0 ? void 0 : _a.from;
            const fromDate = (fromValue !== undefined && fromValue !== null) ? new Date(fromValue) : null;
            return ({
                [UserProps.FirstName]: u.alias || u.first_name || u.last_name,
                [UserProps.License]: u.license,
                from: fromDate,
                location: team.user_info[u.id] && team.user_info[u.id].location,
                data: u,
                type: DataType.User,
                team,
                [VehicleProps.Name]: null,
                [TourProps.Title]: null,
                [TourProps.Start_Time]: null,
                [TourProps.End_Time]: null,
            });
        }) || [],
        vehicles: vehicles.map(v => ({
            [VehicleProps.Name]: v.name,
            [VehicleProps.License]: v.license,
            data: v,
            type: DataType.Vehicle,
            team,
            from: null,
            [TourProps.Title]: null,
            [TourProps.Start_Time]: null,
            [TourProps.End_Time]: null,
            [UserProps.FirstName]: null
        })) || [],
    };
    const handleEnterKey = (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (event.key === 'Enter') {
            event.preventDefault();
            if (!Boolean(name)) {
                setEditName(false);
                setName(((_a = team === null || team === void 0 ? void 0 : team.name) === null || _a === void 0 ? void 0 : _a.toString()) || '');
            }
            else {
                setEditName(false);
                team.setName(name);
            }
        }
    });
    return (_jsxs(Box, Object.assign({ round: "6px", width: "350px", height: "medium", border: "all", elevation: "medium", overflow: "auto", margin: { bottom: "small" }, ref: drop }, { children: [_jsxs(Box, Object.assign({ round: { corner: "top", size: "6px" }, background: isOver ? "accent-1" : "brand", pad: { horizontal: "small", vertical: "xsmall" }, flex: false, justify: "between", direction: "row", onDoubleClick: () => !readonly && setEditName(true) }, { children: [editName ?
                        _jsx("input", { autoFocus: true, ref: inputRef, className: "team-card-name-input", value: name, onChange: (e) => setName(e.target.value), onKeyDown: handleEnterKey, onBlur: () => {
                                setEditName(false);
                                team.setName(name);
                            } })
                        : _jsx(Text, Object.assign({ size: "var(--FormComponent-font-lg)" }, { children: name })), _jsxs(Box, Object.assign({ direction: "row", gap: "xsmall" }, { children: [_jsx(Box, Object.assign({ hoverIndicator: true, alignSelf: "center", background: isNotified ? "green" : "status-critical", style: { borderRadius: "15px" }, onClick: () => {
                                    if (readonly)
                                        return;
                                    setNotified(team.toggleNotified());
                                }, onDoubleClick: (e) => e.stopPropagation() }, { children: _jsx(CheckmarkCircle02Icon, { size: 18 }) })), !readonly &&
                                _jsx(Box, Object.assign({ hoverIndicator: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () { return yield team.remove().catch(() => toast.error(t("could_not_delete_team"))); }), alignSelf: "center" }, { children: _jsx(Delete02Icon, { size: 18 }) }))] }))] })), _jsx(TeamCardSection, { columns: [TourProps.Title, TourProps.Start_Time, TourProps.End_Time], columnLabels: [t("tour_plural"), t("start"), t("to")], data: data["tours"], readonly: readonly }), _jsx(TeamCardSection, { columns: [UserProps.FirstName, UserProps.License, "from", "location"], columnLabels: [t("worker_plural"), t("_license"), t("from"), t("location")], columnTypes: ["text", "text", "time", "input"], data: data["users"], readonly: readonly }), _jsx(TeamCardSection, { columns: [VehicleProps.Name, "license"], columnLabels: [t("vehicle_plural"), t("_license")], data: data["vehicles"], readonly: readonly })] }), team.id));
}));
