import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
const resources = {
    en: { "translation": require('../locales/en.json') },
    de: { "translation": require('../locales/de.json') },
    sv: { "translation": require('../locales/sv.json') }
};
const detection_options = {};
i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
    resources,
    detection: detection_options,
    fallbackLng: 'en',
    supportedLngs: ["en", "sv", "de"],
    interpolation: {
        escapeValue: false,
    },
});
export default i18next;
