var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from 'grommet';
import { Document, Download } from 'grommet-icons';
import { useTranslation } from 'react-i18next';
export const FileComment = (props) => {
    const { t, i18n } = useTranslation();
    const { file, hideDownload } = props;
    const getSizeString = () => {
        if (file.size === null)
            return "";
        if (file.size < 1000)
            return `${file.size} B`;
        else if (file.size < 1000000)
            return `${file.size / 1000} kB`;
        else if (file.size < 1000 * 1000 * 1000)
            return `${file.size / (1000 * 1000)} MB`;
        else
            return `${file.size / (1000 * 1000 * 1000)} GB`;
    };
    return (_jsxs(Box, Object.assign({ className: "comment-file", align: "center", background: "light-4", direction: "row", gap: "small", pad: "xsmall", round: "4px", margin: { bottom: "xsmall" } }, { children: [_jsxs(Box, Object.assign({ direction: "row", align: "center", gap: "xsmall" }, { children: [_jsxs(Box, Object.assign({ align: "center" }, { children: [_jsx(Document, {}), _jsx(Text, Object.assign({ truncate: true, size: "xsmall" }, { children: getSizeString() }))] })), _jsx(Text, Object.assign({ truncate: true, size: "small" }, { children: file.name }))] })), !hideDownload &&
                _jsx(Box, Object.assign({ hoverIndicator: true, onClick: (event) => __awaiter(void 0, void 0, void 0, function* () {
                        event.stopPropagation();
                        const url = yield file.getURL();
                        window.open(url);
                    }) }, { children: _jsx(Download, {}) }))] })));
};
