var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PaginationFooter } from '@/components/pagination/paginationFooter';
import client from '@/feathers';
import { User } from '@/models';
import { DataPage } from '@/models/dataPage';
import { UserRole } from '@/types';
import { Box, Button, Heading, Text } from 'grommet';
import { Add01Icon, ArrowDown02Icon, ArrowUp02Icon } from 'hugeicons-react';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';
const CapitalizedSpan = styled.span `
  text-transform: capitalize;
`;
export const UsersList = inject('userStore')(observer((props) => {
    const { userStore } = props;
    const { t } = useTranslation();
    const [sorting, setSorting] = React.useState([
        { id: 'first_name', desc: false },
    ]);
    const [query, setQuery] = React.useState({
        role: { $in: [UserRole.Admin, UserRole.Sales, UserRole.Default] },
        $sort: { first_name: 1 },
    });
    const [page, setPage] = React.useState(new DataPage(loadUserPage, User.create, [], 0, 0, query, true, 20));
    React.useEffect(() => {
        page.setQuery(query, true);
    }, [query]);
    React.useEffect(() => {
        const sortObj = sorting.reduce((acc, sort) => {
            acc[sort.id] = sort.desc ? -1 : 1;
            return acc;
        }, {});
        setQuery((prevQuery) => (Object.assign(Object.assign({}, prevQuery), { $sort: sortObj })));
    }, [sorting]);
    React.useEffect(() => {
        page.loadPage(page.page);
    }, []);
    const users = page.data;
    const formatValue = (property, item) => {
        switch (property) {
            case 'email':
                return _jsx(_Fragment, { children: item.email });
            case 'fullName':
                return _jsx(CapitalizedSpan, { children: item.fullName });
            case 'role':
                return _jsx(CapitalizedSpan, { children: t(item.role) });
            case 'office_id':
                return (_jsx(CapitalizedSpan, { children: _jsx(Text, Object.assign({ truncate: true }, { children: item.getLinkedOffices.length
                            ? item.getLinkedOffices.map((o) => o && o.name).join(', ')
                            : 'N/A' })) }));
            case 'license':
                return _jsx(CapitalizedSpan, { children: item[property] });
            case 'phone':
                return _jsx(CapitalizedSpan, { children: item[property] });
            case 'alias':
                return _jsx(CapitalizedSpan, { children: item[property] });
            default:
                return (_jsx(CapitalizedSpan, { children: t(item[property]) }));
        }
    };
    const columns = React.useMemo(() => COLUMNS.map((c) => ({
        accessorKey: c.property,
        header: t(c.label),
        footer: c.footer ? c.footer : undefined,
        cell: (info) => formatValue(c.property, info.row.original),
    })), [t]);
    const table = useReactTable({
        data: users,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        manualSorting: true,
    });
    const start = page.page * page.page_size + 1;
    const end = Math.min((page.page + 1) * page.page_size, page.total);
    return (_jsxs(Box, Object.assign({ className: 'userList', alignSelf: "start", alignContent: "start", pad: { vertical: 'medium', horizontal: 'medium' }, width: "xxlarge" }, { children: [_jsxs(Box, Object.assign({ align: "center", direction: 'row', justify: 'between', pad: { bottom: 'medium' } }, { children: [_jsx(Heading, Object.assign({ level: "3", margin: "none", responsive: true }, { children: t('user_plural') })), _jsx(Box, Object.assign({ align: "end" }, { children: _jsx(Button, { primary: true, icon: _jsx(Add01Icon, { color: "white" }), label: t('create_new_user'), onClick: () => {
                                userStore === null || userStore === void 0 ? void 0 : userStore.setActiveUser(User.create());
                            } }) }))] })), _jsxs(Box, Object.assign({ fill: true }, { children: [_jsx(Box, Object.assign({ margin: { vertical: 'small' } }, { children: _jsx(Text, { children: `Showing ${start}-${end} of ${page.total}` }) })), _jsx("div", Object.assign({ className: 'table-wrapper' }, { children: _jsxs("table", Object.assign({ className: 'table', style: { width: '100%', fontSize: 'inherit' } }, { children: [_jsx("thead", Object.assign({ className: 'table-head' }, { children: table.getHeaderGroups().map((headerGroup) => (_jsx("tr", { children: headerGroup.headers.map((header) => {
                                            var _a;
                                            return (_jsxs("th", Object.assign({ onClick: header.column.getToggleSortingHandler(), style: {
                                                    cursor: 'pointer',
                                                    userSelect: 'none',
                                                    textAlign: 'left',
                                                    padding: '8px',
                                                } }, { children: [flexRender(header.column.columnDef.header, header.getContext()), (_a = {
                                                        asc: _jsx(ArrowUp02Icon, { height: 16, width: 16, style: { marginLeft: '8px' } }),
                                                        desc: _jsx(ArrowDown02Icon, { height: 16, width: 16, style: { marginLeft: '8px' } }),
                                                    }[header.column.getIsSorted()]) !== null && _a !== void 0 ? _a : null] }), header.id));
                                        }) }, headerGroup.id))) })), _jsx("tbody", Object.assign({ className: 'table-body' }, { children: table.getRowModel().rows.map((row) => (_jsx("tr", Object.assign({ onClick: () => userStore === null || userStore === void 0 ? void 0 : userStore.setActiveUser(row.original), className: "resource-row", style: { cursor: 'pointer' } }, { children: row.getVisibleCells().map((cell) => (_jsx("td", Object.assign({ style: {
                                                textAlign: 'left',
                                                padding: '8px',
                                            } }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id))) }), row.id))) })), _jsx("tfoot", Object.assign({ className: 'table-footer' }, { children: table.getFooterGroups().map((footerGroup) => (_jsx("tr", { children: footerGroup.headers.map((header) => (_jsx("td", Object.assign({ style: { textAlign: 'left', padding: '8px' } }, { children: flexRender(header.column.columnDef.footer, header.getContext()) }), header.id))) }, footerGroup.id))) }))] })) })), _jsx(PaginationFooter, { page: page, totalPages: page.max_page + 1, onChangePage: (newPage) => page.loadPage(newPage), selectPageSize: true })] }))] })));
}));
const COLUMNS = [
    {
        property: 'first_name',
        label: 'first_name',
        dataScope: 'row',
        format: '',
    },
    {
        property: 'last_name',
        label: 'last_name',
        dataScope: 'row',
        format: '',
    },
    {
        property: 'alias',
        label: 'alias',
    },
    {
        property: 'email',
        label: 'email',
    },
    {
        property: 'phone',
        label: 'phone',
    },
    {
        property: 'license',
        label: 'driver_license',
    },
    {
        property: 'role',
        label: 'role',
    },
    {
        property: 'office_id',
        label: 'office',
        footer: '',
    },
    {
        property: 'status',
        label: 'status',
        align: 'end',
        footer: '',
    },
];
const loadUserPage = (page, page_size, query) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        return yield client.service('users').find({
            query: Object.assign({ $limit: page_size, $skip: page * page_size }, (query || {})),
        });
    }
    catch (err) {
        console.error('Failed to load page,', err);
        return null;
    }
});
