var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const toCap = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
const emptyList = [undefined, null, ""];
export const deepComp = (obj1, obj2, emptyStringAsUndefined) => {
    if (obj1 === obj2)
        return true;
    if (!(Boolean(obj1) && Boolean(obj2)) ||
        !(typeof obj1 === 'object' && typeof obj2 === 'object')) {
        if (emptyStringAsUndefined && emptyList.includes(obj1) && emptyList.includes(obj2))
            return true;
        return obj1 === obj2;
    }
    const key1 = Object.keys(obj1);
    const key2 = Object.keys(obj2);
    if (key1.length !== key2.length)
        return false;
    for (const k of key1)
        if (!deepComp(obj1[k], obj2[k]))
            return false;
    return true;
};
export const uniqObj = (arr) => {
    const idxOf = (arr, el) => {
        return arr.findIndex((v, idx) => v["id"] === el["id"]);
    };
    return arr.filter((el, idx, arr) => Boolean(el) && idxOf(arr, el) === idx);
};
export const arrToObj = (arr, id_prop = "id") => {
    return arr.reduce((res, val) => {
        if (!val[id_prop])
            return res;
        res[Number(val[id_prop])] = val;
        return res;
    }, {});
};
export const arrToObjAgg = (arr, id_prop = "id") => {
    return arr.reduce((res, val) => {
        if (!val[id_prop])
            return res;
        res[Number(val[id_prop])] = res[Number(val[id_prop])] || [];
        res[Number(val[id_prop])].push(val);
        return res;
    }, {});
};
export const sleep = (delay) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise(resolve => setTimeout(resolve, delay));
});
