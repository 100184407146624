import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import authentication from '@feathersjs/authentication-client';
import io from 'socket.io-client';
const host = process.env.API_URL || 'http://localhost:3030';
console.log('connect to socket host', host);
export const socket = io(host, {
    transports: ['websocket'],
    forceNew: true,
    timeout: 10000
});
socket.on("connect", () => {
    console.log('Socket.io connected');
});
socket.on("reconnect", (attempt) => {
    console.log('Socket.io reconnect attempt', attempt);
});
socket.on("error", (error) => {
    console.log('Socket.io error', error);
});
socket.on("connect_error", (error) => {
    console.log('Socket.io connect_error', error);
});
socket.on("connect_error", (error) => {
    console.error('Socket.io connect_error', error);
    console.error('Socket.io connect_error message', error.message);
    console.error('Socket.io connect_error type', error.type);
    console.error('Socket.io connect_error description', error.description);
});
const client = feathers();
client.configure(socketio(socket, { timeout: 45000 }));
client.configure(authentication({
    storage: window.localStorage
}));
export default client;
