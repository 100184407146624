import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
export const Menu = (props) => {
    const itemsRef = React.useRef(null);
    const clickEventListener = (event) => {
        const currentRef = itemsRef.current;
        if (currentRef) {
            if (currentRef.style.display === "inline-block") {
                currentRef.style.display = "none";
            }
        }
    };
    React.useEffect(() => {
        window.addEventListener('click', clickEventListener, true);
        return () => {
            window.removeEventListener('click', clickEventListener, true);
        };
    }, []);
    return (_jsxs("div", Object.assign({ className: "comment-menu-base", onClick: (event) => {
            if (itemsRef.current) {
                itemsRef.current.style.display = "inline-block";
                itemsRef.current.scrollIntoView({ behavior: "smooth" });
            }
        } }, { children: [props.children, _jsx("div", Object.assign({ className: "comment-menu-items", ref: itemsRef }, { children: props.items.map(i => (_jsx("div", Object.assign({ className: "comment-menu-item", onClick: (event) => {
                        if (itemsRef.current) {
                            itemsRef.current.style.visibility = "hidden";
                            i.onClick(event);
                            event.stopPropagation();
                        }
                    } }, { children: _jsx("span", { children: i.label }) }), i.label))) }))] })));
};
