import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "grommet";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Vehicle } from "../../../models";
import { VehiclesList } from "./vehicleList";
import { VehicleEdit } from "./vehicleEdit";
export const Vehicles = inject("dataStore")(observer((props) => {
    const { t, i18n } = useTranslation();
    const { dataStore } = props;
    const vehicles = Object.values((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getVehicles) || {});
    vehicles.sort(Vehicle.compareForResources);
    return (_jsxs(Box, Object.assign({ fill: true, direction: "row", align: "stretch" }, { children: [_jsx(VehiclesList, {}), _jsx(VehicleEdit, {})] })));
}));
