var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Data } from './';
import { DataType } from './../types';
import { observable, makeObservable, computed, runInAction, action, set, makeAutoObservable } from 'mobx';
import { isPast, formatISO, format, isEqual, isThisWeek } from 'date-fns';
import client from '../feathers';
import { deepComp } from '../util';
import { rootStore } from '../app';
export class Tour extends Data {
    constructor(data) {
        super(DataType.Tour, data);
        this.customer = null;
        this.date = null;
        this.desc = null;
        this.end_time = null;
        this.invoiced = false;
        this.notified = false;
        this.label = null;
        this.lime_id = null;
        this.lime_data = null;
        this.max_users = 0;
        this.max_vehicles = 0;
        this.office_id = null;
        this.start_time = null;
        this.title = null;
        this.volume = null;
        this.team_links = [];
        this.num_comments = 0;
        this.to = {};
        this.from = {};
        this.awaiting_overwrite = false;
        this.overwrite_block = {};
        this.toggleNotified = () => {
            this.notified = !this.notified;
        };
        this.setCustomerData = (target, key, value) => {
            this[target][key] = value;
        };
        this.overwrite = () => {
            if (!this.lime_data) {
                this.awaiting_overwrite = false;
                return;
            }
            for (const key of Object.keys(this.lime_data["from"])) {
                if (!this.overwrite_block["from"] || this.overwrite_block["from"][key] || !Boolean(this.lime_data["from"][key]))
                    continue;
                set(this.from, key, this.lime_data["from"][key]);
            }
            for (const key of Object.keys(this.lime_data["to"])) {
                if (!this.overwrite_block["to"] || this.overwrite_block["to"][key] || !Boolean(this.lime_data["to"][key]))
                    continue;
                set(this.to, key, this.lime_data["to"][key]);
            }
            this.invoiced = Boolean(this.lime_data.details.invoiced);
            this.volume = this.lime_data.details.volume;
            this.awaiting_overwrite = false;
        };
        this.addTeam = (id) => {
            this.team_links.push(id);
        };
        this.removeTeam = (id) => {
            this.team_links = this.team_links.filter(l => l !== id);
        };
        makeObservable(this, {
            awaiting_overwrite: observable,
            customer: observable,
            date: observable,
            desc: observable,
            end_time: observable,
            invoiced: observable,
            notified: observable,
            label: observable,
            lime_id: observable,
            num_comments: observable,
            office_id: observable,
            start_time: observable,
            title: observable,
            team_links: observable,
            volume: observable,
            max_users: observable,
            max_vehicles: observable,
            from: observable,
            to: observable,
            isDelayed: computed,
            isNew: computed,
            isComplete: computed,
            getTourTeams: computed,
            getTourUsers: computed,
            getTourVehicles: computed,
            hasChanged: computed,
            overwrite: action,
            addTeam: action,
            toggleNotified: action,
            removeTeam: action,
            setLimeId: action,
            clone: action,
            move: action,
            setCustomerData: action,
        });
        makeAutoObservable(this.overwrite_block);
        if (data)
            this.fromData(data);
    }
    static create(data) {
        const defaultTour = Object.assign(Object.assign({}, Data.defaultValues), { desc: null, end_time: null, from: {}, notified: false, label: null, lime_id: null, lime_data: null, max_users: 0, max_vehicles: 0, start_time: null, to: {}, volume: null, num_comments: null, awaiting_overwrite: false, customer: null, date: new Date(), office_id: null, title: "", invoiced: false, team_links: [] });
        return new Tour(Object.assign(defaultTour, data));
    }
    fromData(data) {
        super.fromData(data);
        this.customer = data.customer;
        this.date = data.date ? new Date(data.date) : null;
        this.desc = data.desc;
        this.end_time = data.end_time ? new Date(data.end_time) : null;
        this.invoiced = data.invoiced;
        this.label = data.label;
        this.lime_id = data.lime_id ? Number(data.lime_id) : null;
        this.office_id = data.office_id ? Number(data.office_id) : null;
        this.start_time = data.start_time ? new Date(data.start_time) : null;
        this.title = data.title;
        this.volume = data.volume;
        this.team_links = data.team_links ? data.team_links.map(l => Number(l)) : [];
        this.lime_data = data.lime_data;
        this.num_comments = data.num_comments || 0;
        this.max_vehicles = Number(data.max_vehicles || 0);
        this.max_users = Number(data.max_users || 0);
        this.notified = Boolean(data.notified);
        if (data.to && typeof data.to === 'object') {
            Object.keys(data.to).forEach((k) => {
                if (data.to[k] !== undefined) {
                    set(this.to, k, data.to[k]);
                }
            });
        }
        if (data.from && typeof data.from === 'object') {
            Object.keys(data.from).forEach((k) => {
                if (data.from[k] !== undefined) {
                    set(this.from, k, data.from[k]);
                }
            });
        }
    }
    toData() {
        return Object.assign(Object.assign({}, super.toData()), { date: this.date ? formatISO(this.date, { representation: "date" }) : undefined, start_time: this.start_time ? formatISO(this.start_time) : undefined, end_time: this.end_time ? formatISO(this.end_time) : undefined, customer: this.customer, desc: this.desc, from: (Boolean(Object.keys(this.from).length) && this.from) || undefined, invoiced: this.invoiced, label: this.label, lime_id: this.lime_id, office_id: this.office_id, title: this.title, to: (Boolean(Object.keys(this.to).length) && this.to) || undefined, volume: this.volume, search_index: "", max_users: this.max_users, notified: this.notified, max_vehicles: this.max_vehicles });
    }
    get isDelayed() {
        if (!this.date)
            return false;
        return !this.invoiced && isPast(this.date) && !isThisWeek(this.date);
    }
    get isNew() {
        return !Boolean(this.id);
    }
    get getTourTeams() {
        return this.team_links.map(t => rootStore.teamStore.getTeam(t)).filter(t => Boolean(t));
    }
    get getTourVehicles() {
        return this.getTourTeams
            .map(t => t.getTeamVehicles)
            .filter((v, idx, arr) => v && arr.indexOf(v) === idx)
            .flat();
    }
    get getTourUsers() {
        return this.getTourTeams
            .map(t => t.getTeamUsers)
            .filter((u, idx, arr) => u && arr.indexOf(u) === idx)
            .flat();
    }
    isBefore(tour) {
        if (!this.start_time)
            return -1;
        const strLhs = format(this.start_time, "HH:mm") + (this.end_time ? format(this.end_time, "HH:mm") : "00:00");
        if (!tour.start_time)
            return 1;
        const strRhs = format(tour.start_time, "HH:mm") + (tour.end_time ? format(tour.end_time, "HH:mm") : "00:00");
        return strLhs < strRhs ? -1 : (strLhs > strRhs ? 1 : 0);
    }
    get isComplete() {
        return (this.getTourUsers.length >= this.max_users &&
            this.getTourUsers.length >= 1 &&
            this.getTourVehicles.length >= this.max_vehicles);
    }
    get hasChanged() {
        if (!this.id || !this.date)
            return false;
        const tour = rootStore.dataStore.tours[this.id];
        if (!tour || !tour.date)
            return true;
        return (!isEqual(this.date, tour.date) ||
            !this.start_time && tour.start_time ||
            this.start_time && !tour.start_time ||
            (this.start_time && tour.start_time && !isEqual(this.start_time, tour.start_time)) ||
            !this.end_time && tour.end_time ||
            this.end_time && !tour.end_time ||
            (this.end_time && tour.end_time && !isEqual(this.end_time, tour.end_time)) ||
            this.customer !== tour.customer ||
            this.desc !== tour.desc ||
            this.invoiced !== tour.invoiced ||
            this.label !== tour.label ||
            this.lime_id !== tour.lime_id ||
            this.office_id !== tour.office_id ||
            this.title !== tour.title ||
            !deepComp(this.from, tour.from, true) ||
            !deepComp(this.to, tour.to, true) ||
            this.volume !== tour.volume ||
            this.max_users !== tour.max_users ||
            this.max_vehicles !== tour.max_vehicles ||
            this.notified !== tour.notified);
    }
    setLimeId(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield client.service('lime').find({
                    query: {
                        tourId: this.id,
                        limeId: id,
                    },
                });
                runInAction(() => {
                    if (Boolean(result[0].data) && !deepComp(this.lime_data || {}, result[0].data)) {
                        this.awaiting_overwrite = true;
                    }
                    else if (!Boolean(result[0].data)) {
                        this.awaiting_overwrite = false;
                    }
                    this.lime_id = id;
                    this.lime_data = result[0].data;
                });
                return result[0].success;
            }
            catch (error) {
                console.error(error);
            }
            return false;
        });
    }
    clone(office_id, date) {
        return __awaiter(this, void 0, void 0, function* () {
            const clone = Tour.create(Object.assign(Object.assign({}, this), { id: undefined, date, office_id, updated_by: undefined }));
            yield clone.save();
            return clone;
        });
    }
    move(office_id, date) {
        return __awaiter(this, void 0, void 0, function* () {
            this.office_id = office_id;
            this.date = date;
            yield this.save();
        });
    }
}
