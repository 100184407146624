var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Heading, Box, TextInput, Button, Text, FormField } from "grommet";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../routes";
import { inject, observer } from "mobx-react";
import { UserCircle02Icon } from "hugeicons-react";
import { useNavigate } from 'react-router-dom';
const AcceptInvite = inject("userStore")(observer((props) => {
    const { t } = useTranslation();
    const { userStore } = props;
    const [password, setPassword] = React.useState("");
    const [password2, setPassword2] = React.useState("");
    const [token, setToken] = React.useState("");
    const [message, setMessage] = React.useState("");
    const navigate = useNavigate();
    useEffect(() => {
        var _a;
        const { match } = props;
        const token = (_a = match === null || match === void 0 ? void 0 : match.params) === null || _a === void 0 ? void 0 : _a.token;
        setToken(token);
    }, [props]);
    const submit = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        if (token && userStore) {
            const result = yield userStore.acceptInvite(token, password);
            if (result)
                navigate(AppRoute.Settings);
            setMessage(result);
        }
    });
    const isPasswordLongEnough = password.length > 6;
    const hasNumber = /\d/.test(password);
    const passwordsMatch = password === password2;
    const valid = isPasswordLongEnough && hasNumber && passwordsMatch;
    if (!token)
        return null;
    return (_jsxs(Box, Object.assign({ margin: "auto", fill: "horizontal", animation: "fadeIn", alignContent: "center", pad: "large" }, { children: [_jsx(Heading, Object.assign({ size: "small", textAlign: "center" }, { children: t("accept_invite") })), _jsx(Text, Object.assign({ alignSelf: "center", margin: "medium", size: "xsmall" }, { children: t("password_requirements") })), _jsx(FormField, Object.assign({ label: t("enter_password"), margin: "small" }, { children: _jsx(TextInput, { type: "password", placeholder: t("password"), value: password, icon: _jsx(UserCircle02Icon, {}), onChange: (e) => setPassword(e.target.value) }) })), _jsx(FormField, Object.assign({ label: t("repeat_password"), margin: "small" }, { children: _jsx(TextInput, { type: "password", placeholder: t("password"), value: password2, icon: _jsx(UserCircle02Icon, {}), onChange: (e) => setPassword2(e.target.value) }) })), _jsxs(Box, Object.assign({ direction: "row", gap: "large", align: "center", alignContent: "center", margin: "auto" }, { children: [_jsx(Button, { style: { fontSize: "14px", fontWeight: "normal" }, color: "#053162", type: "submit", primary: true, label: t("select"), onClick: submit, disabled: !valid }), _jsx(Text, { children: message })] }))] })));
}));
export default AcceptInvite;
