import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Anchor, Box, Button, Card, CardBody, CardFooter, CardHeader, Layer, Text } from 'grommet';
import { Alert, CircleInformation, Info } from 'grommet-icons';
export var DialogType;
(function (DialogType) {
    DialogType[DialogType["Warning"] = 0] = "Warning";
    DialogType[DialogType["Confirmation"] = 1] = "Confirmation";
    DialogType[DialogType["Information"] = 2] = "Information";
    DialogType[DialogType["None"] = 3] = "None";
})(DialogType || (DialogType = {}));
export const Dialog = (props) => {
    const { show, title, message, confirmButtonLabel, cancelButtonLabel, type, onConfirm, onCancel } = props;
    const icon = (() => {
        switch (type) {
            case DialogType.Information:
                return _jsx(Info, {});
            case DialogType.Confirmation:
                return _jsx(CircleInformation, {});
            case DialogType.Warning:
                return _jsx(Alert, { size: "large", color: "status-critical" });
            default:
                return _jsx(_Fragment, {});
        }
    })();
    if (!show)
        return _jsx(_Fragment, {});
    return (_jsx(Layer, { children: _jsxs(Card, Object.assign({ width: "medium", round: "4px", background: "brand" }, { children: [_jsx(CardHeader, Object.assign({ pad: "small", background: "brand" }, { children: _jsx(Text, Object.assign({ size: "var(--FormComponent-font-lg)" }, { children: title })) })), _jsxs(CardBody, Object.assign({ direction: "row", pad: "medium", background: "light-2", align: "center" }, { children: [icon, _jsx(Box, { alignSelf: "stretch", width: "24px" }), _jsx(Text, { children: message })] })), _jsxs(CardFooter, Object.assign({ direction: "row", pad: "small", justify: "start", background: "light-2" }, { children: [confirmButtonLabel &&
                            _jsx(Button, Object.assign({ primary: true, onClick: onConfirm, size: "small", style: {
                                    borderRadius: "4px"
                                } }, { children: _jsxs(Box, Object.assign({ pad: "small" }, { children: ["\u00A2", confirmButtonLabel] })) })), cancelButtonLabel &&
                            _jsx(Anchor, Object.assign({ onClick: onCancel }, { children: cancelButtonLabel }))] }))] })) }));
};
