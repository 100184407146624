import { Data } from './';
import { DataType, ResourceStatus, VehicleStatus } from './../types';
import { observable, makeObservable, reaction, action } from 'mobx';
import { rootStore } from '../app';
import { isSameDay, formatISO } from 'date-fns';
export class Vehicle extends Data {
    constructor(data) {
        super(DataType.Vehicle, data);
        this.name = null;
        this.type = null;
        this.license = null;
        this.max_weight = null;
        this.volume = null;
        this.office_id = null;
        this.team_links = [];
        this.status = ResourceStatus.Free;
        this.temp_date = null;
        this.vehicle_status = null;
        this.setStatus = () => {
            if (!rootStore.dataStore.getCurDate) {
                this.status = ResourceStatus.Free;
                return;
            }
            if (!this.id)
                return;
            const numTeams = rootStore.teamStore.getTeamsByVehicle(this.id).filter(t => t.date && isSameDay(t.date, rootStore.dataStore.getCurDate)).length;
            this.status = !numTeams ? ResourceStatus.Free : (numTeams === 1 ? ResourceStatus.Assigned : ResourceStatus.Conflict);
        };
        makeObservable(this, {
            name: observable,
            type: observable,
            max_weight: observable,
            office_id: observable,
            team_links: observable,
            license: observable,
            status: observable,
            vehicle_status: observable,
            setStatus: action,
        });
        if (data)
            this.fromData(data);
        reaction(() => [rootStore.teamStore.getCurDate, rootStore.teamStore.getTeamsByVehicle(this.id)], this.setStatus);
    }
    static create(data) {
        const defaultVehicle = Object.assign(Object.assign({}, Data.defaultValues), { name: "", type: null, license: null, team_links: [], max_weight: null, volume: null, office_id: null, status: ResourceStatus.Free, temp_date: null, vehicle_status: null });
        return new Vehicle(Object.assign(defaultVehicle, data));
    }
    fromData(data) {
        super.fromData(data);
        this.name = data.name;
        this.type = data.type;
        this.license = data.license;
        this.max_weight = data.max_weight;
        this.volume = data.volume;
        this.office_id = data.office_id && Number(data.office_id);
        this.team_links = data.team_links && data.team_links.map(l => Number(l));
        this.license = data.license;
        this.temp_date = data.temp_date && new Date(data.temp_date);
        this.vehicle_status = data.vehicle_status;
    }
    toData() {
        return Object.assign(Object.assign({}, super.toData()), { name: this.name, type: this.type, license: this.license, max_weight: this.max_weight, volume: this.volume, office_id: this.office_id, temp_date: this.temp_date && formatISO(this.temp_date, { representation: "date" }), created_by: undefined, updated_by: undefined, vehicle_status: this.vehicle_status });
    }
    get getStatus() {
        return this.status || null;
    }
}
Vehicle.compare = (lhs, rhs) => {
    var _a, _b, _c, _d, _e, _f;
    const lhsState = (_a = lhs.status) !== null && _a !== void 0 ? _a : ResourceStatus.Free;
    const rhsState = (_b = rhs.status) !== null && _b !== void 0 ? _b : ResourceStatus.Free;
    if (lhsState === rhsState) {
        const lhsLicense = (_c = lhs.license) !== null && _c !== void 0 ? _c : "";
        const rhsLicense = (_d = rhs.license) !== null && _d !== void 0 ? _d : "";
        if (lhsLicense === rhsLicense) {
            return ((_e = lhs.name) !== null && _e !== void 0 ? _e : "").localeCompare((_f = rhs.name) !== null && _f !== void 0 ? _f : "");
        }
        else {
            return lhsLicense.localeCompare(rhsLicense);
        }
    }
    else {
        return lhsState > rhsState ? -1 : 1;
    }
};
Vehicle.compareForResources = (lhs, rhs) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const lhsVehicleStatus = (_a = lhs.vehicle_status) !== null && _a !== void 0 ? _a : "";
    const rhsVehicleStatus = (_b = rhs.vehicle_status) !== null && _b !== void 0 ? _b : "";
    if (lhsVehicleStatus !== rhsVehicleStatus) {
        if (lhsVehicleStatus === VehicleStatus.Operational)
            return -1;
        if (lhsVehicleStatus === VehicleStatus.Terminated)
            return 1;
        return (lhsVehicleStatus === VehicleStatus.Not_operational && rhsVehicleStatus === VehicleStatus.Terminated) ? -1 : 1;
    }
    if (lhs.office_id !== rhs.office_id)
        return ((_c = lhs.office_id) !== null && _c !== void 0 ? _c : 0) - ((_d = rhs.office_id) !== null && _d !== void 0 ? _d : 0);
    if (lhs.license === rhs.license && lhs.volume === rhs.volume) {
        return ((_e = lhs.name) !== null && _e !== void 0 ? _e : "").localeCompare((_f = rhs.name) !== null && _f !== void 0 ? _f : "");
    }
    else if (lhs.license === rhs.license) {
        return ((_g = lhs.volume) !== null && _g !== void 0 ? _g : 0) - ((_h = rhs.volume) !== null && _h !== void 0 ? _h : 0);
    }
    else {
        return ((_j = lhs.license) !== null && _j !== void 0 ? _j : "").localeCompare((_k = rhs.license) !== null && _k !== void 0 ? _k : "");
    }
};
Vehicle.createTemp = (office_id) => {
    return new Vehicle(Object.assign(Object.assign({}, Data.defaultValues), { name: "", type: null, license: null, team_links: [], max_weight: null, volume: null, status: ResourceStatus.Free, vehicle_status: null, temp_date: rootStore.dataStore.getCurDate, office_id: office_id }));
};
