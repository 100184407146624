var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, TextInput } from "grommet";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TourIcon } from "../calendar/tourIcon";
import ClipLoader from "react-spinners/ClipLoader";
export const TourIDInput = inject('dataStore', 'userStore')(observer((props) => {
    var _a;
    const { t } = useTranslation();
    const { dataStore, userStore } = props;
    const [limeID, setLimeID] = useState(((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour) === null || _a === void 0 ? void 0 : _a.lime_id) || null);
    const [borderColor, setBorderColor] = useState('');
    const [fetching, setFetching] = useState(false);
    const [timer, setTimer] = useState(null);
    const isMounted = React.useRef(true);
    useEffect(() => { isMounted.current = true; return () => { isMounted.current = false; }; }, []);
    const change = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        setFetching(true);
        const result = yield ((_b = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour) === null || _b === void 0 ? void 0 : _b.setLimeId(limeID));
        if (isMounted.current) {
            setFetching(false);
            setBorderColor(result ? 'green' : 'red');
        }
    });
    useEffect(() => {
        if (borderColor.length) {
            setTimer(setTimeout(() => { if (isMounted.current)
                setBorderColor(''); }, 5000));
        }
    }, [borderColor]);
    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);
    const render = () => {
        const { lime_id } = (dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour) || {};
        const value = !!lime_id ? `${lime_id}` : undefined;
        const icon = fetching ?
            _jsx(ClipLoader, { color: '#053162', loading: fetching, size: 20 })
            :
                _jsx(TourIcon, { size: "large", type: value ? "linked" : "unlinked" });
        return (_jsxs(Box, { children: [_jsx(Text, Object.assign({}, props.textProps, { children: props.label })), _jsx(TextInput, Object.assign({ id: "lime-id-input", name: "limeID", placeholder: t("lime_id"), defaultValue: value, icon: icon, readOnly: !(userStore === null || userStore === void 0 ? void 0 : userStore.isAdminOrSales), onChange: e => setLimeID(Number((e.target.value || ''))), onBlur: change, onKeyDown: (event) => {
                        var _a;
                        if (event.key === "Enter")
                            (_a = document.getElementById("lime-id-input")) === null || _a === void 0 ? void 0 : _a.blur();
                    }, style: { width: '100%', borderColor: borderColor } }, props.textInputProps))] }));
    };
    return render();
}));
