var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { format } from "date-fns";
import { Box, Button, Footer, Header, Layer, ResponsiveContext, Text, } from "grommet";
import { Close, Compare, Save, Trash } from "grommet-icons";
import { Tip } from "grommet/components/Tip";
import { inject, observer } from "mobx-react";
import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { AppRoute } from "../../routes";
import { Dialog, DialogType } from "../dialog";
import { EditTourAdv } from "./editTourAdv";
import { EditTourComments } from "./editTourComments";
import { EditTourDesc } from "./editTourDesc";
import { EditTourInfo } from "./editTourInfo";
import { EditTourLimeData } from "./editTourLimeData";
import { TabButton } from "./tabButton";
import { Calendar01Icon, LaborIcon, TruckDeliveryIcon } from "hugeicons-react";
import { useNavigate } from 'react-router-dom';
export const EditTour = inject("dataStore", "userStore")(observer((props) => {
    var _a, _b, _c, _d, _e;
    const { t, i18n } = useTranslation();
    const size = useContext(ResponsiveContext);
    const [tabIndex, setTabIndex] = useState(0);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const { dataStore, userStore } = props;
    const navigate = useNavigate();
    React.useEffect(() => setTabIndex(0), [dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour]);
    const renderBody = () => {
        var _a;
        if ((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour) === null || _a === void 0 ? void 0 : _a.awaiting_overwrite)
            return _jsx(EditTourLimeData, {});
        switch (tabIndex) {
            case 1:
                return _jsx(EditTourAdv, {});
            case 2:
                return _jsx(EditTourComments, {});
            case 4:
                return _jsx(EditTourDesc, {});
            default:
                return _jsx(EditTourInfo, {});
        }
    };
    const renderWorkerInfo = () => {
        if (!(dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour))
            return null;
        return dataStore.activeTour.getTourUsers
            .filter((u) => !!u)
            .map((u) => (_jsx(Tip, Object.assign({ content: _jsxs(Text, Object.assign({ truncate: true }, { children: [u.first_name, " ", u.last_name] })) }, { children: _jsx(Box, { children: _jsx(LaborIcon, {}) }) }), u.id)));
    };
    const renderVehicleInfo = () => {
        if (!(dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour))
            return null;
        return dataStore.activeTour.getTourVehicles
            .filter((v) => !!v)
            .map((v) => (_jsx(Tip, Object.assign({ content: _jsxs(Text, Object.assign({ truncate: true }, { children: [v.name, " - ", v.type] })) }, { children: _jsx(Box, { children: _jsx(TruckDeliveryIcon, { size: 16 }) }) }), v.id)));
    };
    if (!(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour))
        return _jsx(_Fragment, {});
    return (_jsx(Layer, Object.assign({ className: "editTour", onEsc: () => {
            dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(null);
        }, onClickOutside: () => {
            dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(null);
        } }, { children: _jsxs(Box, Object.assign({ fill: "horizontal", width: !["large"].includes(size) ? "medium" : undefined, height: { min: "100%" } }, { children: [_jsxs(Box, Object.assign({ round: "4px", height: { min: 'medium' }, style: { minWidth: size === "small" ? "360px" : "560px" } }, { children: [_jsx(Button, { alignSelf: "end", hoverIndicator: true, icon: _jsx(Close, {}), onClick: () => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(null) }), _jsx(Box, Object.assign({ fill: "horizontal", className: "editTour-header" }, { children: _jsx(Header, Object.assign({ align: "flex-end", justify: "between", direction: "row", background: "white", pad: { horizontal: "medium" }, style: { zIndex: 1, minHeight: "50px" } }, { children: _jsxs(Box, Object.assign({ fill: true, className: "tab-area", direction: "row", justify: "between", alignContent: "end", alignSelf: "end", margin: { top: "small" } }, { children: [size === "small" ? ("") : (_jsx(Tip, Object.assign({ content: t("go_to_scheduler") }, { children: _jsx(Button, { hoverIndicator: true, icon: _jsx(Calendar01Icon, {}), onClick: () => {
                                                    var _a;
                                                    dataStore === null || dataStore === void 0 ? void 0 : dataStore.setDate((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour) === null || _a === void 0 ? void 0 : _a.date);
                                                    navigate(AppRoute.Scheduler);
                                                } }) }))), _jsx(TabButton, { tabIndex: 0, tabValue: tabIndex, setTabIndex: setTabIndex, title: t("information"), badge: "" }), _jsx(TabButton, { tabIndex: 4, tabValue: tabIndex, setTabIndex: setTabIndex, title: t("detail_plural"), badge: "" }), _jsx(TabButton, { tabIndex: 1, tabValue: tabIndex, setTabIndex: setTabIndex, title: t("customer"), badge: "" }), _jsx(TabButton, { tabIndex: 2, tabValue: tabIndex, setTabIndex: setTabIndex, title: t("comments"), badge: (() => {
                                                var _a;
                                                const numComments = (_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour) === null || _a === void 0 ? void 0 : _a.num_comments;
                                                if (numComments === null || numComments < 1) {
                                                    return "";
                                                }
                                                if (numComments > 9) {
                                                    return "9+";
                                                }
                                                return numComments.toString();
                                            })(), disabled: !Boolean(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour.id) })] })) })) })), _jsx(Box, Object.assign({ overflow: "auto", className: "editTourDialog", pad: { vertical: "medium", horizontal: "medium" } }, { children: renderBody() })), _jsx(Footer, Object.assign({ pad: { vertical: "medium", horizontal: "medium" }, direction: size === "small" ? "row-reverse" : "row", justify: "end" }, { children: _jsxs(Box, Object.assign({ width: "100%", direction: size === "small" ? "column" : "row", gap: "medium", justify: "end" }, { children: [tabIndex === 0 &&
                                        _jsxs(Box, Object.assign({ width: "100%", direction: "row-reverse", justify: "between" }, { children: [(userStore === null || userStore === void 0 ? void 0 : userStore.isAdminOrSales) && ((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour) === null || _a === void 0 ? void 0 : _a.created_by) ? (_jsx(Button, { hoverIndicator: true, icon: _jsx(Trash, {}), onClick: () => setShowDeleteDialog(true), disabled: (dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour.id) === 0, tip: t("remove_tour") })) : (""), ((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour.created_by) ? (_jsxs(Box, Object.assign({ style: { justifyContent: "center" } }, { children: [_jsx(Tip, Object.assign({ content: t("created_by") +
                                                                " " +
                                                                ((_b = userStore === null || userStore === void 0 ? void 0 : userStore.getUser(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour.created_by)) === null || _b === void 0 ? void 0 : _b.fullName) }, { children: _jsxs(Text, Object.assign({ size: "xsmall", style: { textAlign: "left" } }, { children: [t("created_by"), _jsx("strong", { children: " " +
                                                                            ((_c = userStore === null || userStore === void 0 ? void 0 : userStore.getUser(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour.created_by)) === null || _c === void 0 ? void 0 : _c.displayAlias) +
                                                                            " " }), format((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour.created_at) || new Date(), "yyyy-LL-dd HH:mm")] })) })), !!(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour.updated_by) && (_jsx(Tip, Object.assign({ content: t("updated_by") +
                                                                " " +
                                                                ((_d = userStore === null || userStore === void 0 ? void 0 : userStore.getUser(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour.updated_by)) === null || _d === void 0 ? void 0 : _d.fullName) }, { children: _jsxs(Text, Object.assign({ size: "xsmall", style: { textAlign: "left" } }, { children: [t("updated_by"), _jsx("strong", { children: " " +
                                                                            ((_e = userStore === null || userStore === void 0 ? void 0 : userStore.getUser(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour.updated_by)) === null || _e === void 0 ? void 0 : _e.displayAlias) +
                                                                            " " }), format((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour.updated_at) ||
                                                                        (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour.created_at) ||
                                                                        new Date(), "yyyy-LL-dd HH:mm")] })) })))] }))) : "")] })), _jsx(Box, Object.assign({ direction: "row" }, { children: (userStore === null || userStore === void 0 ? void 0 : userStore.isAdminOrSales) && (_jsx(Box, Object.assign({ width: "100%", direction: "row", justify: "end" }, { children: _jsx(Button, { primary: true, style: { width: size === "small" ? "100%" : undefined }, icon: (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour.awaiting_overwrite) ? (_jsx(Compare, {})) : (_jsx(Save, {})), label: (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour.awaiting_overwrite)
                                                    ? t("overwrite")
                                                    : t("save"), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                    var _f, _g, _h;
                                                    if ((_f = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour) === null || _f === void 0 ? void 0 : _f.awaiting_overwrite) {
                                                        (_g = dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour) === null || _g === void 0 ? void 0 : _g.overwrite();
                                                    }
                                                    else {
                                                        yield ((_h = dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour) === null || _h === void 0 ? void 0 : _h.save().then(() => toast.success(t("saved_tour"))).catch(() => toast.error(t("could_not_save_tour"))));
                                                        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(null);
                                                    }
                                                }) }) }))) })), tabIndex === 1 && (_jsxs(Box, { children: [_jsx(Box, Object.assign({ direction: "row" }, { children: renderWorkerInfo() })), _jsx(Box, Object.assign({ direction: "row" }, { children: renderVehicleInfo() }))] }))] })) }))] })), _jsx(Dialog, { message: t("confirm_delete_tour"), show: showDeleteDialog, title: t("confirm_delete_dialog_title"), type: DialogType.Warning, confirmButtonLabel: t("delete"), cancelButtonLabel: t("cancel"), onConfirm: () => __awaiter(void 0, void 0, void 0, function* () {
                        var _j;
                        yield ((_j = dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour) === null || _j === void 0 ? void 0 : _j.remove().catch(() => toast.error(t("could_not_delete_tour"))));
                        setShowDeleteDialog(false);
                        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(null);
                    }), onCancel: () => setShowDeleteDialog(false) })] })) })));
}));
