import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { inject, observer } from "mobx-react";
import { AppRoute } from "../../routes";
import { Box, TextInput, Button, Heading, FormField, } from "grommet";
import { useTranslation } from "react-i18next";
import { LockIcon, UserCircle02Icon } from "hugeicons-react";
import { useNavigate } from 'react-router-dom';
const SignUpView = inject("userStore")(observer((props) => {
    const { t } = useTranslation();
    const { userStore } = props;
    const navigate = useNavigate();
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    if (userStore === null || userStore === void 0 ? void 0 : userStore.isLoggedIn) {
        navigate(AppRoute.Calendar);
    }
    const handleSubmit = () => {
        if (email && password) {
        }
    };
    return (_jsxs(Box, Object.assign({ margin: "auto", width: "60%" }, { children: [_jsx(Heading, Object.assign({ size: "small", textAlign: "center", style: { fontWeight: "normal" } }, { children: t("create_account") })), _jsx(FormField, Object.assign({ name: "user_name", htmlFor: "user-name-input", label: t("user_name") }, { children: _jsx(TextInput, { id: "user-name-input", name: "user_name", placeholder: t("user_name"), value: email, icon: _jsx(UserCircle02Icon, {}), onChange: (e) => setEmail(e.target.value) }) })), _jsx(FormField, Object.assign({ name: "password", htmlFor: "password-input", label: t("password"), style: { marginBottom: "3rem" } }, { children: _jsx(TextInput, { id: "password-input", name: "password", type: "password", placeholder: t("password"), value: password, icon: _jsx(LockIcon, {}), onChange: (e) => setPassword(e.target.value), onKeyDown: (e) => {
                        if (e.key === "Enter") {
                            handleSubmit();
                        }
                    } }) })), _jsx(Box, Object.assign({ direction: "row", gap: "large", align: "center", alignContent: "center", margin: "auto" }, { children: _jsx(Button, { style: { fontSize: "14px", fontWeight: "normal" }, color: "#053162", primary: true, label: t("create_account"), onClick: handleSubmit }) }))] })));
}));
export default SignUpView;
