var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, reaction, keys, remove, set, runInAction } from 'mobx';
import client from '../feathers';
import { Marker } from '../models/marker';
import { Data } from './../models';
export class MapStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.access_token = null;
        this.marker_service = client.service('marker');
        this.mapbox_service = client.service("mapbox");
        this.Stockholm = { longitude: 18.0649000, latitude: 59.3325800 };
        this.showPopup = false;
        this.activeMarker = null;
        this.markers = {};
        this.requestToken = () => __awaiter(this, void 0, void 0, function* () {
            try {
                this.access_token = yield this.mapbox_service.get(0);
            }
            catch (err) {
                console.error("Failed to load mapbox access token.");
            }
        });
        this.registerEventListener = () => {
            this.marker_service.on("created", (data) => runInAction(() => set(this.markers, data.id, Marker.create(data))));
            this.marker_service.on("removed", (data) => runInAction(() => set(this.markers, data.id, undefined)));
            this.marker_service.on("updated", (data) => runInAction(() => set(this.markers, data.id, Marker.create(data))));
            this.marker_service.on("patched", (data) => runInAction(() => set(this.markers, data.id, Marker.create(data))));
        };
        this.loadMarker = (queryOptions, page = 0) => __awaiter(this, void 0, void 0, function* () {
            const limit = (queryOptions && queryOptions["$limit"]) || 100;
            const data = yield this.marker_service.find({
                query: Object.assign({ $limit: limit, $skip: page * limit }, queryOptions),
            });
            runInAction(() => {
                if (!page)
                    keys(this.markers).forEach((k) => remove(this.markers, k));
                Object.assign(this.markers, data.data.reduce((res, val) => {
                    if (val.id)
                        res[val.id] = Marker.create(val);
                    return res;
                }, {}));
            });
            if (page * limit < data.total)
                this.loadMarker(queryOptions, page + 1);
        });
        this.setActiveMarker = (marker) => {
            this.activeMarker = marker ? Marker.create(marker) : null;
        };
        this.togglePopup = () => {
            this.showPopup = !this.showPopup;
        };
        this.addMarker = (lng, lat, type = "default") => __awaiter(this, void 0, void 0, function* () {
            if (!this.rootStore.dataStore.getActiveTour) {
                console.error("Marker has to belong to a tour");
                return;
            }
            const m = Marker.create(Object.assign(Object.assign({}, Data.defaultValues), { longitude: lng, latitude: lat, text: "", type: type, tour_id: this.rootStore.dataStore.getActiveTour.id }));
            yield m.save();
            this.setActiveMarker(m);
        });
        makeAutoObservable(this);
        this.requestToken();
        reaction(() => this.rootStore.dataStore.getActiveTour, () => {
            var _a, _b, _c;
            if (!this.rootStore.dataStore.activeTour) {
                keys(this.markers).forEach((k) => remove(this.markers, k));
                return;
            }
            this.loadMarker({ tour_id: (_c = (_b = (_a = this.rootStore) === null || _a === void 0 ? void 0 : _a.dataStore) === null || _b === void 0 ? void 0 : _b.getActiveTour) === null || _c === void 0 ? void 0 : _c.id });
        });
        this.registerEventListener();
    }
    get getMarkers() {
        return Object.values(this.markers);
    }
    get getActiveMarker() {
        return this.activeMarker;
    }
    get getToken() {
        return this.access_token;
    }
    get hasOpenPopup() {
        return this.showPopup;
    }
}
