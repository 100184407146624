import { createElement as _createElement } from "react";
import { jsx as _jsx } from "react/jsx-runtime";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Box, Select } from "grommet";
import { Section } from "../components/settings/section";
export const Settings = inject('userStore', 'dataStore')(observer((props) => {
    var _a, _b;
    const { t, i18n } = useTranslation();
    const { userStore, dataStore } = props;
    const sections = [
        {
            title: t("calendar"),
            options: [{
                    text: t("default_office"),
                    tip: t("default_office_help_text"),
                    form: (_jsx(Select, { value: ((_a = userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) === null || _a === void 0 ? void 0 : _a.default_office) && (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOfficeByID((_b = userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) === null || _b === void 0 ? void 0 : _b.default_office)) || undefined, options: Object.values((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOffices) || {}), labelKey: "name", valueKey: "id", placeholder: t("select_office"), onChange: event => {
                            var _a, _b;
                            (_a = userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) === null || _a === void 0 ? void 0 : _a.setDefaultOffice(event.option.id);
                            (_b = userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) === null || _b === void 0 ? void 0 : _b.save();
                        } }))
                }]
        },
        {
            title: t("scheduler"),
            options: []
        }
    ];
    return (_jsx(Box, Object.assign({ className: "settings-container", pad: "medium", align: "center", overflow: { vertical: "auto" }, gap: "medium", fill: true }, { children: sections.map(s => _createElement(Section, Object.assign({}, s, { key: "section-box-" + s.title }))) })));
}));
