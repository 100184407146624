var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from "grommet";
import { TeamCard } from "./teamCard";
import { useTranslation } from "react-i18next";
import { observer, inject } from "mobx-react";
import { Team } from "../../models/team";
import toast from "react-hot-toast";
import { Data } from "@/models";
export const Teams = inject('teamStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { teamStore, readonly, heightProp } = props;
    const teams = (teamStore === null || teamStore === void 0 ? void 0 : teamStore.getCurTeams) || [];
    return (_jsxs(Box, Object.assign({ fill: true, wrap: true, pad: "small", gap: "small", direction: "row", overflow: "auto", flex: false, height: { max: heightProp } }, { children: [teams.map((t, idx) => {
                var _a;
                return (t && _jsx(TeamCard, { readonly: readonly, team: t }, "team-card" + ((_a = t.id) === null || _a === void 0 ? void 0 : _a.toString()) + idx));
            }), !readonly &&
                _jsxs(Box, Object.assign({ hoverIndicator: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        const team = Team.create(Object.assign(Object.assign({}, Data.defaultValues), { office_id: (teamStore === null || teamStore === void 0 ? void 0 : teamStore.getCurOfficeId) || null, date: (teamStore === null || teamStore === void 0 ? void 0 : teamStore.getCurDate) || null, name: t("team") + (teams.length + 1), user_id: null, notified: false, tour_links: [], user_links: [], vehicle_links: [], user_info: [] }));
                        yield team.save().catch(() => toast.error(t("could_not_save_team")));
                    }), width: "small", height: "medium", align: "center", justify: "between", flex: false }, { children: [_jsxs(Box, Object.assign({ direction: "row", justify: "between", fill: "horizontal" }, { children: [_jsx(Box, { border: [{ side: "top" }, { side: "left" }], width: "45px", height: "xsmall" }), _jsx(Box, { border: [{ side: "top" }, { side: "right" }], width: "45px", height: "xsmall" })] })), _jsxs(Box, Object.assign({ align: "center" }, { children: [_jsx(Text, Object.assign({ color: "dark-4" }, { children: t("new_team") })), _jsx(Text, Object.assign({ color: "dark-4", size: "xxlarge" }, { children: "+" }))] })), _jsxs(Box, Object.assign({ direction: "row", justify: "between", fill: "horizontal" }, { children: [_jsx(Box, { border: [{ side: "bottom" }, { side: "left" }], width: "45px", height: "xsmall" }), _jsx(Box, { border: [{ side: "bottom" }, { side: "right" }], width: "45px", height: "xsmall" })] }))] }))] })));
}));
