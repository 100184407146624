var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Box, Select, TextInput, Button, CheckBox } from "grommet";
import { Section } from "../components/settings/section";
import { UserRole, VehicleLicense } from "../types";
import { User } from "../models";
import { User as UserIcon, Save } from "grommet-icons";
import { Lock as PasswordIcon } from "grommet-icons";
import { runInAction } from "mobx";
import { SystemMessage } from "../components/systemMessage";
import toast from "react-hot-toast";
export const Profile = inject('userStore', 'dataStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { userStore, dataStore } = props;
    const [user, setUser] = React.useState();
    const [name, setName] = React.useState();
    const [oldPassword, setOldPassword] = React.useState();
    const [newPassword, setNewPassword] = React.useState();
    React.useEffect(() => {
        var _a;
        if (!(userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser))
            return;
        setUser(User.create(userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser));
        setName((_a = userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) === null || _a === void 0 ? void 0 : _a.fullName);
    }, []);
    React.useEffect(() => {
        var _a;
        if (!(userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser))
            return;
        setName((_a = userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) === null || _a === void 0 ? void 0 : _a.fullName);
        setUser(User.create(userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser));
    }, [userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser]);
    if (!user)
        return null;
    const userAvatar = (_jsx(Box, Object.assign({ round: "full", align: "center", justify: "center", flex: false, background: "light-2", elevation: "xsmall", pad: "small" }, { children: _jsx(UserIcon, { size: "large" }) })));
    const passwordAvatar = (_jsx(Box, Object.assign({ round: "full", align: "center", justify: "center", flex: false, background: "light-2", elevation: "xsmall", pad: "small" }, { children: _jsx(PasswordIcon, { size: "large" }) })));
    const getSubtitle = () => {
        const dash = user.role && user.employment_type ? " - " : "";
        return (user.role === UserRole.Admin ? t("admin") : "") + dash + (user.employment_type || "");
    };
    const sections = [
        { text: t("name"), form: (_jsx(Box, Object.assign({ border: { side: "all", size: "xsmall", color: user.validateProp("first_name") ? undefined : "status-error" }, round: "4px" }, { children: _jsx(TextInput, { plain: true, textAlign: "end", placeholder: t("name_help_text"), value: name, onChange: event => {
                        setName(event.target.value);
                        const names = event.target.value.trim().split(" ");
                        runInAction(() => {
                            if (names.length > 1) {
                                user.last_name = names[names.length - 1];
                                user.first_name = names.slice(0, -1).join(' ');
                            }
                            else {
                                user.first_name = names[0];
                                user.last_name = "";
                            }
                        });
                    } }) }))) },
        { text: t("phone"), form: (_jsx(Box, { children: _jsx(TextInput, { placeholder: "+46 1xxxxx ", value: user.phone || "", onChange: event => runInAction(() => user.phone = event.target.value), textAlign: "end" }) })) },
        { text: t("license"), tip: t("license_help_text"), form: (_jsx(Select, { css: { textAlign: "end" }, value: user.license || undefined, options: [VehicleLicense.B, VehicleLicense.C, VehicleLicense.CE, VehicleLicense.None], placeholder: t("select_license"), onChange: event => runInAction(() => user.license = event.option) })) },
        {
            text: t("default_office"),
            tip: t("default_office_help_text"),
            form: (_jsx(Select, { value: user.default_office ? dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOfficeByID(user.default_office) : undefined, options: Object.values((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOffices) || {}), labelKey: "name", valueKey: "id", placeholder: t("select_office"), onChange: event => {
                    user.setDefaultOffice(event.option.id);
                } }))
        },
    ];
    const passwordSection = [
        { text: t("old_password"), tip: t("enter_your_current_password"), form: (_jsx(Box, { children: _jsx(TextInput, { style: { width: 300 }, type: "password", placeholder: t("enter_old_password"), value: oldPassword || "", onChange: event => runInAction(() => setOldPassword(event.target.value)) }) })) },
        { text: t("new_password"), tip: t("enter_your_new_password"), form: (_jsx(Box, { children: _jsx(TextInput, { style: { width: 300 }, type: "password", placeholder: t("enter_new_password"), value: newPassword || "", onChange: event => runInAction(() => setNewPassword(event.target.value)) }) })) },
    ];
    if (userStore === null || userStore === void 0 ? void 0 : userStore.isAdmin)
        sections.push({
            text: t("hide_user"),
            tip: t("hide_user_help_text"),
            form: (_jsx(CheckBox, { checked: user.hidden, onChange: (event) => runInAction(() => user.hidden = event.target.checked) }))
        });
    return (_jsxs(Box, Object.assign({ className: "settings-container", pad: "medium", align: "center", overflow: { vertical: "auto" }, fill: true }, { children: [_jsxs(Box, Object.assign({ align: "end", gap: "small" }, { children: [_jsx(Section, { title: t("profile"), subtitle: getSubtitle(), avatar: userAvatar, options: sections }, "section-box-profile"), _jsx(Button, { primary: true, icon: _jsx(Save, {}), label: t("save"), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            yield user.save()
                                .then((ok) => toast.success(t("saved_user"))).catch((err) => toast
                                .error(t("could_not_save_user")));
                        }), disabled: !user || !user.validate || !(userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) || user.compare(userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) })] })), _jsxs(Box, Object.assign({ align: "end", gap: "small", pad: "large" }, { children: [_jsx(Section, { title: t("change_password"), subtitle: "", avatar: passwordAvatar, options: passwordSection }, "section-box-profile"), _jsx(Button, { primary: true, icon: _jsx(Save, {}), label: t("change_password"), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            if (!newPassword || !oldPassword)
                                return;
                            yield (userStore === null || userStore === void 0 ? void 0 : userStore.changePassword(newPassword, oldPassword).then((res) => toast.success(t("password_changed"))).catch((err) => toast.error(t(err.message))));
                        }), disabled: !user || !oldPassword || !newPassword })] })), _jsx(SystemMessage, {})] })));
}));
