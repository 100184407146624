import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { inject, observer } from "mobx-react";
import { AppRoute } from "../../routes";
import { Box, TextInput, Button, Heading, FormField, Text } from "grommet";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { PasswordInput } from "./passwordInput";
const LoginView = inject("userStore")(observer((props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { userStore } = props;
    const { error_message } = userStore || {};
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    if (userStore === null || userStore === void 0 ? void 0 : userStore.isLoggedIn)
        navigate(AppRoute.Calendar);
    return (_jsxs(Box, Object.assign({ margin: "auto", width: "100%", pad: "small", gap: "small", animation: 'fadeIn' }, { children: [_jsx(Heading, Object.assign({ size: "small", textAlign: "center" }, { children: t("login") })), _jsx(Box, Object.assign({ direction: 'row', width: 'xxlarge', alignSelf: 'center' }, { children: _jsx(FormField, Object.assign({ label: t("user_name"), width: 'full' }, { children: _jsx(TextInput, { name: "name", placeholder: t("user_name"), defaultValue: email, onChange: (e) => setEmail(e.target.value) }) })) })), _jsx(Box, Object.assign({ direction: 'row', width: 'xxlarge', alignSelf: 'center' }, { children: _jsx(FormField, Object.assign({ label: t("password"), width: "full" }, { children: _jsx(PasswordInput, { id: "password", value: password, onChange: (value) => setPassword(value), onKeyDown: (e) => {
                            if (e.key === "Enter")
                                userStore === null || userStore === void 0 ? void 0 : userStore.login(email, password);
                        } }) })) })), _jsx(Box, Object.assign({ direction: 'row', width: 'xxlarge', alignSelf: 'center', justify: 'center' }, { children: _jsx(Button, { primary: true, autoFocus: true, color: "#053162", type: "submit", label: t("login"), onClick: () => userStore === null || userStore === void 0 ? void 0 : userStore.login(email, password) }) })), _jsx(Box, Object.assign({ width: 'full', alignSelf: 'center', align: 'center', fill: 'horizontal' }, { children: _jsx(Link, Object.assign({ to: "/auth/forgot-password" }, { children: _jsx(Text, Object.assign({ color: "#053162", margin: 'auto' }, { children: t("forgot_password?") })) })) })), !!error_message && (_jsx(Box, Object.assign({ width: 'full', alignSelf: 'center', align: 'center', fill: 'horizontal' }, { children: _jsx(Text, Object.assign({ color: "red", margin: 'auto' }, { children: error_message })) })))] })));
}));
export default LoginView;
