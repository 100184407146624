var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, TextInput } from "grommet";
import { ViewIcon, ViewOffSlashIcon } from "hugeicons-react";
export const PasswordInput = React.forwardRef((_a, ref) => {
    var _b;
    var { id, label, disabled, containerClass = "", value, onChange, onKeyDown } = _a, rest = __rest(_a, ["id", "label", "disabled", "containerClass", "value", "onChange", "onKeyDown"]);
    const { t } = useTranslation();
    const [inputType, setInputType] = useState("password");
    const toggleVisibility = (e) => {
        e.preventDefault();
        if (inputType === "password") {
            setInputType("text");
        }
        else {
            setInputType("password");
        }
    };
    const handleChange = (e) => {
        const newValue = e.target.value;
        if (onChange) {
            onChange(newValue);
        }
    };
    return (_jsxs(Box, Object.assign({ direction: "row", justify: "between", align: "center" }, { children: [_jsx(TextInput, Object.assign({ style: { border: "none", outline: "none", boxShadow: "none" }, type: inputType, id: id, ref: ref, value: value, name: "password", placeholder: t("password"), onChange: handleChange, onKeyDown: onKeyDown, className: "pr-[2.5rem]" }, rest)), _jsx(Button, Object.assign({ onClick: (e) => toggleVisibility(e), type: "button", "aria-label": (_b = t("toggle_password_visibility")) !== null && _b !== void 0 ? _b : "Toggle visibility" }, { children: inputType === "password" ? (_jsx(ViewIcon, { width: 20, height: 20, strokeWidth: 2 })) : (_jsx(ViewOffSlashIcon, { width: 20, height: 20, strokeWidth: 2 })) }))] })));
});
