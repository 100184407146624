var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { User } from "grommet-icons";
import { Grid, Box, TextInput, Button, Heading } from "grommet";
import { useTranslation } from "react-i18next";
const prettyPrintJson = require("pretty-print-json");
import client from "./../feathers";
const FormContainer = styled.form `
  padding: 15px 15px 40px 15px;
  display: block;
  flex-direction: row;
`;
const FormLabel = styled.label `
  padding: 3px;
  color: #151515;
  margin-top: 10px;
`;
const TextResult = styled.pre `
  height: 500px;
  width: 700px;
  color: #151515;
  margin-top: 10px;
`;
export const LimeDebug = inject("userStore")(observer((props) => {
    const { t, i18n } = useTranslation();
    const [sbkRef, setSBKRef] = React.useState("24561");
    const [refResult, setRefResult] = React.useState({});
    const getLimeObjet = (ref) => __awaiter(void 0, void 0, void 0, function* () {
        const result = yield client.service("/lime-crm").find({
            query: {
                refId: ref,
            },
        });
        const r = result[0];
        if (r._links)
            r._links = null;
        setRefResult(r);
    });
    return (_jsx(Grid, Object.assign({ alignContent: "center", justifyContent: "center", style: { height: "100%" } }, { children: _jsxs(FormContainer, { children: [_jsx(Heading, Object.assign({ size: "small", responsive: true }, { children: t("Enter Deal ID") })), _jsxs(Box, Object.assign({ margin: { bottom: "small" } }, { children: [_jsx(TextInput, { name: "text", placeholder: t("sbk_ref_nr"), value: sbkRef, icon: _jsx(User, {}), onChange: (e) => setSBKRef(e.target.value) }), _jsx(Button, { primary: true, label: t("search"), margin: { bottom: "small" }, onClick: () => getLimeObjet(sbkRef) })] })), !!Object.keys(refResult).length && (_jsx(TextResult, { dangerouslySetInnerHTML: {
                        __html: prettyPrintJson.toHtml(refResult),
                    } }))] }) })));
}));
