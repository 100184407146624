import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { customer_obj_props } from "../../types";
import { observer, inject } from "mobx-react";
import { List, Box, Text } from "grommet";
import { useTranslation } from "react-i18next";
export const AdvancedInfoList = inject('dataStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { editable, dataStore, target } = props;
    const tour = (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour) || null;
    const data = Object.values(customer_obj_props).map(d => ({
        label: t(d), value: tour ? tour[target][d] : '', prop: d
    }));
    return (_jsx(List, { pad: 'none', data: editable ? data : (data && data.filter(d => d.showEmpty || Boolean(d.value)) || []), children: (data) => (_jsxs(Box, Object.assign({ direction: "row", justify: "between", pad: { horizontal: "small", vertical: "xsmall" }, align: "center" }, { children: [_jsx(Text, Object.assign({ size: "small", weight: "bold" }, { children: data.label })), (!data.inputType || data.inputType === "text" || data.inputType === "number") &&
                    _jsx("textarea", { type: data.inputType, className: "customer-list-input", disabled: !editable, onChange: (e) => tour === null || tour === void 0 ? void 0 : tour.setCustomerData(target, data.prop, e.target.value), rows: data["rows"] || 1, value: data.value || "" })] }))) }));
}));
