import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Note, Tour } from '@/models';
import { AppRoute } from '@/routes';
import { Filter, FilterGroup } from '@/types';
import { toCap } from '@/util';
import { LoadingView } from '@/views';
import { add, format, isSameDay, startOfWeek } from 'date-fns';
import { de, enUS, sv } from 'date-fns/esm/locale';
import { Box, Button, ResponsiveContext, Text, TextInput } from 'grommet';
import { FormSearch } from 'grommet-icons';
import { Add01Icon, Cancel01Icon, FilterHorizontalIcon } from 'hugeicons-react';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CalendarDateSelection } from './calendarDateSelection';
import { DataCell } from './dataCell';
import { SearchView } from './searchView';
import { isHolidayOrSunday } from '@/utils';
import { MobileFilterModal } from './mobileFilterModal';
import { viewportSize } from '@/utils/viewport';
export const debounce = (clbk, debounceDelay = 500) => {
    let debounceTimer = null;
    return (args) => {
        debounceTimer && clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => clbk(args), debounceDelay);
    };
};
export const Calendar = inject('dataStore', 'userStore')(observer((props) => {
    var _a, _b, _c;
    const { dataStore, userStore } = props;
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const size = React.useContext(ResponsiveContext);
    const dateLocales = { en: enUS, sv: sv, de: de };
    const { isMobile, isDesktop } = viewportSize();
    const [selectedRow, setSelectedRow] = React.useState((_a = userStore === null || userStore === void 0 ? void 0 : userStore.getDefaultOfficeId) !== null && _a !== void 0 ? _a : 0);
    const [searchString, setSearchString] = React.useState('');
    const [isSearching, setIsSearching] = React.useState(false);
    const [openFilters, setOpenFilters] = React.useState(false);
    const handleSearchChange = (event) => {
        const newSearchString = event.target.value;
        setSearchString(newSearchString);
        setIsSearching(newSearchString.length > 0);
    };
    const _applyQuery = React.useCallback(debounce(handleSearchChange), [searchString]);
    const clearSearch = () => {
        setSearchString("");
        setIsSearching(false);
    };
    const dayList = (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getFilter(FilterGroup.Other, Filter.Weekend)) ?
        ["mon", "tue", "wed", "thu", "fri", "sat", "sun"] :
        ["mon", "tue", "wed", "thu", "fri"];
    const columnHeaders = React.useMemo(() => {
        const elements = [
            _jsx(Box, Object.assign({ pad: "small", background: "light-1" }, { children: _jsx(Text, Object.assign({ weight: "bold" }, { children: t("office") })) }), "office_column")
        ];
        return elements.concat((dayList).map((val, dayOfWeek) => {
            var _a, _b, _c, _d;
            return (_jsxs(Box, Object.assign({ hoverIndicator: true, justify: "between", pad: "small", direction: "row", background: "light-1", onClick: () => {
                    var _a, _b;
                    dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(null);
                    dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveNote(null);
                    dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveOffice(Object.values((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOfficesUnfiltered) !== null && _a !== void 0 ? _a : {})[0]);
                    dataStore === null || dataStore === void 0 ? void 0 : dataStore.setDate(add(startOfWeek((_b = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) !== null && _b !== void 0 ? _b : new Date()), { days: dayOfWeek }));
                    navigate(AppRoute.Scheduler);
                } }, { children: [_jsx(Text, Object.assign({ weight: "bold", className: `${isSameDay(new Date(), add(startOfWeek((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) !== null && _a !== void 0 ? _a : new Date()), { days: dayOfWeek })) ? "calendar-current-date" : ""} ${isHolidayOrSunday(add(startOfWeek((_b = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) !== null && _b !== void 0 ? _b : new Date()), { days: dayOfWeek })) ? "calendar-holiday" : ""}` }, { children: format(add(startOfWeek((_c = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) !== null && _c !== void 0 ? _c : new Date()), { days: dayOfWeek }), 'd') }), "date"), _jsx(Text, Object.assign({ weight: "bold", color: "dark-5" }, { children: toCap(format(add(startOfWeek((_d = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) !== null && _d !== void 0 ? _d : new Date()), { days: dayOfWeek }), 'E', { locale: dateLocales[i18n.language] })) }), "day")] }), "day_" + dayOfWeek));
        }));
    }, [dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate, i18n.language, dataStore === null || dataStore === void 0 ? void 0 : dataStore.getFilter(FilterGroup.Other, Filter.Weekend)]);
    const rows = (_b = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCalendarRows(false)) !== null && _b !== void 0 ? _b : [];
    const isLoading = React.useDeferredValue((_c = dataStore === null || dataStore === void 0 ? void 0 : dataStore.isLoadingCalendar) !== null && _c !== void 0 ? _c : false);
    return (_jsxs(Box, Object.assign({ fill: true, overflow: "hidden", className: "rc-container", gap: "medium", pad: { vertical: "medium", horizontal: "small" } }, { children: [_jsxs(Box, Object.assign({ className: "rc-header", direction: "column", align: "center", gap: "small" }, { children: [_jsx(CalendarDateSelection, {}), _jsxs(Box, Object.assign({ align: "center", className: 'calendar-container', width: "100%", direction: size === "small" ? "column" : "row", justify: 'between', gap: "small" }, { children: [_jsxs(Box, Object.assign({ className: 'filter-and-search', direction: 'row', gap: "medium", width: size === "small" ? "100%" : "auto" }, { children: [isMobile && (_jsxs(Box, Object.assign({ width: "100%", className: 'filter-bar' }, { children: [_jsx(Button, { secondary: true, className: "styled-button", style: { padding: "4px 6px" }, gap: 'small', icon: _jsx(FilterHorizontalIcon, { height: 18, width: 18 }), label: t("filter"), color: "black", onClick: () => setOpenFilters(true) }), openFilters &&
                                                _jsx(MobileFilterModal, { onClose: () => setOpenFilters(false) })] }))), _jsxs(Box, Object.assign({ className: 'search-bar', direction: "row", align: "center", width: size === "small" ? "100%" : "300px", background: "white", border: { color: "light-4", size: "xsmall", style: "solid" }, style: { borderRadius: "6px" } }, { children: [_jsx(TextInput, { className: 'search-bar-input', icon: _jsx(FormSearch, { height: 18, width: 18 }), style: { padding: "6px 6px 6px 42px" }, placeholder: t("search_bar_placeholder"), defaultValue: searchString, onChange: _applyQuery, plain: true }), searchString && (_jsx(Button, { className: 'search-bar-clear', icon: _jsx(Cancel01Icon, { height: 18, width: 18, className: 'search-bar-clear' }), onClick: clearSearch, plain: true, hoverIndicator: true }))] }))] })), _jsxs(Box, Object.assign({ className: 'create-buttons', direction: "row", gap: "medium", justify: "between", width: size === "small" ? "100%" : "auto" }, { children: [((userStore === null || userStore === void 0 ? void 0 : userStore.isAdmin) || (userStore === null || userStore === void 0 ? void 0 : userStore.isSales)) && (_jsx(Button, { primary: true, style: { padding: "4px 8px", whiteSpace: "nowrap" }, className: "styled-button", icon: _jsx(Add01Icon, { height: 18, width: 18 }), gap: 'small', label: t("create_new_tour"), color: "accent-1", onClick: () => {
                                            const newTour = Tour.create({
                                                date: dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate,
                                                office_id: userStore.getDefaultOfficeId,
                                            });
                                            dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(newTour);
                                        } })), _jsx(Button, { primary: true, style: { padding: "4px 8px", whiteSpace: "nowrap" }, label: t("create_note"), className: "styled-button", gap: 'small', icon: _jsx(Add01Icon, { height: 18, width: 18 }), color: "accent-1", onClick: () => {
                                            const newNote = Note.create({
                                                from: dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate,
                                                to: dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate,
                                                office_id: userStore === null || userStore === void 0 ? void 0 : userStore.getDefaultOfficeId,
                                            });
                                            dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveNote(newNote);
                                        } })] }))] }))] })), isSearching ? (_jsx(SearchView, { searchString: searchString, setSearchString: setSearchString, dataStore: dataStore, userStore: userStore })) : isLoading ? (_jsx(LoadingView, {})) : (_jsx(DndProvider, Object.assign({ backend: HTML5Backend }, { children: _jsx(Box, Object.assign({ flex: "shrink", background: 'white' }, { children: _jsxs("div", Object.assign({ className: "calendar" }, { children: [_jsx("div", Object.assign({ className: "calendar-header" + ((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getFilter(FilterGroup.Other, Filter.Weekend)) ? "" : " no-weekend") }, { children: columnHeaders }), "calendar-header"), rows.map(row => {
                                const cells = [
                                    _jsx(Box, Object.assign({ hoverIndicator: true, pad: "small", onClick: () => setSelectedRow(row.id === selectedRow ? -1 : row.id) }, { children: _jsx(Text, Object.assign({ size: "small" }, { children: row.name })) }), row.id)
                                ];
                                return (_jsx("div", Object.assign({ className: "calendar-row" + ((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getFilter(FilterGroup.Other, Filter.Weekend)) ? "" : " no-weekend"), style: { minHeight: "140px" } }, { children: cells.concat(dayList.map((day, idx) => {
                                        var _a;
                                        return (_jsx(DataCell, { tours: row.id ? row[day] : undefined, office_id: row.id, notes: row.id === 0 ? row[day] : undefined, elKey: "cell" + row.id + day, date: add(startOfWeek((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate) !== null && _a !== void 0 ? _a : new Date()), { days: idx }) }, "warppercell" + row.id + day));
                                    })) }), "row" + row.id));
                            })] }), "calendar") })) })))] })));
}));
