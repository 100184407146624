var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Data } from './';
import { DataType, NoteType } from './../types';
import { makeObservable, observable, computed, action } from 'mobx';
import { rootStore } from '../app';
import { startOfWeek, endOfWeek, formatISO, startOfDay } from 'date-fns';
export class Note extends Data {
    constructor(data) {
        super(DataType.Note, data);
        this.from = null;
        this.to = null;
        this.desc = null;
        this.office_id = null;
        this.title = null;
        this.type = null;
        this.user_links = [];
        this.setUserLinks = (users) => {
            this.user_links = (users ? users.map(u => u.id) : []);
        };
        this.save_user_links = () => __awaiter(this, void 0, void 0, function* () {
            if (!this.id)
                return;
            const storeNote = rootStore.dataStore.notes[this.id];
            const user_ids = this.user_links || [];
            const container_ids = (storeNote && storeNote.user_links) || [];
            const created_ids = user_ids.filter(id => !container_ids.includes(id));
            const removed_ids = container_ids.filter(id => !user_ids.includes(id));
            for (const user_id of created_ids)
                yield rootStore.userStore.link_note(user_id, this.id);
            for (const user_id of removed_ids)
                yield rootStore.userStore.unlink_note(user_id, this.id);
        });
        makeObservable(this, {
            desc: observable,
            office_id: observable,
            title: observable,
            user_links: observable,
            from: observable,
            to: observable,
            type: observable,
            getLinkedUsers: computed,
            isCurDay: computed,
            isCurWeek: computed,
            clone: action,
            move: action,
            setUserLinks: action,
            save_user_links: action,
        });
        if (data)
            this.fromData(data);
    }
    static create(data) {
        const defaultNote = Object.assign(Object.assign({}, Data.defaultValues), { desc: null, office_id: null, from: new Date(), to: new Date(), title: "", user_links: [], type: NoteType.Other });
        return new Note(Object.assign(defaultNote, data));
    }
    fromData(data) {
        super.fromData(data);
        this.from = data.from && new Date(data.from);
        this.to = data.to && new Date(data.to);
        this.desc = data.desc;
        this.office_id = data.office_id && Number(data.office_id);
        this.title = data.title;
        this.user_links = data.user_links && data.user_links.map(l => Number(l)) || this.user_links || [];
        this.type = data.type;
    }
    toData() {
        return Object.assign(Object.assign({}, super.toData()), { from: this.from && formatISO(this.from, { representation: "date" }), to: this.to && formatISO(this.to, { representation: "date" }), office_id: this.office_id, title: this.title, desc: this.desc, type: this.type });
    }
    get getLinkedUsers() {
        return this.user_links ? this.user_links.map(id => rootStore.userStore.getUser(id)) : [];
    }
    clone(office_id, date) {
        return __awaiter(this, void 0, void 0, function* () {
            const clone = Note.create(Object.assign(Object.assign({}, this), { id: undefined, date, office_id }));
            yield clone.save();
            return clone;
        });
    }
    move(office_id, date) {
        return __awaiter(this, void 0, void 0, function* () {
            this.office_id = office_id;
            yield this.save();
        });
    }
    get isCurDay() {
        if (!this.from || !this.to)
            return false;
        return startOfDay(this.from) <= startOfDay(rootStore.dataStore.getCurDate) && startOfDay(this.to) >= startOfDay(rootStore.dataStore.getCurDate);
    }
    get isCurWeek() {
        if (!this.from || !this.to)
            return false;
        const weekStart = startOfWeek(rootStore.dataStore.getCurDate);
        const weekEnd = endOfWeek(rootStore.dataStore.getCurDate);
        return this.from <= weekEnd && this.to >= weekStart;
    }
}
