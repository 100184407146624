import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tip, Box, Text } from "grommet";
import { inject, observer } from "mobx-react";
import { NoteTooltip } from "./noteTooltip";
import { useDrag } from "react-dnd";
import { DataType } from "../../types";
import { Agreement01Icon, Alert01Icon, EarthIcon, Hospital01Icon, InspectCodeIcon, Sun01Icon } from "hugeicons-react";
const noteTypeIcons = {
    "sick": _jsx(Hospital01Icon, { width: 18, height: 18 }),
    "vacation": _jsx(Sun01Icon, { width: 18, height: 18 }),
    "meeting": _jsx(Agreement01Icon, { width: 18, height: 18 }),
    "inspection": _jsx(InspectCodeIcon, { width: 18, height: 18 }),
    "europe": _jsx(EarthIcon, { width: 18, height: 18 }),
    "other": _jsx(Alert01Icon, { width: 18, height: 18 }),
};
export const NoteCard = inject('dataStore')(observer((props) => {
    const { note, dataStore, elKey, noDnD } = props;
    const [{ isDragging }, drag] = noDnD ? [{ isDragging: undefined }, undefined] : useDrag(() => ({
        type: DataType.Note,
        item: { item: note, key: elKey, type: DataType.Note },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));
    const office = note.office_id && (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOfficesUnfiltered[note.office_id].name) || "";
    const icon = noteTypeIcons[note.type];
    return (_jsx(Tip, Object.assign({ content: _jsx(NoteTooltip, { note: note }) }, { children: _jsx(Box, Object.assign({ className: "calendar-note", height: { min: "fit-content" }, direction: "row", justify: "between", align: "center", round: "xsmall", pad: "xsmall", margin: { bottom: "xsmall" }, background: "post-it", border: {
                color: "light-3",
                size: "1px",
            }, onClick: (e) => { e.stopPropagation(); dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveNote(note); } }, { children: _jsxs(Box, Object.assign({ basis: "full" }, { children: [_jsxs(Box, Object.assign({ direction: "row", justify: "between" }, { children: [_jsx(Text, Object.assign({ size: "xsmall", weight: "bold", margin: "none" }, { children: office })), icon] })), _jsx(Text, Object.assign({ size: "xsmall" }, { children: note.title }))] })) }), "note" + elKey + note.id) }), "tip" + elKey + note.id));
}));
