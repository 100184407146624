import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Heading, Box, TextInput, Button, Text, FormField } from "grommet";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { UserIcon } from "hugeicons-react";
const ForgotPassword = inject("userStore")(observer((props) => {
    const { t } = useTranslation();
    const { userStore } = props;
    const [email, setEmail] = React.useState("");
    const [sent, setSent] = React.useState(false);
    const [sentTo, setSentTo] = React.useState("");
    const submit = (e) => {
        e.preventDefault();
        if (userStore) {
            userStore.requestForgotPassword(email);
            setSentTo(email);
            setSent(true);
        }
    };
    return (_jsxs(Box, Object.assign({ margin: "auto", width: "100%", gap: "small", className: "forgotPassword" }, { children: [_jsx(Heading, Object.assign({ size: "small", textAlign: "center", style: { fontWeight: "normal" } }, { children: t("forgot_password") })), _jsx(Text, Object.assign({ textAlign: "center", size: "small" }, { children: t("enter_email") })), _jsx(Box, Object.assign({ margin: "none", className: "form-field-box" }, { children: _jsx(FormField, Object.assign({ margin: "none", className: "form-field" }, { children: _jsx(TextInput, { type: "email", placeholder: t("email"), icon: _jsx(UserIcon, {}), onChange: (e) => setEmail(e.target.value) }) })) })), _jsxs(Box, Object.assign({ direction: "row", gap: "large", align: "center", alignContent: "center", margin: "auto" }, { children: [!sent && (_jsx(Button, { style: { fontSize: "14px", fontWeight: "normal" }, color: "#053162", type: "submit", primary: true, disabled: !email.length, label: t("send"), onClick: (e) => submit(e) })), sent && _jsx(Text, Object.assign({ alignSelf: "center", size: "small" }, { children: t("check_inbox", { email: sentTo }) }))] })), _jsx(Link, Object.assign({ to: "/auth/login" }, { children: _jsx(Box, Object.assign({ width: "full", alignSelf: "center", alignContent: "center", fill: "horizontal" }, { children: _jsx(Text, Object.assign({ color: "#053162", margin: "auto" }, { children: t("back") })) })) }))] })));
}));
export default ForgotPassword;
