import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from '@/components/calendar/calendar';
import { CalendarSidebar } from '@/components/calendar/calendarSidebar';
import { MobileCalendar } from '@/components/calendar/mobileCalendar';
import { EditNote } from '@/components/editNote/editNote';
import { EditTour } from '@/components/editTour/editTour';
import { viewportSize } from '@/utils/viewport';
import { Box, Button } from 'grommet';
import { CalendarSetting02Icon } from 'hugeicons-react';
export const CalendarView = (props) => {
    const { t } = useTranslation();
    const { isMobile, isDesktop } = viewportSize();
    const storedMobileCalendar = localStorage.getItem('isMobileCalendar');
    const initialMobileCalendar = storedMobileCalendar ? JSON.parse(storedMobileCalendar) : true;
    const [isMobileCalendar, setIsMobileCalendar] = useState(initialMobileCalendar);
    useEffect(() => {
        localStorage.setItem('isMobileCalendar', JSON.stringify(isMobileCalendar));
    }, [isMobileCalendar]);
    const DesktopView = (_jsxs(_Fragment, { children: [_jsx(CalendarSidebar, {}), _jsx(Calendar, {})] }));
    const MobileView = (_jsxs(_Fragment, { children: [isMobileCalendar ? _jsx(MobileCalendar, {}) : _jsx(Calendar, {}), _jsx("div", Object.assign({ className: "calendar-toggle" }, { children: _jsx(Button, { style: { padding: '4px 6px', color: 'white' }, gap: 'small', icon: _jsx(CalendarSetting02Icon, { height: 18, width: 18 }), color: "white", label: isMobileCalendar ? t("view_week") : t("view_day"), onClick: () => setIsMobileCalendar((prev) => !prev) }) }))] }));
    return (_jsxs(Box, Object.assign({ fill: true, direction: "row", align: "stretch", background: "#f3f2ef" }, { children: [isDesktop && DesktopView, isMobile && MobileView, _jsx(EditTour, {}), _jsx(EditNote, {})] })));
};
