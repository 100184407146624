import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Box, Text } from "grommet";
import { useDrag } from "react-dnd";
import { DataType, ResourceStatus } from "../../types";
import { Car01Icon, DeliveryBox01Icon, StopWatchIcon } from "hugeicons-react";
export const VehicleListEntry = inject('dataStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { dataStore, vehicle } = props;
    const [{ isDragging }, drag] = useDrag(() => ({
        type: DataType.Vehicle,
        item: { item: vehicle, type: DataType.Vehicle },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));
    const status = vehicle.getStatus;
    const bg_color = (status === ResourceStatus.Conflict ?
        "status-critical-light" :
        (status === ResourceStatus.Assigned ?
            "light-2" :
            undefined));
    const textColor = status === ResourceStatus.Assigned ? "#999999" : undefined;
    return (_jsx(Box, Object.assign({ ref: drag, className: "list-entry", flex: false, direction: "row", justify: "between", align: "center", fill: "horizontal", background: bg_color, onClick: () => {
            if (Boolean(vehicle.temp_date))
                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveVehicle(vehicle);
        }, pad: {
            vertical: "xsmall",
            horizontal: "small"
        }, style: {
            cursor: "grab"
        } }, { children: _jsxs(Box, Object.assign({ gap: "xsmall", flex: false, justify: "start", fill: "horizontal" }, { children: [_jsxs(Box, Object.assign({ justify: "between", direction: "row" }, { children: [_jsx(Text, Object.assign({ color: textColor, truncate: true, size: "medium" }, { children: vehicle.name })), !!vehicle.temp_date && _jsx(StopWatchIcon, { size: 20, color: textColor })] })), _jsxs(Box, Object.assign({ className: "list-entry-details", direction: "row", justify: "between", fill: "horizontal" }, { children: [_jsxs(Box, Object.assign({ pad: { right: "small" }, direction: "row", align: "center" }, { children: [_jsx(DeliveryBox01Icon, { color: textColor, size: 16 }), _jsx(Text, Object.assign({ color: textColor, size: "small", style: { float: "right" } }, { children: vehicle.volume || "-" }))] })), _jsx(Box, Object.assign({ pad: { right: "small" }, direction: "row" }, { children: _jsx(Text, Object.assign({ color: textColor, size: "small" }, { children: t(vehicle.type) || "-" })) })), _jsxs(Box, Object.assign({ direction: "row" }, { children: [_jsx(Car01Icon, { color: textColor, size: 16 }), _jsx(Text, Object.assign({ color: textColor, size: "small" }, { children: vehicle.license || "-" }))] }))] }))] })) })));
}));
