import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDrag } from 'react-dnd';
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Box, Text } from "grommet";
import { TourIcon } from "../calendar/tourIcon";
import { format } from "date-fns";
import { AppRoute } from "../../routes";
import { DataType } from "../../types";
import { TruckIcon, UserIcon } from "hugeicons-react";
import { useNavigate } from 'react-router-dom';
export const TourListEntry = inject('dataStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { dataStore, tour } = props;
    const navigate = useNavigate();
    const [{ isDragging }, drag] = useDrag(() => ({
        type: DataType.Tour,
        item: { item: tour, type: DataType.Tour },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));
    const time = tour.start_time && format(tour.start_time, 'HH:mm') || '00:00';
    const label = tour.label ? t(tour.label) : undefined;
    const volume = tour.volume ? "" + tour.volume + "m³" : undefined;
    const isReady = tour.isComplete;
    const textColor = isReady ? "#999999" : undefined;
    return (_jsxs(Box, Object.assign({ ref: drag, className: "tourList-entry", round: "var(--radius)", border: "all", elevation: "xsmall", width: "220px", pad: "xsmall", margin: { bottom: "small" }, background: isReady ? "light-2" : undefined, hoverIndicator: !isReady, onClick: () => {
            dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(tour);
            navigate(AppRoute.Calendar);
        } }, { children: [_jsxs(Box, Object.assign({ basis: "full" }, { children: [_jsxs(Box, Object.assign({ direction: "row", justify: "between" }, { children: [_jsx(Text, Object.assign({ size: "xsmall", weight: "bold", margin: "none" }, { children: [time, label, volume].filter(s => Boolean(s)).join(" - ") })), _jsxs(Box, Object.assign({ direction: "row", gap: "xsmall", basis: "auto" }, { children: [tour.label && (_jsx(TourIcon, { type: tour.label })), Boolean(tour.lime_id) && (_jsx(TourIcon, { type: "linked" }))] }))] })), _jsx(Text, Object.assign({ size: "xsmall", truncate: true }, { children: tour.title }))] })), _jsxs(Box, Object.assign({ className: "tourList-entry-details", direction: "row", justify: "between", gap: "xsmall", align: "baseline" }, { children: [_jsxs(Box, Object.assign({ direction: "row", gap: "xsmall" }, { children: [_jsx(UserIcon, { size: 16, stroke: textColor }), _jsx(Text, Object.assign({ color: textColor }, { children: tour.getTourUsers.length + "/" + tour.max_users }))] })), _jsxs(Box, Object.assign({ direction: "row", gap: "xsmall" }, { children: [_jsx(TruckIcon, { size: 16, color: textColor }), _jsx(Text, Object.assign({ color: textColor }, { children: tour.getTourVehicles.length + "/" + tour.max_vehicles }))] }))] }))] })));
}));
