import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { inject, observer } from "mobx-react";
import { Teams } from "../components/scheduler/teams";
import { Box, Button, ResponsiveContext } from "grommet";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FormComponent } from "../components/formComponents/formComponent";
import { DataType, TourProps } from "../types";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { PlannerPrintView } from "./PlannerPrintView";
import { PrinterIcon } from "hugeicons-react";
import { CalendarDateSelection } from "@/components/calendar/calendarDateSelection";
export const Planner = inject('dataStore', 'userStore')(observer((props) => {
    const { t } = useTranslation();
    const { dataStore, userStore } = props;
    const printContainerRef = React.useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printContainerRef.current,
    });
    const size = React.useContext(ResponsiveContext);
    const selected_office = (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveOffice) || (userStore === null || userStore === void 0 ? void 0 : userStore.getDefaultOffice) || null;
    React.useEffect(() => {
        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveOffice(selected_office);
    }, [dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveOffice, userStore === null || userStore === void 0 ? void 0 : userStore.getDefaultOffice]);
    return (_jsxs(_Fragment, { children: [_jsx(DndProvider, Object.assign({ backend: HTML5Backend }, { children: _jsxs(Box, Object.assign({ fill: true, background: '#f3f2ef', pad: { vertical: size === "small" ? "large" : "medium", horizontal: "medium" }, gap: "medium" }, { children: [_jsxs(Box, Object.assign({ align: size === "small" ? "center" : undefined, justify: "between", direction: size === "small" ? "column" : "row", fill: "horizontal" }, { children: [_jsx(Box, Object.assign({ margin: { bottom: size === "small" ? '2rem' : "0" } }, { children: _jsx(FormComponent, { type: DataType.Office, inputType: "select", prop: TourProps.Office_Id, label: t("office"), containerProps: {
                                            fill: "horizontal",
                                            gap: "small",
                                            direction: "row",
                                            align: "center"
                                        }, labelProps: {
                                            size: "var(--FormComponent-font-lg)",
                                            weight: "bold"
                                        }, inputCmpProps: {
                                            options: Object.values((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOffices) || {}),
                                            labelKey: "name",
                                            valueKey: "id",
                                            placeholder: t("select_office"),
                                            value: selected_office || undefined,
                                            onChange: (event) => {
                                                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(null);
                                                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveOffice(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOfficeByID(event.option.id));
                                            },
                                        } }) })), _jsx(Box, Object.assign({ direction: "row", align: "center", justify: "center", gap: "medium", alignContent: "center" }, { children: _jsxs(Box, Object.assign({ direction: "row", gap: "small" }, { children: [_jsx(Button, { primary: true, color: "transparent", icon: _jsx(PrinterIcon, {}), onClick: handlePrint }), _jsx(CalendarDateSelection, { dayMode: true })] })) }))] })), _jsx(Box, Object.assign({ fill: true, pad: "medium", margin: { top: size === "small" ? "1rem" : undefined }, align: size === "small" ? "center" : undefined, overflow: "auto", className: "planner-teams", background: "white", round: "6px", border: "all" }, { children: _jsx(Teams, { readonly: true }) }))] })) })), _jsx("div", Object.assign({ ref: printContainerRef, className: "print-only" }, { children: _jsx(PlannerPrintView, {}) }))] }));
}));
