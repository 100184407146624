import { Data } from './';
import { DataType } from './../types';
import { observable, makeObservable, action } from 'mobx';
export class Marker extends Data {
    constructor(data) {
        super(DataType.Marker, data);
        this.latitude = Marker.Stockholm.latitude;
        this.longitude = Marker.Stockholm.longitude;
        this.text = "";
        this.type = "default";
        this.tour_id = null;
        this.setText = (text) => {
            this.text = text;
        };
        makeObservable(this, {
            text: observable,
            longitude: observable,
            latitude: observable,
            type: observable,
            tour_id: observable,
            setText: action,
        });
        this.fromData(data);
    }
    static create(data) {
        return new Marker(data);
    }
    fromData(data) {
        super.fromData(data);
        this.text = data.text;
        this.longitude = data.longitude;
        this.latitude = data.latitude;
        this.type = data.type;
        this.tour_id = Number(data.tour_id);
    }
    toData() {
        return Object.assign(Object.assign({}, super.toData()), { text: this.text, latitude: this.latitude, longitude: this.longitude, type: this.type, tour_id: this.tour_id, updated_by: undefined });
    }
}
Marker.Stockholm = { longitude: 18.0649000, latitude: 59.3325800 };
