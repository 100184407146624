var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Box, Button, Text, Layer, TextInput } from "grommet";
import { ClockButton } from "./clockButton";
import { getHours, getMinutes } from "date-fns";
import { Clock } from "grommet-icons";
export const TimePicker = (props) => {
    const clock_radius = 130;
    const { label, labelProps, disabled, onChange, value, hideIcon, } = props;
    const { cancelLabel, confirmLabel, } = {
        cancelLabel: props.cancelLabel || "Cancel",
        confirmLabel: props.confirmLabel || "OK",
    };
    const [minute, setMinute] = React.useState((value && Math.ceil(getMinutes(value) / 5) * 5 % 60) || 0);
    const [hour, setHour] = React.useState((value && getHours(value)) || 0);
    const [hourMode, setHourMode] = React.useState(true);
    const [dlgOpen, setDlgOpen] = React.useState(false);
    const confirm = (h, m) => {
        const timeString = String(h).padStart(2, "0") + ":" + String(m).padStart(2, "0");
        onChange && onChange(timeString);
        close();
    };
    const close = () => {
        setHourMode(true);
        setDlgOpen(false);
    };
    const renderClockButtons = () => {
        const elements = [];
        if (hourMode) {
            for (let i = 1; i <= 12; i++) {
                elements.push(_jsx(ClockButton, Object.assign({ idx: i % 12, radius: clock_radius, numItems: 12, onClick: () => {
                        setHour(i);
                        setHourMode(false);
                    }, selected: hour === i }, { children: i }), i % 12 + "am"));
            }
            for (let i = 1; i <= 12; i++) {
                elements.push(_jsx(ClockButton, Object.assign({ idx: i % 12, radius: clock_radius * 0.7, numItems: 12, onClick: () => {
                        setHour(i + 12);
                        setHourMode(false);
                    }, selected: hour === (i + 12) }, { children: String((i + 12) % 24).padStart(2, "0") }), String((i + 12) % 24).padStart(2, "0") + "pm"));
            }
        }
        else {
            for (let i = 0, j = 0; i <= 60; i += 5, j++) {
                elements.push(_jsx(ClockButton, Object.assign({ idx: j % 12, radius: clock_radius * 0.95, numItems: 12, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        setMinute(i % 60);
                        confirm(hour, i % 60);
                    }), selected: minute === (i) }, { children: String(i % 60).padStart(2, "0") }), j + "min"));
            }
        }
        return elements;
    };
    const getValue = (hour, minute) => {
        if (!value)
            return "--:--";
        return String(hour).padStart(2, "0") + ":" + String(minute).padStart(2, "0");
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, Object.assign({ style: { border: 'none' } }, { children: [Boolean(label) && _jsx(Text, Object.assign({ weight: "bold" }, labelProps, { children: label })), _jsx(TextInput, { readOnly: true, reverse: true, textAlign: "end", disabled: disabled, icon: hideIcon ? undefined : _jsx(Clock, {}), value: getValue(hour, minute) + "  ", onClick: () => setDlgOpen(true), style: {
                            border: "none",
                            padding: "0px",
                            paddingRight: hideIcon ? "0px" : "40px",
                            backgroundColor: "inherit"
                        } })] })), dlgOpen && _jsx(Layer, Object.assign({ onEsc: () => setDlgOpen(false), onClickOutside: () => setDlgOpen(false) }, { children: _jsxs(Box, Object.assign({ className: "tp-container", width: "medium" }, { children: [_jsxs(Box, Object.assign({ className: "tp-header", pad: "small", align: "center", justify: "center", background: "brand", direction: "row" }, { children: [_jsx(Button, Object.assign({ hoverIndicator: true, onClick: () => setHourMode(true) }, { children: _jsx(Text, Object.assign({ size: "3em" }, { children: `${String(hour % 24).padStart(2, "0")}` })) })), _jsx(Text, Object.assign({ size: "3em" }, { children: ":" })), _jsx(Button, Object.assign({ hoverIndicator: true, onClick: () => setHourMode(false) }, { children: _jsx(Text, Object.assign({ size: "3em" }, { children: `${String(minute).padStart(2, "0")}` })) }))] })), _jsxs(Box, Object.assign({ className: "tp-body", background: "white" }, { children: [_jsx(Box, Object.assign({ className: "tp-clock-container", fill: "vertical", justify: "center", align: "center", pad: "small" }, { children: _jsx(Box, Object.assign({ background: "#7AACE6", round: "full", width: "310px", height: "310px", className: "tp-clock", style: {
                                            position: "relative",
                                        } }, { children: renderClockButtons() })) })), _jsxs(Box, Object.assign({ direction: "row", className: "tp-button-area", justify: "end", pad: "small", gap: "small" }, { children: [_jsx(Button, Object.assign({ hoverIndicator: true, onClick: close }, { children: cancelLabel })), _jsx(Button, Object.assign({ hoverIndicator: true, onClick: () => confirm(hour, minute) }, { children: confirmLabel }))] }))] }))] })) }))] }));
};
