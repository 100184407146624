import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, DateInput, ResponsiveContext, Text } from "grommet";
import { inject, observer } from "mobx-react";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import SunEditor from "suneditor-react";
import { DataType, NoteProps, NoteType } from "../../types";
import { FormComponent } from "../formComponents/formComponent";
const editor_sv = require('../../../locales/editor_sv.json');
export const EditNoteForm = inject("dataStore", "userStore")(observer((props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const size = useContext(ResponsiveContext);
    const { t, i18n } = useTranslation();
    const { dataStore, userStore } = props;
    const noteTypes = Object.values(NoteType).map((type) => ({ label: t(type), value: type }));
    const fromDate = (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) && ((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _a === void 0 ? void 0 : _a.from) && ((_b = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _b === void 0 ? void 0 : _b.from.toISOString()) || new Date().toISOString();
    const toDate = ((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) && ((_c = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _c === void 0 ? void 0 : _c.to)) ? (_d = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _d === void 0 ? void 0 : _d.to.toISOString() : fromDate;
    const [showToolbar, setShowToolbar] = React.useState(false);
    const renderMultiSelectValue = () => {
        var _a;
        const user_strings = ((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _a === void 0 ? void 0 : _a.getLinkedUsers.map((u) => u && u.displayName)) || [];
        return (_jsx(Box, Object.assign({ pad: "11px", width: { max: "200px" } }, { children: _jsx(Text, Object.assign({ size: "18px", color: user_strings.length ? undefined : "dark-5", truncate: true }, { children: user_strings.length ? user_strings.join(", ") : t("select_users") })) })));
    };
    return (_jsxs(Box, Object.assign({ width: "medium", gap: "medium", fill: "horizontal" }, { children: [_jsx(Box, { children: _jsx(DateInput, { format: "mm/dd - mm/dd/yyyy", value: [fromDate, toDate], onChange: ({ value }) => {
                        const [from, to] = value;
                        from && (dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveProp(DataType.Note, NoteProps.From, new Date(from)));
                        to && (dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveProp(DataType.Note, NoteProps.To, new Date(to)));
                    }, calendarProps: {
                        range: true,
                        style: { width: size === "small" ? "340px" : "100%" },
                    } }) }), _jsx(FormComponent, { type: DataType.Note, inputType: "select", prop: NoteProps.Office_Id, label: t("office"), inputCmpProps: {
                    options: Object.values((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOffices) || {}),
                    labelKey: "name",
                    onChange: (event) => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveProp(DataType.Note, NoteProps.Office_Id, event.option.id),
                    valueKey: "id",
                    value: ((_e = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _e === void 0 ? void 0 : _e.office_id) ? (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOffices[(_f = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _f === void 0 ? void 0 : _f.office_id]) ||
                        undefined : undefined,
                    placeholder: t("select_office"),
                } }), _jsx(FormComponent, { type: DataType.Note, prop: NoteProps.Title, label: t("title"), inputCmpProps: {
                    autoFocus: true,
                    value: ((_g = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _g === void 0 ? void 0 : _g.title) || "",
                } }), _jsxs(Box, Object.assign({ direction: size === "small" ? "column" : "row", justify: "between", gap: "small" }, { children: [_jsx(Box, Object.assign({ width: size === "small" ? "100%" : "40%" }, { children: _jsx(FormComponent, { type: DataType.Note, inputType: "select", prop: NoteProps.Type, label: t("type"), inputCmpProps: {
                                labelKey: "label",
                                valueKey: "value",
                                options: noteTypes,
                                value: noteTypes.find((v) => { var _a; return v.value == ((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _a === void 0 ? void 0 : _a.type); }),
                                onChange: ({ option }) => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveProp(DataType.Note, NoteProps.Type, option.value),
                                placeholder: t("select_type")
                            } }) })), _jsx(Box, Object.assign({ fill: "horizontal" }, { children: _jsx(FormComponent, { type: DataType.Note, inputType: "select", label: t("user_plural"), inputCmpProps: {
                                autoFocus: false,
                                multiple: true,
                                closeOnChange: false,
                                options: (userStore === null || userStore === void 0 ? void 0 : userStore.getUsers) || [],
                                onChange: (event) => {
                                    var _a;
                                    (_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _a === void 0 ? void 0 : _a.setUserLinks(event.value);
                                },
                                valueKey: "id",
                                labelKey: (user) => user.displayName,
                                value: (_h = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _h === void 0 ? void 0 : _h.getLinkedUsers,
                                valueLabel: renderMultiSelectValue(),
                                placeholder: t("select_users"),
                            } }) }))] })), _jsxs(Box, { children: [_jsx(Text, Object.assign({ weight: "bold", margin: { bottom: "xsmall" } }, { children: t("desc") })), _jsx(Wrapper, Object.assign({ showToolbar: showToolbar }, { children: _jsx(SunEditor, { setOptions: {
                                plugins: [],
                                toolbarContainer: "#toolbar_container",
                                buttonList: [
                                    [
                                        "-top-right",
                                        ":i-More-default.more_vertical",
                                        "fontColor",
                                        "hiliteColor",
                                        "align",
                                        "horizontalRule",
                                        "list",
                                        "table",
                                        "link",
                                    ],
                                    ["fullScreen", "undo", "redo"],
                                    [
                                        "fontSize",
                                        "formatBlock",
                                        "bold",
                                        "underline",
                                        "italic",
                                        "removeFormat",
                                    ],
                                ],
                                resizingBar: false,
                                minHeight: "200px",
                            }, setContents: ((_j = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _j === void 0 ? void 0 : _j.desc) || "", onChange: (richText) => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveProp(DataType.Note, NoteProps.Desc, richText), onBlur: () => setShowToolbar(false), onFocus: () => setShowToolbar(true), autoFocus: false, lang: i18n.language === "sv" ? editor_sv : i18n.language }) }))] })] })));
}));
const Wrapper = styled.div `
  .se-toolbar {
    display: ${p => !p.showToolbar && 'none'};
  }
`;
