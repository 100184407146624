var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Data } from './';
import { DataType } from './../types';
import { observable, makeObservable } from 'mobx';
import axios from 'axios';
import client from '../feathers';
export class FileData extends Data {
    constructor(data) {
        super(DataType.File, data);
        this.name = null;
        this.type = null;
        this.size = null;
        this.key = null;
        this.blob = null;
        this.parent_id = null;
        this.parent_type = null;
        this.progress = 0;
        this.uploading = false;
        this.getURL = () => __awaiter(this, void 0, void 0, function* () {
            if (!this.key)
                return '';
            return yield this.storage_service.get(this.key);
        });
        makeObservable(this, {
            name: observable,
            type: observable,
            size: observable,
            key: observable,
            progress: observable,
            uploading: observable,
        });
        this.fromData(data);
        this.storage_service = client.service(DataType.Storage);
    }
    static create(data) {
        return new FileData(data);
    }
    fromData(data) {
        super.fromData(data);
        this.name = data.name;
        this.size = data.size;
        this.type = data.type;
        this.blob = data.blob;
        this.parent_id = data.parent_id;
        this.parent_type = data.parent_type;
        this.key = data.key;
    }
    toData() {
        return Object.assign(Object.assign({}, super.toData()), { name: this.name, size: this.size, type: this.type, key: this.key, parent_type: this.parent_type, parent_id: this.parent_id, updated_by: undefined, updated_at: undefined });
    }
    save() {
        const _super = Object.create(null, {
            save: { get: () => super.save }
        });
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { url, key, created_at } = yield this.storage_service.create(this.toData());
                if (!(url && key && created_at)) {
                    console.error("Failed to created signed URL.");
                    return;
                }
                this.key = key;
                this.created_at = new Date(created_at);
                const options = {
                    headers: {
                        'Content-Type': this.type,
                        'x-amz-acl': 'private'
                    },
                    onUploadProgress: (progressEvent) => this.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
                };
                this.uploading = true;
                const result = yield axios.put(url, this.blob, options);
                this.uploading = false;
            }
            catch (err) {
                console.error(err);
            }
            if (!this.key) {
                console.error("Failed to upload file.");
                return;
            }
            yield _super.save.call(this);
            if (!this.id) {
                console.error("Failed to create database object. Rolling back");
                try {
                    this.storage_service.remove(this.key);
                }
                catch (err) {
                    console.error("Failed clean up. Potentially orphaned data with key ", this.key);
                }
            }
        });
    }
    get getProgress() {
        return this.progress;
    }
    get isUploading() {
        return this.uploading;
    }
}
