import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Users } from "../components/resources/users";
import { Vehicles } from "../components/resources/vehicles";
import { ResourceSidebar } from "../components/resources/resourceSidebar";
import { Box } from "grommet";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
export const ResourceView = observer(() => {
    let location = useLocation();
    function getView(pathname) {
        switch (pathname) {
            case '/resources':
                return _jsx(Users, {});
            case '/resources/vehicles':
                return _jsx(Vehicles, {});
            case '/resources/vehicles/:vehicleId':
                return _jsx(Vehicles, {});
            case "/resources/users":
                return _jsx(Users, {});
            case "/resources/users/:userId":
                return _jsx(Users, {});
            default:
                return _jsx(Users, {});
        }
    }
    return (_jsxs(Box, Object.assign({ fill: true, direction: "row", align: "stretch", background: '#f3f2ef' }, { children: [_jsx(ResourceSidebar, {}), getView(location.pathname)] })));
});
