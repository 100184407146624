import { UserStore, DataStore, MapStore, TeamStore } from '.';
import { makeObservable, observable, action } from 'mobx';
export class RootStore {
    constructor() {
        this.rootStore = this;
        this.userStore = new UserStore(this);
        this.dataStore = new DataStore(this);
        this.mapStore = new MapStore(this);
        this.teamStore = new TeamStore(this);
        this.showSystemMessage = false;
        this.messageText = "Some Message";
        this.messageType = "error";
        this.toggleSystemMessage = (message) => {
            if (message)
                this.setSystemMessage(message.text, message.type);
            this.showSystemMessage = !this.showSystemMessage;
        };
        this.setSystemMessage = (text, type) => {
            this.messageText = text;
            this.messageType = type;
        };
        this.displaySystemMessage = (timeout, message) => {
            if (this.showSystemMessage)
                return;
            if (message)
                this.setSystemMessage(message.text, message.type);
            this.showSystemMessage = true;
            setTimeout(() => this.toggleSystemMessage(), timeout);
        };
        makeObservable(this, {
            showSystemMessage: observable,
            messageText: observable,
            messageType: observable,
            toggleSystemMessage: action,
            displaySystemMessage: action,
            setSystemMessage: action,
        });
    }
}
