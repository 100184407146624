import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Box, List } from "grommet";
import { Vehicle } from "../../models";
import { VehicleListEntry } from "./vehicleListEntry";
export const VehicleList = inject('dataStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { dataStore } = props;
    const vehicles = (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getSchedulerVehicles.filter(v => v.vehicle_status === 'operational').sort(Vehicle.compare)) || [];
    return (_jsx(Box, Object.assign({ fill: true, basis: "1/2", className: "tourList", background: "white", border: "left" }, { children: _jsx(Box, Object.assign({ className: "tourList-body", overflow: { vertical: "auto" } }, { children: _jsx(List, { data: vehicles, children: (vehicle) => (_jsx(VehicleListEntry, { vehicle: vehicle }, vehicle.id)), pad: "none" }) })) })));
}));
