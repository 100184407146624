var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput, Button, Grid, Box, FormField, Grommet } from "grommet";
import client from "./../feathers";
const localTheme = {
    global: {
        colors: {
            focus: "neutral-3",
        },
        breakpoints: {
            small: {
                value: 900,
            },
            medium: {
                value: 1500,
            },
            large: {
                value: 3000,
            },
        },
    },
};
export const EmailDebug = () => {
    const [receiver, setReceiver] = useState("jonas@limeloop.se");
    const { t, i18n } = useTranslation();
    const send = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        const result = yield client.service('invite_user').create({ receiver });
    });
    return (_jsx(Grommet, Object.assign({ theme: localTheme }, { children: _jsx(Grid, Object.assign({ alignContent: "center", margin: "auto", justifyContent: "center", style: { height: "100%" } }, { children: _jsxs(Box, Object.assign({ pad: "small", gap: "medium", width: "medium" }, { children: [_jsx(FormField, Object.assign({ name: "name", htmlFor: "text-input-id" }, { children: _jsx(TextInput, { style: { fontSize: "14px", fontWeight: "normal" }, id: "text-input-id", name: "Email", placeholder: "Email", defaultValue: receiver, onChange: (e) => setReceiver(e.target.value) }) })), _jsx(Button, { color: "#053162", type: "submit", label: t("send"), onClick: send })] })) })) })));
};
