import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Box, Layer, Button } from 'grommet';
import styled from 'styled-components';
import { Close } from 'grommet-icons';
export const ImageComment = (props) => {
    const { t, i18n } = useTranslation();
    const { image } = props;
    const [showLayer, setShowLayer] = React.useState(false);
    const [url, setURL] = React.useState(null);
    React.useEffect(() => { image.getURL().then(url => setURL(url)); }, []);
    return (_jsxs(Box, Object.assign({ hoverIndicator: true, className: "comment-image-container", style: { display: "inline-block" }, align: "center", background: "light-4", direction: "row", gap: "small", pad: "xsmall", round: "4px", margin: { bottom: "xsmall" }, flex: false, justify: "center", onClick: (event) => {
            setShowLayer(Boolean(url));
            event.stopPropagation();
        } }, { children: [_jsx("img", { src: url ? url : undefined, className: "comment-image-file", alt: t("loading_image") }), showLayer &&
                _jsx(Layer, Object.assign({ style: { position: "relative" }, onClickOutside: () => setShowLayer(false), onEsc: () => setShowLayer(false), margin: "large" }, { children: _jsxs(Box, Object.assign({ overflow: "auto" }, { children: [_jsx(CloseButton, { hoverIndicator: true, icon: _jsx(Close, { size: 'medium' }), onClick: (event) => {
                                    setShowLayer(false);
                                    event.stopPropagation();
                                } }), _jsx(Image, { src: url !== null && url !== void 0 ? url : undefined })] })) }))] })));
};
const CloseButton = styled(Button) `
  position: absolute;
  top:      1rem;
  right:    3rem;
`;
