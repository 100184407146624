import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, TextArea, MaskedInput, CheckBox, Select } from "grommet";
import { inject, observer } from "mobx-react";
import { DataType } from "../../types";
export const FormComponent = inject('dataStore', 'userStore')(observer((props) => {
    const { dataStore, userStore, type, editable } = props;
    const inputComponent = () => {
        switch (props.inputType) {
            case "select":
                return _jsx(Select, Object.assign({}, props.inputCmpProps));
            case "checkbox":
                return _jsx(CheckBox, Object.assign({ readOnly: !(userStore === null || userStore === void 0 ? void 0 : userStore.isAdminOrSales), onChange: (event) => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveProp(type, props.prop, event.target.checked) }, props.inputCmpProps, { checked: props.inputCmpProps.checked || false }));
            case "maskedinput":
                return _jsx(MaskedInput, Object.assign({ onChange: (event) => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveProp(type, props.prop, event.target.value) }, props.inputCmpProps, { value: props.inputCmpProps.value || "" }));
            default:
                if (props.unit)
                    return (_jsxs(Box, Object.assign({ direction: "row", justify: "between", gap: "small", align: "center" }, { children: [_jsx(TextArea, Object.assign({ readOnly: !(userStore === null || userStore === void 0 ? void 0 : userStore.isAdminOrSales) && !editable, rows: 1, resize: false, onChange: (event) => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveProp(type, props.prop, event.target.value) }, props.inputCmpProps, { value: props.inputCmpProps.value || "" })), _jsx(Text, { children: props.unit })] })));
                return _jsx(TextArea, Object.assign({ readOnly: !(userStore === null || userStore === void 0 ? void 0 : userStore.isAdminOrSales) && !editable, rows: 1, resize: false, onChange: (event) => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveProp(type, props.prop, event.target.value) }, props.inputCmpProps, { value: props.inputCmpProps.value || "" }));
        }
    };
    const val = type === DataType.Tour ? (dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour) && (dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour.validateProp(props.prop))
        : (type === DataType.Note ? (dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeNote) && (dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeNote.validateProp(props.prop))
            : true);
    return (_jsxs(Box, Object.assign({}, props.containerProps, { flex: false }, { children: [_jsxs(Box, Object.assign({ direction: "row" }, { children: [_jsx(Text, Object.assign({ weight: "bold" }, props.labelProps, { margin: { vertical: 'xsmall' } }, { children: props.label })), !val && _jsx(Text, Object.assign({ color: "status-error" }, { children: `\xa0*` }))] })), inputComponent()] })));
}));
