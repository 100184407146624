import { Data } from './';
import { DataType } from './../types';
import { observable, makeObservable } from 'mobx';
export class Office extends Data {
    constructor(data) {
        super(DataType.Office, data);
        this.name = "";
        makeObservable(this, {
            name: observable
        });
        this.fromData(data);
    }
    static create(data) {
        const defaultOffice = Object.assign(Object.assign({}, Data.defaultValues), { name: '' });
        return new Office(Object.assign(defaultOffice, data));
    }
    fromData(data) {
        super.fromData(data);
        this.name = data.name;
    }
    toData() {
        return Object.assign(Object.assign({}, super.toData()), { name: this.name });
    }
}
