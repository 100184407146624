var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Header, Layer, Box, Button, Text, Footer } from "grommet";
import { FormComponent } from "../../formComponents/formComponent";
import { Close, Save, Trash } from "grommet-icons";
import { inject, observer } from "mobx-react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { VehicleProps, DataType, VehicleTypes, VehicleStatus, VehicleLicense } from "../../../types";
import { DriverLicenseOptions } from "../../../utils";
import { runInAction } from "mobx";
import toast from "react-hot-toast";
export const VehicleEdit = inject("dataStore", "userStore")(observer((props) => {
    const { t, i18n } = useTranslation();
    const close = () => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveVehicle(null);
    const { dataStore, userStore, lock_office } = props;
    const vehicle = (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveVehicle) || null;
    const [volume, setVolume] = React.useState("");
    React.useEffect(() => {
        setVolume(vehicle && vehicle.volume ? "" + vehicle.volume : "0");
    }, [vehicle]);
    if (!vehicle)
        return null;
    const vehicleTypes = [
        {
            label: t("car"),
            value: VehicleTypes.CAR,
        },
        {
            label: t("light_truck"),
            value: VehicleTypes.LIGHT_TRUCK,
        },
        {
            label: t("truck"),
            value: VehicleTypes.TRUCK,
        },
    ];
    const vehicleStatus = [
        {
            label: t(VehicleStatus.Operational),
            value: VehicleStatus.Operational,
        },
        {
            label: t(VehicleStatus.Not_operational),
            value: VehicleStatus.Not_operational,
        }, {
            label: t(VehicleStatus.Terminated),
            value: VehicleStatus.Terminated,
        },
    ];
    return (_jsx(Layer, Object.assign({ onEsc: close, onClickOutside: close }, { children: _jsxs(Box, Object.assign({ round: "4px", style: { minHeight: "550px", minWidth: "400px" }, justify: "between" }, { children: [_jsxs(Box, Object.assign({ fill: "horizontal" }, { children: [_jsx(Header, Object.assign({ justify: "between", direction: "row", fill: "horizontal" }, { children: _jsx(Button, { hoverIndicator: true, icon: _jsx(Close, {}), onClick: close }) })), _jsx(Box, Object.assign({ pad: { vertical: "xsmall", horizontal: "large" }, fill: "horizontal" }, { children: _jsx(FormComponent, { type: DataType.Vehicle, prop: VehicleProps.Name, label: t("name"), inputCmpProps: {
                                    autoFocus: true,
                                    value: vehicle.name || "",
                                    onChange: (event) => {
                                        runInAction(() => vehicle.name = event.target.value);
                                    },
                                } }) })), _jsx(Box, Object.assign({ pad: { vertical: "xsmall", horizontal: "large" }, fill: "horizontal" }, { children: _jsx(FormComponent, { type: DataType.Vehicle, prop: VehicleProps.Type, label: t("type"), inputType: "select", inputCmpProps: {
                                    value: vehicleTypes.find((v) => v.value == vehicle.type),
                                    labelKey: "label",
                                    valueKey: "value",
                                    placeholder: t("select_type"),
                                    options: vehicleTypes,
                                    onChange: ({ option }) => {
                                        runInAction(() => vehicle.type = option.value);
                                    },
                                } }) })), _jsx(Box, Object.assign({ pad: { vertical: "xsmall", horizontal: "large" }, fill: "horizontal" }, { children: _jsx(FormComponent, { type: DataType.Vehicle, prop: VehicleProps.License, label: t("select_license"), inputType: "select", inputCmpProps: {
                                    value: DriverLicenseOptions.find((v) => v.value == vehicle.license),
                                    labelKey: "label",
                                    valueKey: "value",
                                    placeholder: t("select_license"),
                                    options: DriverLicenseOptions.filter(v => v.value === VehicleLicense.B || v.value === VehicleLicense.C),
                                    onChange: ({ option }) => {
                                        runInAction(() => vehicle.license = option.value);
                                    },
                                } }) })), _jsx(Box, Object.assign({ pad: { vertical: "xsmall", horizontal: "large" }, fill: "horizontal" }, { children: _jsx(FormComponent, { type: DataType.Vehicle, prop: VehicleProps.Volume, label: t("volume"), inputType: "textarea", inputCmpProps: {
                                    value: volume,
                                    onChange: (event) => {
                                        const val = event.target.value.replace(/\D/g, "");
                                        runInAction(() => vehicle.volume = Number(val) || 0);
                                        setVolume(val);
                                    },
                                } }) })), _jsx(Box, Object.assign({ pad: { vertical: "xsmall", horizontal: "large" }, fill: "horizontal" }, { children: _jsx(FormComponent, { type: DataType.Vehicle, inputType: "select", prop: VehicleProps.Office_Id, label: t("office"), inputCmpProps: {
                                    autoFocus: false,
                                    options: Object.values((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOffices) || {}),
                                    labelKey: "name",
                                    onChange: (event) => (runInAction(() => vehicle.office_id = event.option.id)),
                                    valueKey: "id",
                                    disabled: lock_office,
                                    value: (vehicle && vehicle.office_id) ? dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOffices[vehicle.office_id] : undefined,
                                    placeholder: t("select_office"),
                                } }) })), _jsx(Box, Object.assign({ pad: { vertical: "xsmall", horizontal: "large" }, fill: "horizontal" }, { children: _jsx(FormComponent, { type: DataType.Vehicle, prop: VehicleProps.Vehicle_status, label: t("status"), inputType: "select", inputCmpProps: {
                                    autoFocus: false,
                                    value: vehicleStatus.find((v) => v.value == vehicle.vehicle_status),
                                    labelKey: "label",
                                    valueKey: "value",
                                    placeholder: t("set_status"),
                                    options: vehicleStatus,
                                    onChange: ({ option }) => {
                                        runInAction(() => vehicle.vehicle_status = option.value);
                                    },
                                } }) }))] })), _jsxs(Footer, Object.assign({ pad: "large", justify: "between" }, { children: [_jsxs(Box, Object.assign({ direction: "row", gap: "small" }, { children: [_jsx(Button, { primary: true, icon: _jsx(Save, {}), label: t("save"), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        yield vehicle.save()
                                            .then(() => toast.success(t("saved_vehicle")))
                                            .catch(() => toast.error(t("could_not_save_vehicle")));
                                        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveVehicle(null);
                                    }), disabled: !vehicle || !vehicle.validate }), _jsx(Button, { hoverIndicator: true, icon: _jsx(Trash, {}), disabled: vehicle.id === 0, tip: t("remove_vehicle"), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        yield vehicle.remove()
                                            .then(() => toast.success(t("deleted_vehicle")))
                                            .catch(() => toast.error(t("could_not_delete_vehicle")));
                                        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveVehicle(null);
                                    }) })] })), _jsxs(Box, { children: [_jsxs(Text, Object.assign({ style: { textAlign: "right" }, size: "small" }, { children: [t("created_at"), " ", format(vehicle.created_at || new Date(), "yyyy-LL-dd HH:mm")] })), _jsxs(Text, Object.assign({ style: { textAlign: "right" }, size: "small" }, { children: [t("last_edited"), " ", format(vehicle.updated_at || vehicle.created_at || new Date(), "yyyy-LL-dd HH:mm")] }))] })] }))] })) })));
}));
