import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from 'grommet';
import { format } from 'date-fns';
import { ImageComment } from './imageComment';
import { FileComment } from '.';
import { SystemComment } from './systemComment';
import { useTranslation } from 'react-i18next';
import { Menu } from './menu';
import { inject, observer } from 'mobx-react';
import { MailReply01Icon } from 'hugeicons-react';
export const CommentBase = inject('dataStore', 'userStore')(observer((props) => {
    var _a, _b, _c, _d, _e;
    const { t, i18n } = useTranslation();
    const { comment, left, dataStore, userStore } = props;
    const round = left ? "0px 6px 6px 6px" : "6px 6px 0px 6px";
    const renderContent = () => {
        var _a;
        if (comment.file && ((_a = comment.file.type) === null || _a === void 0 ? void 0 : _a.startsWith('image')))
            return _jsx(ImageComment, { image: comment.file });
        else if (comment.file)
            return _jsx(FileComment, { file: comment.file });
        return null;
    };
    if (comment.system)
        return _jsx(SystemComment, { comment: comment });
    return (_jsxs(Box, Object.assign({ direction: "row", flex: false, alignSelf: left ? undefined : "end" }, { children: [left &&
                _jsx(Box, { className: "comment-container-left", width: "10px", height: "10px", background: "brand", round: "4px" }, "comment-container-left"), _jsx(Menu, Object.assign({ items: [
                    { label: t("reply"), onClick: () => {
                            var _a;
                            dataStore === null || dataStore === void 0 ? void 0 : dataStore.setReplyComment(comment);
                            (_a = document.getElementById('comment-input')) === null || _a === void 0 ? void 0 : _a.focus();
                        } },
                    { label: t("delete"), onClick: () => {
                            comment.remove();
                        } },
                ] }, { children: _jsxs(Box, Object.assign({ className: "comment-container", round: round, background: "light-1", margin: { horizontal: 'xsmall' }, pad: {
                        vertical: "xsmall",
                        horizontal: "small"
                    }, border: {
                        side: "all",
                        size: "xsmall"
                    }, width: {
                        max: "350px"
                    } }, { children: [Boolean(comment.reply_comment) &&
                            _jsxs(Box, Object.assign({ className: "comment-reply", align: "center", background: "light-4", direction: "row", gap: "xsmall", pad: {
                                    left: "xsmall",
                                    right: "small",
                                    top: "xsmall",
                                    bottom: "xsmall"
                                }, round: "4px", border: {
                                    side: "left",
                                    color: "brand",
                                    size: "medium"
                                }, margin: { bottom: "xsmall" } }, { children: [_jsx(MailReply01Icon, { size: 20, stroke: "#053162" }), _jsxs(Box, { children: [_jsx(Text, Object.assign({ weight: "bold", size: "xsmall" }, { children: format((_b = (_a = comment.reply_comment) === null || _a === void 0 ? void 0 : _a.created_at) !== null && _b !== void 0 ? _b : new Date(), "yyyy-LL-dd HH:mm") })), ((_c = comment.reply_comment) === null || _c === void 0 ? void 0 : _c.text) && (_jsx(Text, Object.assign({ size: "xsmall", truncate: true }, { children: comment.reply_comment.text })))] })] })), _jsxs(Box, Object.assign({ className: "comment-body" }, { children: [renderContent(), _jsx("pre", Object.assign({ className: "comment-textarea" }, { children: comment.text }))] }), "comment-body"), _jsx(Box, Object.assign({ className: "comment-footer", alignSelf: "end" }, { children: _jsxs(Text, Object.assign({ size: "8pt" }, { children: [_jsx("strong", { children: (_e = userStore === null || userStore === void 0 ? void 0 : userStore.getUser((_d = comment.created_by) !== null && _d !== void 0 ? _d : 0)) === null || _e === void 0 ? void 0 : _e.displayName }), ":", comment.created_at
                                        ? format(comment.created_at, "yyyy-LL-dd HH:mm")
                                        : 'Date unknown'] })) }), "comment-footer")] }), comment.id) }), "comment-menu"), !left &&
                _jsx(Box, { className: "comment-container-right", width: "10px", height: "10px", background: "brand", round: "4px", alignSelf: "end" }, "comment-container-right")] }), "comment"));
}));
