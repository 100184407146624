import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from "grommet";
import { useTranslation } from "react-i18next";
import { SettingsOption } from "./option";
export const Section = (props) => {
    const { t, i18n } = useTranslation();
    const { title, options, subtitle, avatar } = props;
    return (_jsxs(Box, Object.assign({ className: "settings-section-container", background: "light-1", elevation: "small", pad: { horizontal: "medium", bottom: "medium", top: "small" }, round: "6px", width: {
            min: "xlarge"
        } }, { children: [_jsxs(Box, Object.assign({ justify: "between", direction: "row", className: "section-title", pad: "xsmall", border: {
                    side: "bottom",
                    size: "small"
                }, margin: {
                    bottom: "small"
                }, align: "baseline" }, { children: [_jsx(Text, Object.assign({ size: "xlarge" }, { children: title })), _jsx(Text, Object.assign({ size: "small" }, { children: subtitle })), avatar] })), _jsx(Box, Object.assign({ className: "section-options", gap: "small" }, { children: options.map(o => _createElement(SettingsOption, Object.assign({}, o, { key: "section-option-" + o.text }))) }))] }), "section-container-" + title));
};
