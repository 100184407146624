var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import client from "../feathers";
import { makeObservable, observable, action, computed, runInAction } from "mobx";
import { computedFn } from "mobx-utils";
export class Data {
    constructor(type, data) {
        this.id = undefined;
        this.created_at = undefined;
        this.created_by = undefined;
        this.updated_at = undefined;
        this.updated_by = undefined;
        this.validateProp = computedFn(function (prop) {
            return true;
        });
        makeObservable(this, {
            created_at: observable,
            created_by: observable,
            id: observable,
            updated_at: observable,
            updated_by: observable,
            save: action,
            remove: action,
            validate: computed,
        });
        this.service = client.service(type);
        if (!data)
            return;
        this.id = data.id && Number(data.id);
        this.created_at = data.created_at && new Date(data.created_at);
        this.updated_at = data.updated_at && new Date(data.updated_at);
        this.updated_by = Number(data.updated_by);
        this.created_by = Number(data.created_by);
    }
    static create(data) {
        return {};
    }
    ;
    fromData(data) {
        this.id = data.id && Number(data.id);
        this.created_at = data.created_at && new Date(data.created_at);
        this.updated_at = data.updated_at && new Date(data.updated_at);
        this.updated_by = Number(data.updated_by);
        this.created_by = Number(data.created_by);
    }
    toData() {
        return {
            id: this.id ? Number(this.id) : undefined,
            created_at: this.created_at && this.created_at.toISOString(),
            updated_at: this.updated_at && this.updated_at.toISOString(),
            created_by: this.created_by,
            updated_by: this.updated_by
        };
    }
    save() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.id) {
                    console.log(this.toData());
                    yield this.service.update(this.id, this.toData());
                }
                else {
                    console.log(this.toData());
                    const newData = yield this.service.create(Object.assign(Object.assign({}, this.toData()), { id: undefined }));
                    runInAction(() => this.fromData(newData));
                }
            }
            catch (error) {
                console.error("Failed to save object", this, error);
                throw Error("Error to trigger calling catch block");
            }
        });
    }
    remove() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.id)
                    yield this.service.remove(this.id);
            }
            catch (error) {
                console.error(error);
                throw Error("Error to trigger calling catch block");
            }
        });
    }
    get validate() {
        return true;
    }
}
Data.defaultValues = {
    id: undefined,
    created_at: new Date(),
    updated_at: new Date(),
    created_by: undefined,
    updated_by: undefined,
};
