var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { AppRoute } from "../routes";
import { Grid, Box, ResponsiveContext, Grommet, grommet, } from "grommet";
import { deepMerge } from "grommet/utils";
import LoginImage from "../assets/images/login-hero-min.jpg";
import LoginView from "../components/authentication/login";
import SignUpView from "../components/authentication/signup";
import ForgotPassword from "../components/authentication/forgotPassword";
import ResetPassword from "../components/authentication/resetPassword";
import AcceptInvite from "../components/authentication/acceptInvite";
import { useNavigate } from 'react-router-dom';
const localTheme = deepMerge(grommet, {
    global: {
        colors: {
            focus: "neutral-3",
        },
        font: {
            family: "DM Sans",
        },
        breakpoints: {
            small: {
                value: 900,
            },
            medium: {
                value: 1500,
            },
            large: {
                value: 3000,
            },
        },
    },
});
const ResponsiveGrid = (_a) => {
    var { children, areas } = _a, props = __rest(_a, ["children", "areas"]);
    const size = React.useContext(ResponsiveContext);
    return (_jsx(Grid, Object.assign({ areas: areas[size] }, props, { children: children })));
};
const Image = styled.img `
  object-fit: cover;
  overflow:   hidden;
  width:      100%;
  height:     100%;
  background: none;
  min-height: 100vh;
`;
export const Auth = inject("userStore")(observer((props) => {
    const { userStore } = props;
    const responsive = React.useContext(ResponsiveContext);
    const navigate = useNavigate();
    if (userStore === null || userStore === void 0 ? void 0 : userStore.isLoggedIn)
        navigate(AppRoute.Calendar);
    function getView(pathname) {
        switch (pathname) {
            case '/auth/login':
                return _jsx(LoginView, {});
            case '/auth/signup':
                return _jsx(SignUpView, {});
            case '/auth/forgot-password':
                return _jsx(ForgotPassword, {});
            case '/auth/reset-password/:token':
                return _jsx(ResetPassword, {});
            case '/auth/invite/:token':
                return _jsx(AcceptInvite, {});
            default:
                return _jsx(LoginView, {});
        }
    }
    return (_jsx(Grommet, Object.assign({ theme: localTheme }, { children: _jsx(ResponsiveContext.Consumer, { children: (size) => (_jsxs(ResponsiveGrid, Object.assign({ rows: ["image", "loginForm"], columns: ["1/2", "1/2"], gap: "none", areas: {
                    small: [
                        { name: "image", start: [0, 0], end: [0, 0] },
                        { name: "loginForm", start: [0, 0], end: [1, 1] },
                    ],
                    medium: [
                        { name: "image", start: [0, 0], end: [0, 0] },
                        { name: "loginForm", start: [1, 0], end: [1, 0] },
                    ],
                    large: [
                        { name: "image", start: [0, 0], end: [0, 0] },
                        { name: "loginForm", start: [1, 0], end: [1, 0] },
                    ],
                } }, { children: [_jsx(Box, Object.assign({ gridArea: "image", background: "light-3", fill: "vertical" }, { children: _jsx(Image, { src: LoginImage }) })), _jsx(Box, Object.assign({ margin: { top: '2rem', vertical: responsive === "small" ? "auto" : undefined }, gridArea: "loginForm", pad: 'large', alignContent: 'center' }, { children: _jsx(Box, Object.assign({ width: "340px", alignSelf: 'center', fill: 'vertical' }, { children: getView(location.pathname) })) }))] }))) }) })));
}));
