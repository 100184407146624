import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Box } from "grommet";
import { FormComponent } from "../components/formComponents/formComponent";
import { DataType, TourProps } from "../types";
import { WeekCalendar } from "../components/scheduler/weekCalendar";
import { TourList } from "../components/scheduler/tourList";
import { ResourceList } from "../components/scheduler/resourceList";
import { Teams } from "../components/scheduler/teams";
export const Scheduler = inject('userStore', 'dataStore', 'teamStore')(observer((props) => {
    return (_jsx(DndProvider, Object.assign({ backend: HTML5Backend }, { children: _jsx(_Scheduler, Object.assign({}, props)) })));
}));
const _Scheduler = inject('userStore', 'dataStore', 'teamStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { dataStore, userStore, teamStore } = props;
    const [{ isOver }, drop] = useDrop(() => ({
        accept: [DataType.Link],
        drop: (data, monitor) => {
            if (monitor.isOver({ shallow: true })) {
                if (!data.item["data"] || !data.item["team"]) {
                    console.error("Incorrect drop item");
                    return;
                }
                teamStore === null || teamStore === void 0 ? void 0 : teamStore.unlink_data(data.item["type"], data.item["team"].id, data.item["data"].id);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }), [teamStore]);
    const selected_office = (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveOffice) || (userStore === null || userStore === void 0 ? void 0 : userStore.getDefaultOffice) || null;
    React.useEffect(() => {
        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveOffice(selected_office);
    }, [dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveOffice, userStore === null || userStore === void 0 ? void 0 : userStore.getDefaultOffice]);
    return (_jsxs(Box, Object.assign({ fill: true, direction: "row", pad: "medium", gap: "medium", background: '#f3f2ef', overflow: "auto", ref: drop }, { children: [_jsxs(Box, Object.assign({ className: "scheduler-left", fill: true, gap: "medium" }, { children: [_jsxs(Box, Object.assign({ className: "scheduler-top", direction: "row", gap: "medium", flex: false }, { children: [_jsxs(Box, Object.assign({ className: "scheduler-select", gap: "medium" }, { children: [_jsx(FormComponent, { type: DataType.Office, inputType: "select", prop: TourProps.Office_Id, label: t("office"), containerProps: {
                                            fill: "horizontal",
                                            gap: "xsmall"
                                        }, labelProps: {
                                            size: "var(--FormComponent-font-lg)",
                                            weight: "bold"
                                        }, inputCmpProps: {
                                            options: Object.values((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOffices) || {}),
                                            labelKey: "name",
                                            valueKey: "id",
                                            placeholder: t("select_office"),
                                            value: selected_office || undefined,
                                            onChange: (event) => {
                                                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(null);
                                                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveOffice(dataStore.getOfficeByID(event.option.id));
                                            },
                                        } }), _jsx(WeekCalendar, {})] })), _jsx(TourList, {})] })), _jsx(Box, Object.assign({ fill: true, className: "scheduler-teams", background: "white", round: "6px", border: "all", height: { max: "calc(100vh - 460px)" } }, { children: _jsx(Teams, {}) }))] })), _jsx(Box, Object.assign({ className: "scheduler-resources", fill: "vertical", width: "700px", background: "white", round: "6px", border: "all", height: { max: "calc(100vh - 120px)" } }, { children: _jsx(ResourceList, {}) }))] })));
}));
