import { css } from "styled-components";
export const theme = {
    global: {
        colors: {
            brand: "#053162",
            focus: "accent-1",
            "accent-1": "#0968D2",
            "accent-2": "#87bfff",
            "accent-3": "#add3ff",
            "accent-4": "#ebf4ff",
            "table-bg-1": "#7aace6",
            "table-bg-2": "#7ae2e6",
            "table-bg-3": "#7ae6b3",
            "status-critical": "#FF4040",
            "status-critical-light": "#FFE8E8",
            "status-ok-light": "#cee5dd",
            "status-critical-border": "#FFC0C0",
            "dark-1": "#000",
            text: {
                light: "#000",
                dark: "#fff",
            },
            border: "light-4",
            "post-it": "#FFFF99",
        },
        font: {
            family: "DM Sans",
            size: "16px",
            height: "1.5",
            face: "/* latin-ext */\n@font-face {\n  font-family: 'DM Sans';\n  font-style: normal;\n  font-weight: 400;\n url(https://fonts.gstatic.com/s/dmsans/v15/rP2Hp2ywxg089UriCZ2IHTWEBlwu8Q.woff2) format('woff2');\n  U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;\n}\n/* latin */\n@font-face {\n  font-family: 'DM Sans';\n  font-style: normal;\n  font-weight: 400;\n  src: url(https://fonts.gstatic.com/s/dmsans/v15/rP2Hp2ywxg089UriCZOIHTWEBlw.woff2) format('woff2');\n  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\n}\n",
        },
        focus: {
            border: {
                color: "focus",
            },
            shadow: {
                color: "focus",
                size: "1px",
            },
        },
    },
    anchor: {
        color: "",
    },
    button: {
        border: {
            radius: "6px",
        },
        padding: {
            vertical: "10px",
        },
        extend: css `
      font-weight: "400";
    `,
    },
    text: {
        medium: {
            size: "16px",
        },
    },
    textArea: {
        extend: css `
      font-weight: "400";
    `,
    },
    textInput: {
        extend: css `
      background-color: "#fff";
      font-weight: "400";
    `,
    },
    checkBox: {
        size: "16px",
        extend: css `
      font-size: "14px";
      line-height: "16px";
      margin-bottom: "6px";
    `,
        toggle: {
            extend: css `
        margin-right: "4px";
      `,
        },
    },
    tip: {
        content: {
            background: "light-1",
        },
    },
    accordion: {
        border: undefined,
    },
    tabs: {
        header: {
            border: {
                side: "bottom",
                color: "primary",
                size: "xsmall",
            },
        },
    },
    tab: {
        color: "light-4",
        active: {
            color: "brand",
        },
        hover: {
            color: "dark-4",
        },
        border: {
            side: "bottom",
            color: "light-4",
            active: {
                color: "brand",
            },
            hover: {
                color: "dark-4",
            },
        },
        pad: "small",
        margin: {
            vertical: "-2px",
            horizontal: "none",
        },
    },
    menu: {
        extend: css `
      outline: "none";
    `,
    },
};
