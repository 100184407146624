import { Data, FileData } from './';
import { DataType } from './../types';
import { makeObservable, observable } from 'mobx';
export class Comment extends Data {
    constructor(data) {
        super(DataType.Comment, data);
        this.text = null;
        this.system = null;
        this.reply_id = null;
        this.location = null;
        this.file_id = null;
        this.file = null;
        this.item_id = null;
        this.item_type = 'tour';
        this.reply_comment = null;
        makeObservable(this, {
            text: observable,
            system: observable,
            reply_id: observable,
            location: observable,
            file_id: observable,
            item_id: observable,
        });
        if (data)
            this.fromData(data);
    }
    static create(data) {
        return new Comment(data);
    }
    fromData(data) {
        super.fromData(data);
        this.item_id = Number(data.item_id);
        this.item_type = data.item_type;
        this.text = data.text;
        this.system = data.system;
        this.reply_id = data.reply_id && Number(data.reply_id);
        this.location = data.location;
        this.file_id = data.file_id;
        this.reply_comment = data.reply_comment && Comment.create(data.reply_comment);
        this.file = data.file && FileData.create(data.file);
    }
    toData() {
        return Object.assign(Object.assign({}, super.toData()), { text: this.text, system: this.system, reply_id: this.reply_id, location: this.location, file_id: this.file_id, item_id: this.item_id, item_type: this.item_type, updated_by: undefined, updated_at: undefined });
    }
}
