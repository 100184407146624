import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from "mobx-react";
import { Box } from "grommet";
import { Tour, Note } from "../../models";
import { TourCard } from "./tourCard";
import { NoteCard } from "./noteCard";
import { useDrop } from "react-dnd";
import { DataType } from "../../types";
import { Clone, Pan } from "grommet-icons";
export const DataCell = inject('dataStore', 'userStore')(observer((props) => {
    var _a;
    const { tours, notes, elKey, office_id, date, dataStore, userStore, noDnD } = props;
    const [isOverContainer, drop] = noDnD ? [undefined, undefined] : useDrop(() => ({
        accept: [DataType.Note, DataType.Tour],
        drop: (data) => {
        },
        collect: (monitor) => ({ isOver: !!monitor.isOver() && !isSame(monitor) }),
    }));
    const [isOverClone, dropClone] = noDnD ? [undefined, undefined] : useDrop(() => ({
        accept: [DataType.Note, DataType.Tour],
        drop: (data) => { data.item.clone(office_id !== null && office_id !== void 0 ? office_id : 0, date); },
        collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }), [date]);
    const [isOverMove, dropMove] = noDnD ? [undefined, undefined] : useDrop(() => ({
        accept: [DataType.Note, DataType.Tour],
        drop: (data) => { data.item.move(office_id !== null && office_id !== void 0 ? office_id : 0, date); },
        collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }), [date]);
    const isSame = (monitor) => {
        if ((monitor === null || monitor === void 0 ? void 0 : monitor.getItem().key) === elKey)
            return true;
        const draggedId = (monitor === null || monitor === void 0 ? void 0 : monitor.getItem()) && monitor.getItem().item && monitor.getItem().item.id;
        if (!draggedId)
            return true;
        if ((monitor.getItemType() === DataType.Tour && !Boolean(tours)) ||
            (monitor.getItemType() === DataType.Note && !Boolean(notes)))
            return true;
        return false;
    };
    const renderTour = (tour) => {
        return _jsx(TourCard, { noDnD: noDnD, tour: tour, elKey: elKey }, "tour" + elKey + tour.id);
    };
    const renderNote = (note) => {
        return _jsx(NoteCard, { noDnD: noDnD, note: note, elKey: elKey }, "note" + elKey + note.id);
    };
    const render = (notes ? renderNote : renderTour);
    const data = ((_a = notes !== null && notes !== void 0 ? notes : tours) !== null && _a !== void 0 ? _a : []);
    return (_jsxs(Box, Object.assign({ className: "calendar-cell", ref: drop, onClick: () => {
            if (office_id) {
                const newTour = Tour.create({
                    date: date,
                    office_id: office_id,
                });
                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(newTour);
            }
            else {
                const newNote = Note.create({
                    from: date,
                    to: date,
                    office_id: userStore === null || userStore === void 0 ? void 0 : userStore.getDefaultOfficeId,
                });
                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveNote(newNote);
            }
        } }, { children: [_jsx(Box, Object.assign({ pad: "xsmall", fill: true }, { children: data && (data.map(d => render(d))) })), (isOverContainer === null || isOverContainer === void 0 ? void 0 : isOverContainer.isOver) &&
                _jsxs(Box, Object.assign({ className: "calendar-cell-hover-backdrop", direction: "row", fill: true }, { children: [_jsx(Box, Object.assign({ fill: true, className: "calendar-cell-hover-backdrop-zone", background: (isOverClone === null || isOverClone === void 0 ? void 0 : isOverClone.isOver) ? "rgba(200, 200, 200, .4)" : "rgba(0, 0, 0, .4)", justify: "center", align: "center", ref: dropClone, border: {
                                color: "white",
                                side: "right",
                                size: "small"
                            } }, { children: _jsx(Clone, { color: "white" }) })), _jsx(Box, Object.assign({ fill: true, className: "calendar-cell-hover-backdrop-zone", background: (isOverMove === null || isOverMove === void 0 ? void 0 : isOverMove.isOver) ? "rgba(200, 200, 200, .4)" : "rgba(0, 0, 0, .4)", justify: "center", align: "center", ref: dropMove }, { children: _jsx(Pan, { color: "white" }) }))] }))] }), "cellcontainer" + elKey));
}));
