import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, List, Text, DropButton } from "grommet";
import { DeprecatedTourLabels, TourLabel, TourProps, DataType } from "../../types";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TourIcon } from "../calendar/tourIcon";
export const LabelSelector = inject("dataStore", "userStore")(observer((props) => {
    var _a, _b;
    const { t } = useTranslation();
    const { dataStore, userStore } = props;
    const label = (_b = (_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : TourLabel.None;
    const isLabel = label && label !== TourLabel.None;
    const [selectorOpen, setSelectorOpen] = React.useState(false);
    return (_jsxs(Box, Object.assign({ style: { width: "200px", padding: 2.5 } }, { children: [_jsx(Text, Object.assign({ weight: "bold", margin: { bottom: "xsmall" } }, { children: t("label") })), _jsx(DropButton, Object.assign({ disabled: !(userStore === null || userStore === void 0 ? void 0 : userStore.isAdminOrSales), open: selectorOpen, onClose: () => setSelectorOpen(false), onOpen: () => setSelectorOpen(true), style: { fontWeight: 400 }, dropContent: _jsx(List, Object.assign({ data: Object.values(TourLabel).filter((labelValue) => !["Art", "Inspection"].includes(labelValue)), onClickItem: ({ item }) => {
                        const newLabel = DeprecatedTourLabels.includes(item)
                            ? TourLabel.None
                            : item;
                        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveProp(DataType.Tour, TourProps.Label, newLabel === TourLabel.None ? undefined : newLabel);
                        setSelectorOpen(false);
                    } }, { children: (item) => (_jsxs(Box, Object.assign({ direction: "row", align: "center" }, { children: [item !== TourLabel.None && (_jsx(TourIcon, { size: "large", type: item })), _jsx(Text, Object.assign({ margin: {
                                    left: item !== TourLabel.None ? "small" : "none",
                                } }, { children: t(item) }))] }))) })) }, { children: _jsxs(Box, Object.assign({ direction: "row", align: "center" }, { children: [isLabel && _jsx(TourIcon, { size: "large", type: label }), _jsx(Text, Object.assign({ margin: { left: isLabel ? "small" : "none" } }, { children: isLabel ? t(label) : t("select_label") }))] })) }))] })));
}));
