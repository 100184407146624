import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Meter } from "grommet";
import { observer } from "mobx-react";
export const ProgressBar = observer((props) => {
    const { file } = props;
    React.useEffect(() => {
        const progressBar = document.getElementById("progress-bar");
        if (progressBar) {
            progressBar.focus();
        }
    }, []);
    return (_jsx("div", Object.assign({ className: "progress-bar", id: "progress-bar" }, { children: _jsx(Meter, { type: "circle", size: "xsmall", values: [{
                    value: file.getProgress,
                    color: "brand"
                }] }) })));
});
