import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
export const SystemComment = (props) => {
    const { t, i18n } = useTranslation();
    const { comment } = props;
    const system_messages = () => {
        switch (comment.text) {
            case "created":
                if (!comment.created_at)
                    return "";
            default: return '';
        }
    };
    return (_jsx(Box, Object.assign({ fill: "horizontal", alignContent: "center", justify: "center", className: "comment-system", margin: { vertical: "small" } }, { children: _jsx(Text, Object.assign({ size: "small" }, { children: system_messages() })) })));
};
