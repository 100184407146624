var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Data } from './';
import { DataType, UserRole, Language, UserStatus, ResourceStatus } from './../types';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { rootStore } from '../app';
import { isSameDay, formatISO } from 'date-fns';
export class User extends Data {
    constructor(data) {
        super(DataType.User, data);
        this.email = null;
        this.alias = null;
        this.first_name = null;
        this.last_login = null;
        this.last_name = null;
        this.role = UserRole.Default;
        this.password = null;
        this.language = null;
        this.office_links = [];
        this.default_office = null;
        this.employment_type = null;
        this.schedulerStatus = null;
        this.phone = null;
        this.license = null;
        this.status = null;
        this.filter = {};
        this.temp_date = null;
        this.hidden = false;
        this.getFilter = (filterGroup, key) => {
            var _a;
            const groupFilter = this.filter && this.filter[filterGroup];
            if (typeof groupFilter === 'object' && groupFilter !== null) {
                return (_a = groupFilter[key]) !== null && _a !== void 0 ? _a : false;
            }
            return false;
        };
        this.setDefaultOffice = (office_id) => {
            this.default_office = office_id;
        };
        this.setOfficeLinks = (offices) => {
            this.office_links = offices ? offices.map(o => o.id) : [];
        };
        this.save_office_links = () => __awaiter(this, void 0, void 0, function* () {
            if (!this.id)
                return;
            const storeUser = rootStore.userStore.getUser(this.id);
            const office_ids = this.office_links || [];
            const container_ids = storeUser ? rootStore.userStore.getUser(this.id).office_links || [] : [];
            const created_ids = office_ids.filter(id => !container_ids.includes(id));
            const removed_ids = container_ids.filter(id => !office_ids.includes(id));
            for (const office_id of created_ids)
                yield rootStore.userStore.link_office(this.id, office_id);
            for (const office_id of removed_ids)
                yield rootStore.userStore.unlink_office(this.id, office_id);
        });
        makeObservable(this, {
            email: observable,
            alias: observable,
            first_name: observable,
            last_login: observable,
            last_name: observable,
            role: observable,
            password: observable,
            language: observable,
            license: observable,
            phone: observable,
            employment_type: observable,
            default_office: observable,
            status: observable,
            filter: observable,
            office_links: observable,
            hidden: observable,
            temp_date: observable,
            getLinkedOffices: computed,
            getSchedulerStatus: computed,
            fullName: computed,
            displayName: computed,
            toggleFilter: action,
            setDefaultOffice: action,
            setOfficeLinks: action,
        });
        this.fromData(data);
    }
    static create(data) {
        const defaultUser = Object.assign(Object.assign({}, Data.defaultValues), { email: null, alias: null, first_name: null, last_login: null, last_name: null, role: UserRole.Default, password: null, language: Language.English, schedulerStatus: null, office_links: [], default_office: null, employment_type: null, phone: null, license: null, status: null, filter: {}, temp_date: null, hidden: false });
        return new User(Object.assign(defaultUser, data));
    }
    save() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.id) {
                    yield this.save_office_links();
                    yield this.service.patch(this.id, this.toData());
                }
                else {
                    const res = yield this.service.create(this.toData());
                    if (!res["id"]) {
                        console.error("Failed to create user");
                        throw Error("Failed to create user");
                    }
                    runInAction(() => this.id = res["id"]);
                    yield this.save_office_links();
                }
            }
            catch (error) {
                rootStore.displaySystemMessage(1000, { text: "Failed to save object", type: "error" });
                console.error("Failed to save object", this, error);
                throw Error("Failed to save object");
            }
        });
    }
    fromData(data) {
        super.fromData(data);
        this.email = data.email;
        this.alias = data.alias;
        this.first_name = data.first_name;
        this.last_login = data.last_login && new Date(data.last_login);
        this.last_name = data.last_name;
        this.role = data.role || UserRole.Default;
        this.language = data.language;
        this.office_links = (data.office_links && data.office_links.map(l => Number(l))) || [];
        this.phone = data.phone;
        this.license = data.license;
        this.employment_type = data.employment_type;
        this.filter = data.filter;
        this.status = data.status;
        this.hidden = data.hidden;
        this.default_office = Number(data.default_office);
        this.temp_date = data.temp_date && new Date(data.temp_date);
    }
    toData() {
        return Object.assign(Object.assign({}, super.toData()), { email: this.email || null, alias: this.alias || null, first_name: this.first_name || null, last_login: (this.last_login && this.last_login.toDateString()) || null, last_name: this.last_name || null, role: this.role || null, language: this.language || null, license: this.license || null, phone: this.phone || null, employment_type: this.employment_type || null, default_office: this.default_office || null, filter: this.filter, status: this.status, temp_date: this.temp_date && formatISO(this.temp_date, { representation: "date" }), created_by: undefined, updated_by: undefined, hidden: this.hidden });
    }
    get fullName() {
        return [this.first_name, this.last_name].filter(Boolean).join(" ");
    }
    get displayName() {
        return this.alias || this.fullName;
    }
    get getDefaultOffice() {
        return this.default_office;
    }
    get displayAlias() {
        return this.alias || this.first_name || this.last_name || null;
    }
    compare(user) {
        return (this.employment_type === user.employment_type &&
            this.email === user.email &&
            this.first_name === user.first_name &&
            this.last_name === user.last_name &&
            this.phone === user.phone &&
            this.alias === user.alias &&
            this.license === user.license &&
            this.getDefaultOffice === user.getDefaultOffice &&
            this.hidden === user.hidden);
    }
    get getSchedulerStatus() {
        if (!rootStore.dataStore.getCurDate) {
            return ResourceStatus.Free;
        }
        if (this.hasNote)
            return ResourceStatus.Note;
        const numTeams = rootStore.teamStore.getTeamsByUser(this.id).filter(t => t.date && isSameDay(t.date, rootStore.dataStore.getCurDate)).length;
        return !numTeams ? ResourceStatus.Free : (numTeams === 1 ? ResourceStatus.Assigned : ResourceStatus.Conflict);
    }
    get getLinkedOffices() {
        if (this.role === UserRole.Temporary && this.getDefaultOffice)
            return [rootStore.dataStore.getOfficesUnfiltered[this.getDefaultOffice]];
        return this.office_links ? this.office_links.map(id => rootStore.dataStore.getOfficesUnfiltered[id]) : [];
    }
    get hasNote() {
        return Object.values(rootStore.dataStore.notes)
            .some(n => (n.isCurDay &&
            this.id &&
            n.user_links.includes(this.id)));
    }
    get getNotes() {
        return Object.values(rootStore.dataStore.notes)
            .filter(n => (n.isCurDay &&
            this.id &&
            n.user_links.includes(this.id)));
    }
    toggleFilter(filterGroup, key) {
        this.filter = this.filter || {};
        this.filter[filterGroup] = this.filter[filterGroup] || {};
        this.filter[filterGroup][key] = !Boolean(this.filter[filterGroup][key]);
        this.save();
    }
}
User.createTemp = (office_id) => {
    return new User({
        email: "",
        role: UserRole.Temporary,
        filter: {},
        temp_date: rootStore.dataStore.getCurDate,
        default_office: office_id,
        hidden: false,
    });
};
User.compare = (lhs, rhs) => {
    const lhsState = lhs.getSchedulerStatus;
    const rhsState = rhs.getSchedulerStatus;
    if (lhsState === rhsState) {
        if (lhs.license === rhs.license) {
            return lhs.displayName < rhs.displayName ? -1 : (lhs.displayName > rhs.displayName ? 1 : 0);
        }
        if (lhs.license === null && rhs.license !== null)
            return -1;
        if (lhs.license !== null && rhs.license === null)
            return 1;
        return lhs.license < rhs.license ? -1 : 1;
    }
    else {
        return lhsState > rhsState ? -1 : (lhsState < rhsState ? 1 : 0);
    }
};
User.compareForResources = (lhs, rhs) => {
    if (lhs.status !== rhs.status)
        return (lhs.status === UserStatus.Active) ? -1 : 1;
    if (lhs.role !== rhs.role) {
        return lhs.role === UserRole.Admin ? -1 : (lhs.role === UserRole.Default ? 1 : (rhs.role === UserRole.Admin ? 1 : -1));
    }
    if (lhs.license !== rhs.license) {
        if (lhs.license === null)
            return -1;
        if (rhs.license === null)
            return 1;
        return lhs.license < rhs.license ? -1 : 1;
    }
    return lhs.fullName < rhs.fullName ? -1 : 1;
};
