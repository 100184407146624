import { jsx as _jsx } from "react/jsx-runtime";
import ClipLoader from "react-spinners/ClipLoader";
import { Box } from 'grommet';
export const LoadingView = () => (_jsx(Box, Object.assign({ style: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'full',
        height: 'full',
    } }, { children: _jsx(ClipLoader, { color: '#053162', loading: true, size: 120 }) })));
