var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box, Text } from "grommet";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Tour } from '@/models';
import { TourCard } from './tourCard';
import { PaginationFooter } from '@/components/pagination/paginationFooter';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { DataPage } from '@/models/dataPage';
import client from '@/feathers';
import { FilterGroup, TourLabel } from '@/types';
import { LoadingView } from '@/views';
import { ArrowDown02Icon, ArrowUp02Icon, ArrowUpDownIcon } from 'hugeicons-react';
export const SearchView = inject("userStore", "dataStore")(observer((props) => {
    const { searchString, dataStore } = props;
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = React.useState(0);
    const filteredOffices = Object.keys((dataStore === null || dataStore === void 0 ? void 0 : dataStore.offices) || {}).filter(o => o && (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getFilter(FilterGroup.Office, o))).map(o => Number(o));
    const filteredLabels = Object.values(TourLabel).filter(l => l && (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getFilter(FilterGroup.Label, l)));
    const getBaseParams = (pageSize) => ({
        $limit: pageSize,
        $skip: currentPage * pageSize,
        $and: [
            {
                $or: [].concat(...searchString.split(',').map(s => {
                    const byProp = [
                        { title: { $ilike: '%' + s + '%' } },
                        { customer: { $ilike: '%' + s + '%' } },
                    ];
                    if (Boolean(Number(s)))
                        byProp.push({ lime_id: Number(s) });
                    return byProp;
                }))
            },
            { office_id: { $in: filteredOffices } },
            {
                $or: [
                    { label: { $in: filteredLabels } },
                    { label: null },
                ]
            },
        ]
    });
    const [sorting, setSorting] = React.useState([]);
    const [query, setQuery] = React.useState(getBaseParams);
    const [page] = React.useState(() => new DataPage(loadTourPage, Tour.create, [], 0, 0, query, true, 20));
    React.useEffect(() => {
        if (page) {
            const updatedQuery = Object.assign(Object.assign({}, getBaseParams(page.page_size)), { $skip: currentPage * page.page_size });
            setQuery(updatedQuery);
        }
    }, [filteredOffices === null || filteredOffices === void 0 ? void 0 : filteredOffices.length, filteredLabels === null || filteredLabels === void 0 ? void 0 : filteredLabels.length, currentPage, searchString]);
    React.useEffect(() => {
        const sortObj = sorting.reduce((acc, sort) => {
            acc[sort.id] = sort.desc ? -1 : 1;
            return acc;
        }, {});
        setQuery(prev => (Object.assign(Object.assign({}, prev), { $sort: Object.assign({}, sortObj) })));
    }, [sorting]);
    React.useEffect(() => {
        if (query) {
            page.setQuery(query, true);
            page.loadPage(currentPage);
        }
    }, [query]);
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    const tours = page.data;
    const columns = React.useMemo(() => [
        {
            accessorKey: 'date',
            header: t('date'),
            cell: ({ row }) => row.original.date ? new Date(row.original.date).toLocaleDateString() : t('No Date'),
            enableSorting: true,
        },
        {
            accessorKey: 'tour',
            header: t('tour'),
            cell: ({ row }) => _jsx(TourCard, { tour: row.original, elKey: row.id, dataStore: dataStore, noDnD: true }),
            enableSorting: false,
        },
    ], [t]);
    const table = useReactTable({
        data: tours || [],
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        manualSorting: true,
    });
    const start = page.page * page.page_size + 1;
    const end = Math.min((page.page + 1) * page.page_size, page.total);
    if (page.isLoading)
        return _jsx(LoadingView, {});
    return (_jsx(Box, Object.assign({ direction: "column", gap: "small" }, { children: tours.length > 0 ? (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ margin: { vertical: 'small' } }, { children: _jsx(Text, { children: `Showing ${start}-${end} of ${page.total}` }) })), _jsx("div", Object.assign({ className: "table-wrapper" }, { children: _jsxs("table", Object.assign({ className: 'table', style: { width: '100%', fontSize: 'inherit' } }, { children: [_jsx("thead", Object.assign({ className: 'table-head' }, { children: table.getHeaderGroups().map((headerGroup) => (_jsx("tr", { children: headerGroup.headers.map((header) => {
                                        var _a;
                                        return (_jsxs("th", Object.assign({ onClick: header.column.getToggleSortingHandler(), style: {
                                                cursor: header.column.id === 'date' ? 'pointer' : 'default',
                                                userSelect: 'none',
                                                textAlign: 'left',
                                                padding: '8px',
                                            } }, { children: [flexRender(header.column.columnDef.header, header.getContext()), header.column.id === 'date' && ((_a = {
                                                    asc: _jsx(ArrowUp02Icon, { height: 16, width: 16, style: { marginLeft: '8px' } }),
                                                    desc: _jsx(ArrowDown02Icon, { height: 16, width: 16, style: { marginLeft: '8px' } }),
                                                }[header.column.getIsSorted()]) !== null && _a !== void 0 ? _a : _jsx(ArrowUpDownIcon, { height: 16, width: 16, style: { marginLeft: '8px' } }))] }), header.id));
                                    }) }, headerGroup.id))) })), _jsx("tbody", Object.assign({ className: 'table-body' }, { children: table.getRowModel().rows.map(row => (_jsx("tr", Object.assign({ className: 'table-row' }, { children: row.getVisibleCells().map(cell => (_jsx("td", Object.assign({ style: {
                                            textAlign: 'left',
                                            padding: '8px',
                                        } }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id))) }), row.id))) }))] })) })), _jsx(PaginationFooter, { page: page, totalPages: Math.ceil(page.total / page.page_size), onChangePage: handlePageChange, selectPageSize: [10, 20, 50, 100], ellipsisStartBlockSize: 4 })] })) : (_jsx(Text, Object.assign({ textAlign: "center", margin: { vertical: 'medium' } }, { children: t("no_tours_found") }))) })));
}));
const loadTourPage = (page, page_size, query) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const limit = page_size;
        return yield client.service('tour').find({
            query: Object.assign(Object.assign({}, query), { $limit: limit, $skip: page * limit }),
        });
    }
    catch (error) {
        console.error("Failed to load tours search page", error);
        return null;
    }
});
