var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from 'grommet';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Comment, FileData } from '../../models';
import { DataType } from '../../types';
import { FilePreview } from './filePreview';
import { ProgressBar } from './ProgessBar';
import { ReplyPreview } from './replyPreview';
import { Attachment01Icon, MailSend01Icon } from "hugeicons-react";
export const CommentInput = inject('dataStore')(observer((props) => {
    var _a;
    const { t, i18n } = useTranslation();
    const { dataStore } = props;
    const { acceptedFiles, getRootProps, getInputProps, isDragActive, open } = useDropzone({
        maxFiles: 1,
        noClick: true,
        noKeyboard: true
    });
    const inputRef = React.useRef(null);
    const dragArea = React.useRef(null);
    const [text, setText] = React.useState("");
    const fileData = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCommentFile;
    React.useEffect(() => {
        if (acceptedFiles.length) {
            const fileData = FileData.create({
                name: acceptedFiles[0].name,
                size: acceptedFiles[0].size,
                type: acceptedFiles[0].type,
                parent_id: dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour.id,
                parent_type: DataType.Tour,
                blob: acceptedFiles[0],
            });
            dataStore === null || dataStore === void 0 ? void 0 : dataStore.setCommentFile(fileData);
        }
    }, [acceptedFiles]);
    React.useEffect(() => {
        if (!dragArea.current)
            return;
        if (isDragActive)
            dragArea.current.style.backgroundColor = "rgba(50, 50, 50, 0.3)";
        else
            dragArea.current.style.backgroundColor = "rgba(50, 50, 50, 0)";
    }, [isDragActive]);
    const handleEnterKey = (event) => __awaiter(void 0, void 0, void 0, function* () {
        if (event.keyCode === 13) {
            event.preventDefault();
            if ((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCommentFile) && dataStore.getCommentFile.isUploading)
                return;
            if (event.shiftKey && inputRef.current) {
                inputRef.current.value += "\n";
            }
            else
                submit();
        }
    });
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d;
        if (text !== "" || fileData) {
            try {
                if (fileData) {
                    yield fileData.save();
                    if (!(fileData.id && fileData.key)) {
                        console.error("Failed to upload file and create database object. Please try again.");
                        return;
                    }
                }
                yield Comment.create({
                    text,
                    item_id: dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeTour.id,
                    item_type: 'tour',
                    system: false,
                    reply_id: (_c = (_b = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getReplyComment) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : null,
                    reply_comment: (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getReplyComment) || null,
                    file_id: (_d = fileData === null || fileData === void 0 ? void 0 : fileData.id) !== null && _d !== void 0 ? _d : null,
                    file: fileData !== null && fileData !== void 0 ? fileData : null,
                }).save();
                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setReplyComment(null);
                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setCommentFile(null);
                setText("");
            }
            catch (err) {
                console.error(err);
            }
        }
    });
    return (_jsxs("div", Object.assign({ className: "comment-input-container" }, getRootProps(), { children: [(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCommentFile) && dataStore.getCommentFile.isUploading &&
                _jsx(ProgressBar, { file: dataStore.getCommentFile }), _jsx(ReplyPreview, {}), _jsx(FilePreview, {}), _jsxs(Box, Object.assign({ direction: "row", fill: true, flex: false, style: { position: "relative", height: "fit-content" } }, { children: [isDragActive && _jsx("div", Object.assign({ ref: dragArea, className: "comment-input-drop-area" }, { children: t("Drop files here") })), _jsx("textarea", { autoFocus: true, id: "comment-input", ref: inputRef, value: text, className: "comment-input", placeholder: t("type_here"), rows: (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getReplyComment) ? 2 : 4, onChange: (event) => setText(event.target.value), onKeyDown: handleEnterKey, readOnly: ((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCommentFile) === null || _a === void 0 ? void 0 : _a.isUploading) || false }), _jsxs(Box, Object.assign({ className: "comment-input-button-area", justify: "center", pad: { horizontal: "small", vertical: "none" }, gap: "xsmall", flex: false }, { children: [_jsxs(Box, Object.assign({ className: "comment-input-add-button", onClick: open, round: "6px", width: "30px", height: "30px", justify: "center", align: "center", alignContent: "center", flex: false, hoverIndicator: true }, { children: [_jsx("input", Object.assign({}, getInputProps())), _jsx(Attachment01Icon, { size: 20, stroke: "#053162" })] })), _jsx(Box, Object.assign({ hoverIndicator: true, className: "comment-input-add-button", round: "6px", width: "30px", height: "30px", justify: "center", align: "center", alignContent: "center", flex: false, onClick: submit }, { children: _jsx(MailSend01Icon, { size: 20, color: text !== "" || fileData ? "#053162" : "#DCDCDC" }) }))] }))] }))] })));
}));
