var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeAutoObservable, runInAction, set, when, keys, remove, reaction } from "mobx";
import client from '../feathers';
import { UserRole } from '../types';
import i18next from '../i18n';
import { User, Data } from '../models';
import { startOfDay, endOfDay } from 'date-fns';
import { paramsForServer } from 'feathers-hooks-common';
export class UserStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        this.user_service = client.service('users');
        this.office_user_service = client.service('office-users');
        this.note_user_service = client.service('note-users');
        this.password_service = client.service('password_reset');
        this.invite_service = client.service('invite_user');
        this.user = null;
        this.active_user = null;
        this.email = null;
        this.is_admin = false;
        this.error_message = null;
        this.isAuthenticating = false;
        this.users = {};
        this.temp_users = {};
        this.setUserInContainer = (data, removed) => {
            if (removed)
                runInAction(() => remove(Boolean(data["temp_date"]) ? this.temp_users : this.users, "" + data.id));
            else
                runInAction(() => set(Boolean(data["temp_date"]) ? this.temp_users : this.users, "" + data.id, User.create(data)));
        };
        this.loadUsers = (queryOptions, container, page = 0) => __awaiter(this, void 0, void 0, function* () {
            const limit = 1000;
            const users = (yield this.user_service.find({
                query: Object.assign({ $limit: limit, $skip: page * limit }, queryOptions)
            }));
            runInAction(() => {
                if (!page)
                    keys(container).forEach((k) => remove(container, k));
                Object.assign(container, (users.data.reduce((res, val) => {
                    res[Number(val.id)] = User.create(val);
                    return res;
                }, {})));
            });
            if (page * limit < users.total)
                this.loadUsers(queryOptions, container, page + 1);
        });
        this.loadTempUsers = () => __awaiter(this, void 0, void 0, function* () {
            return this.loadUsers({
                role: UserRole.Temporary,
                temp_date: {
                    $gte: startOfDay(this.rootStore.dataStore.curDate),
                    $lt: endOfDay(this.rootStore.dataStore.curDate)
                }
            }, this.temp_users);
        });
        this.update_links = (data, method) => __awaiter(this, void 0, void 0, function* () {
            if (!data)
                return;
            const user = this.users[Number(data.user_id)];
            if (method === "removed") {
                runInAction(() => user.office_links = user.office_links.filter(l => l !== Number(data.office_id)));
            }
            else if (method === "created") {
                runInAction(() => {
                    if (!user.office_links.includes(data.office_id))
                        user.office_links.push(Number(data.office_id));
                });
            }
        });
        this.update_note_links = (data, method) => __awaiter(this, void 0, void 0, function* () {
            if (!data)
                return;
            const note = this.rootStore.dataStore.notes[Number(data.note_id)];
            if (method === "removed") {
                runInAction(() => note.user_links = note.user_links.filter(l => l !== Number(data.user_id)));
            }
            else if (method === "created") {
                runInAction(() => {
                    if (!note.user_links.includes(data.user_id))
                        note.user_links.push(Number(data.user_id));
                });
            }
        });
        this.link_office = (user_id, office_id) => __awaiter(this, void 0, void 0, function* () {
            try {
                this.office_user_service.create({
                    user_id,
                    office_id,
                });
            }
            catch (err) {
                console.error(err);
            }
        });
        this.link_note = (user_id, note_id) => __awaiter(this, void 0, void 0, function* () {
            try {
                this.note_user_service.create({
                    note_id,
                    user_id,
                });
            }
            catch (err) {
                console.error(err);
            }
        });
        this.unlink_office = (user_id, office_id) => __awaiter(this, void 0, void 0, function* () {
            try {
                const links = yield this.office_user_service.find({
                    query: {
                        user_id,
                        office_id,
                    }
                });
                for (const l of links.data)
                    yield this.office_user_service.remove(l.id);
            }
            catch (err) {
                console.error(err);
            }
        });
        this.unlink_note = (user_id, note_id) => __awaiter(this, void 0, void 0, function* () {
            try {
                const links = yield this.note_user_service.find({
                    query: {
                        note_id,
                        user_id,
                    }
                });
                for (const l of links.data)
                    yield this.note_user_service.remove(l.id);
            }
            catch (err) {
                console.error(err);
            }
        });
        this.getUser = (user_id) => {
            return (this.users && this.users[user_id]) || (this.temp_users && this.temp_users[user_id]);
        };
        this.setAuth = runInAction(() => (res) => {
            if ("user" in res) {
                this.user = User.create(res.user);
                this.is_admin = this.user.role === UserRole.Admin;
                i18next.changeLanguage(this.user.language || "en");
            }
            else {
                throw new Error("Not authenticated");
            }
        });
        this.setUserLanguage = (language) => {
            i18next.changeLanguage(language || "en");
            if (!this.user)
                return;
            this.user_service.patch(this.user.id, { language: language });
        };
        this.setActiveUser = (u) => __awaiter(this, void 0, void 0, function* () {
            this.active_user = u ? User.create(u) : null;
            return;
        });
        this.reAuthenticate = () => __awaiter(this, void 0, void 0, function* () {
            try {
                this.isAuthenticating = true;
                const res = yield client.reAuthenticate();
                this.setAuth(res);
            }
            catch (err) {
            }
            runInAction(() => this.isAuthenticating = false);
        });
        this.login = (email, password) => __awaiter(this, void 0, void 0, function* () {
            this.error_message = '';
            if (!email || !password) {
                this.error_message = 'Login requires an email and a password';
                return;
            }
            try {
                this.isAuthenticating = true;
                const res = yield client.authenticate({
                    strategy: 'local',
                    email,
                    password
                });
                this.setAuth(res);
            }
            catch (err) {
                console.error(err);
                console.error(err.message);
                if (err.message == 'Invalid login') {
                    runInAction(() => this.error_message = 'Invalid login');
                }
            }
            runInAction(() => this.isAuthenticating = false);
        });
        this.changePassword = (newPassword, oldPassword) => __awaiter(this, void 0, void 0, function* () {
            if (!this.getCurUser)
                return;
            yield this.user_service.patch(this.getCurUser.id, { password: newPassword }, paramsForServer({
                oldPassword: oldPassword
            }));
        });
        this.signUp = (email, password) => __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.user_service.create({ email, password });
                yield this.login(email, password);
            }
            catch (err) {
                console.error(err);
            }
        });
        this.logout = () => __awaiter(this, void 0, void 0, function* () {
            this.user = null;
            yield client.logout();
            client.get('storage').removeItem('feathers-jwt');
        });
        this.requestForgotPassword = (email) => __awaiter(this, void 0, void 0, function* () {
            try {
                console.log('Requesting password reset email');
                yield this.password_service.create({
                    email: email,
                    action: 'request',
                });
                console.log('Password reset email sent');
            }
            catch (error) {
                console.error(error);
                return;
            }
        });
        this.resendInvite = (user) => __awaiter(this, void 0, void 0, function* () {
            if (!this.isAdmin)
                return;
            try {
                yield this.invite_service.create(Object.assign(Object.assign({}, Data.defaultValues), { action: 'resend', id: user.id }));
            }
            catch (error) {
                console.error(error);
                return error;
            }
        });
        this.acceptInvite = (token, password) => __awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.invite_service.patch(token, {
                    action: 'accept',
                    token: token,
                    password: password,
                });
                return result;
            }
            catch (error) {
                console.error(error);
                return error;
            }
        });
        this.requestResetPassword = (token, password) => __awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield this.password_service.create({
                    token: token,
                    password: password,
                    action: 'reset',
                });
                return result;
            }
            catch (error) {
                console.error(error);
                return error;
            }
        });
        makeAutoObservable(this);
        this.rootStore = rootStore;
        this.reAuthenticate();
        when(() => this.isLoggedIn, () => {
            this.loadUsers({ role: { $in: [UserRole.Admin, UserRole.Sales, UserRole.Default] } }, this.users);
        });
        reaction(() => { var _a; return this.user && ((_a = this.user) === null || _a === void 0 ? void 0 : _a.id) && this.users[this.user.id]; }, () => {
            var _a;
            const id = (_a = this.user) === null || _a === void 0 ? void 0 : _a.id;
            if (!id)
                return;
            if (this.users[id])
                this.user = this.users[id];
        });
        this.user_service.on("created", (data) => this.setUserInContainer(data));
        this.user_service.on("removed", (data) => this.setUserInContainer(data, true));
        this.user_service.on("updated", (data) => this.setUserInContainer(data));
        this.user_service.on("patched", (data) => this.setUserInContainer(data));
        this.office_user_service.on("created", (data) => this.update_links(data, "created"));
        this.office_user_service.on("removed", (data) => this.update_links(data, "removed"));
        this.note_user_service.on("created", (data) => this.update_note_links(data, "created"));
        this.note_user_service.on("removed", (data) => this.update_note_links(data, "removed"));
    }
    get getCurUser() {
        return this.user;
    }
    get isLoggedIn() {
        return Boolean(this.user);
    }
    get getActiveUser() {
        return this.active_user;
    }
    get getUsers() {
        return Object.values(this.users).filter(u => !u.hidden);
    }
    get getSchedulerUsers() {
        var _a, _b;
        if (!((_b = (_a = this.rootStore) === null || _a === void 0 ? void 0 : _a.dataStore) === null || _b === void 0 ? void 0 : _b.getActiveOffice))
            return [];
        const users = this.getUsers.filter(u => { var _a, _b, _c; return u.office_links && u.office_links.includes((_c = (_b = (_a = this.rootStore) === null || _a === void 0 ? void 0 : _a.dataStore) === null || _b === void 0 ? void 0 : _b.getActiveOffice) === null || _c === void 0 ? void 0 : _c.id); });
        const temp_users = Object.values(this.temp_users).filter(u => { var _a, _b, _c; return u && !u.hidden && u.default_office === ((_c = (_b = (_a = this.rootStore) === null || _a === void 0 ? void 0 : _a.dataStore) === null || _b === void 0 ? void 0 : _b.getActiveOffice) === null || _c === void 0 ? void 0 : _c.id); });
        return [...temp_users, ...users];
    }
    get getTourUsers() {
        if (!this.rootStore.dataStore.getActiveTour)
            return [];
        return this.getUsers.filter(u => { var _a, _b, _c; return (u === null || u === void 0 ? void 0 : u.id) && !u.hidden && ((_c = (_b = (_a = this.rootStore) === null || _a === void 0 ? void 0 : _a.dataStore) === null || _b === void 0 ? void 0 : _b.activeTour) === null || _c === void 0 ? void 0 : _c.team_links) && this.rootStore.dataStore.activeTour.team_links.includes(u.id); });
    }
    get getDefaultOfficeId() {
        var _a;
        return ((_a = this.user) === null || _a === void 0 ? void 0 : _a.default_office) || -1;
    }
    get getDefaultOffice() {
        var _a;
        if (!((_a = this.user) === null || _a === void 0 ? void 0 : _a.default_office) || !this.rootStore.dataStore.getOffices[this.user.default_office])
            return null;
        return this.rootStore.dataStore.getOffices[this.user.default_office];
    }
    get isAdmin() {
        return this.is_admin;
    }
    get isSales() {
        if (!this.user)
            return false;
        return this.user.role === UserRole.Sales;
    }
    get isAdminOrSales() {
        if (!this.user)
            return false;
        if (this.user.role === UserRole.Sales || this.is_admin)
            return true;
        return false;
    }
}
