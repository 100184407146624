import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from "grommet";
export const TabButton = (props) => {
    const { tabIndex, tabValue, setTabIndex, title, badge, disabled } = props;
    const selected = tabIndex === tabValue;
    return (_jsxs(Box, Object.assign({ hoverIndicator: !disabled, round: "4px 4px 1px 1px", className: "tab-container", style: { position: "relative" }, onClick: () => !disabled && setTabIndex(tabIndex), pad: "small", border: {
            color: selected ? "brand" : "status-disabled",
            side: "bottom",
            size: "small"
        } }, { children: [_jsx(Text, Object.assign({ color: disabled ? "light-4" : undefined }, { children: title })), Boolean(badge) &&
                _jsx("div", Object.assign({ className: "tab-badge-content" }, { children: _jsx(Box, Object.assign({ round: "full", pad: { horizontal: "xsmall" }, background: "brand" }, { children: _jsx(Text, Object.assign({ size: "xsmall" }, { children: badge })) })) }))] })));
};
