import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from 'grommet';
import { Alert01Icon, AlertCircleIcon, Flag01Icon } from 'hugeicons-react';
import { inject, observer } from 'mobx-react';
import React from 'react';
const SystemIcon = ({ color, type }) => {
    switch (type) {
        case "warning":
            return _jsx(AlertCircleIcon, { color: color });
        case "error":
            return _jsx(Alert01Icon, { color: color });
        case "info":
            return _jsx(Flag01Icon, { color: color });
        default:
            return _jsx(_Fragment, {});
    }
};
export const SystemMessage = inject("rootStore")(observer((props) => {
    const { rootStore } = props;
    const [visible, setVisible] = React.useState(false);
    const [isMounted, setIsMounted] = React.useState(false);
    const textColor = (rootStore === null || rootStore === void 0 ? void 0 : rootStore.messageType) === "info" ? "white" : "#282300";
    React.useEffect(() => {
        if (rootStore === null || rootStore === void 0 ? void 0 : rootStore.showSystemMessage) {
            setIsMounted(true);
            setTimeout(() => setVisible(true), 10);
        }
        else {
            setVisible(false);
            setTimeout(() => setIsMounted(false), 300);
        }
    }, [rootStore === null || rootStore === void 0 ? void 0 : rootStore.showSystemMessage]);
    if (!isMounted)
        return null;
    return (_jsxs("div", Object.assign({ className: `system-message ${visible ? "enter" : "exit"} message-type-${rootStore === null || rootStore === void 0 ? void 0 : rootStore.messageType}` }, { children: [_jsx(SystemIcon, { color: textColor, type: rootStore === null || rootStore === void 0 ? void 0 : rootStore.messageType }), _jsx(Box, Object.assign({ fill: "horizontal", align: "center" }, { children: _jsx(Text, Object.assign({ color: textColor }, { children: rootStore === null || rootStore === void 0 ? void 0 : rootStore.messageText })) }))] })));
}));
