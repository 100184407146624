import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Box, List } from "grommet";
import { User } from "../../models";
import { WorkerListEntry } from "./workerListEntry";
export const WorkerList = inject('dataStore', 'userStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { dataStore, userStore } = props;
    const workers = (userStore === null || userStore === void 0 ? void 0 : userStore.getSchedulerUsers.filter(u => u.status !== "suspended" && (u.role === "default" || u.role == "temp")).sort(User.compare)) || [];
    return (_jsx(Box, Object.assign({ fill: true, basis: "1/2", className: "tourList", background: "white" }, { children: _jsx(Box, Object.assign({ className: "tourList-body", overflow: { vertical: "auto" } }, { children: _jsx(List, { data: workers, pad: "none", children: (user) => (_jsx(WorkerListEntry, { user: user }, user.id)) }) })) })));
}));
