const holidayData = require('./holidays.json');
import { getDay, isSameDay } from 'date-fns';
import { VehicleLicense } from './../types';
const holidays = holidayData['SE'];
export const locales = {
    'sv': 'sv-SE',
    'de': 'de-DE',
    'en': 'en-GB',
};
export const getAllHolidays = () => {
    const all = [];
    Object.keys(holidays).map(year => Object.keys(holidays[year]).map(d => all.push(`${year}-${d}`)));
    return all;
};
export const getAllHolidaysAsDate = () => {
    return [].concat(...Object.keys(holidays)
        .map(year => Object.keys(holidays[year])
        .map(d => new Date(`${year}-${d}`))));
};
export const getHolidaysByYear = (year) => {
    return holidays[year];
};
const allHolidays = getAllHolidaysAsDate();
export const isHolidayOrSunday = (date) => {
    const isHoliday = allHolidays.some(holiday => isSameDay(date, holiday));
    const isSunday = getDay(date) === 0;
    const isSaturday = getDay(date) === 6;
    return isHoliday || isSunday || isSaturday;
};
export const DriverLicenseOptions = [
    {
        label: 'B',
        value: VehicleLicense.B,
    },
    {
        label: 'C',
        value: VehicleLicense.C,
    },
    {
        label: 'CE',
        value: VehicleLicense.CE,
    },
    {
        label: "None",
        value: VehicleLicense.None,
    },
];
