import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Box, Text, Button } from "grommet";
import { getYear, getWeek, getDay, setWeek, setDay, setYear } from "date-fns";
import { WeekCalendarDay } from "./weekCalendarDay";
import { ArrowLeft01Icon, ArrowRight01Icon } from "hugeicons-react";
export const WeekCalendar = inject('dataStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { dataStore } = props;
    const [startInteraction, setStartInteraction] = React.useState(false);
    const [curYear, setCurYear] = React.useState(getYear(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate));
    const [curWeek, setCurWeek] = React.useState(getWeek(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate));
    const [curDay, setCurDay] = React.useState(getDay(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate));
    React.useEffect(() => {
        setTimeout(() => setStartInteraction(true), 300);
    }, [startInteraction]);
    React.useEffect(() => {
        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setDate(new Date());
    }, []);
    React.useEffect(() => {
        if (!startInteraction)
            return;
        if (curWeek > 52) {
            setCurYear(curYear + 1);
            setCurWeek(1);
            return;
        }
        if (curWeek < 1) {
            setCurYear(curYear - 1);
            setCurWeek(52);
            return;
        }
        let date = new Date();
        date = setYear(date, curYear);
        date = setWeek(date, curWeek);
        date = setDay(date, curDay);
        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setDate(date);
    }, [curWeek, curDay, startInteraction]);
    const counts = (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getTourCounts) || {};
    const status = (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getTourCompletionStatus) || {};
    return (_jsxs(Box, Object.assign({ className: "weekCalendar", fill: "horizontal", gap: "small", flex: false, style: { pointerEvents: !startInteraction ? 'none' : 'inherit', opacity: !startInteraction ? 0.3 : 1 } }, { children: [_jsx(Box, Object.assign({ className: "weekCalendar-header", direction: "row", justify: "between", align: "center" }, { children: _jsxs(Box, Object.assign({ direction: "row", gap: "none", align: "center", justify: "between", width: "100%" }, { children: [_jsx(Button, { primary: true, hoverIndicator: true, color: "white", icon: _jsx(ArrowLeft01Icon, {}), onClick: () => setCurWeek(curWeek - 1) }), _jsx(Text, Object.assign({ size: "var(--FormComponent-font-lg)" }, { children: t("week") + " " + curWeek })), _jsx(Button, { primary: true, hoverIndicator: true, color: "white", margin: { left: 'small' }, icon: _jsx(ArrowRight01Icon, {}), onClick: () => setCurWeek(curWeek + 1) })] })) })), _jsx(Box, Object.assign({ className: "weekCalendar-body", wrap: true, direction: "row", gap: "2px" }, { children: [1, 2, 3, 4, 5, 6, 0].map(d => {
                    let date = new Date();
                    date = setYear(date, curYear);
                    date = setWeek(date, curWeek);
                    date = setDay(date, d);
                    return (_jsx(WeekCalendarDay, { date: date, selected: d === curDay, count: counts[d], complete: status[d], onClick: () => setCurDay(d) }, "calendarday" + d));
                }) }))] })));
}));
