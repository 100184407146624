import { Data } from './data';
import { DataType } from './../types';
import { observable, makeObservable } from 'mobx';
import { formatISO } from 'date-fns';
export class TourUser extends Data {
    constructor(data) {
        super(DataType.TourUser, data);
        this.tour_id = null;
        this.user_id = null;
        this.team_id = null;
        this.check_in = null;
        this.check_out = null;
        this.date = null;
        makeObservable(this, {
            tour_id: observable,
            user_id: observable,
            team_id: observable,
            check_in: observable,
            check_out: observable,
            date: observable,
        });
        if (data)
            this.fromData(data);
    }
    static create(data) {
        return new TourUser(data);
    }
    fromData(data) {
        super.fromData(data);
        this.tour_id = Number(data.tour_id);
        this.user_id = Number(data.user_id);
        this.team_id = Number(data.team_id);
        this.check_in = data.check_in && new Date(data.check_in);
        this.check_out = data.check_out && new Date(data.check_out);
        this.date = data.date && new Date(data.date);
    }
    toData() {
        return Object.assign(Object.assign({}, super.toData()), { date: this.date && formatISO(this.date, { representation: "date" }), check_in: this.check_in && formatISO(this.check_in), check_out: this.check_out && formatISO(this.check_out), tour_id: this.tour_id, user_id: this.user_id, team_id: this.team_id });
    }
}
