var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Data } from './';
import { DataType } from './../types';
import { observable, makeObservable, action, set, computed } from 'mobx';
import { rootStore } from '../app';
import client from '../feathers';
import { formatISO } from 'date-fns';
export class Team extends Data {
    constructor(data) {
        super(DataType.Team, data);
        this.office_id = null;
        this.user_id = null;
        this.notified = false;
        this.name = null;
        this.date = null;
        this.tour_links = [];
        this.user_links = [];
        this.vehicle_links = [];
        this.user_info = {};
        this.link_service = client.service("team-users");
        this.setName = (name) => {
            this.name = name;
            this.save();
        };
        this.setUserId = (userId) => {
            this.user_id = userId;
            this.save();
        };
        this.setUserLinkInfo = (user_id, data, prop) => {
            if (prop)
                set(this.user_info[user_id], prop, data);
            else
                set(this.user_info, user_id, data);
        };
        this.toggleNotified = () => {
            this.notified ? this.notified = false : this.notified = true;
            this.save();
            return this.notified;
        };
        this.addLink = (type, id, data) => {
            switch (type) {
                case DataType.Tour:
                    this.tour_links.push(id);
                    break;
                case DataType.User:
                    this.user_links.push(id);
                    this.user_info[id] = (data || {});
                    break;
                case DataType.Vehicle:
                    this.vehicle_links.push(id);
                    break;
            }
        };
        this.removeLink = (type, id) => {
            if (!this.id)
                return;
            switch (type) {
                case DataType.Tour:
                    this.tour_links = this.tour_links.filter(l => l != id);
                    break;
                case DataType.User:
                    this.user_links = this.user_links.filter(l => l != id);
                    this.user_info[id] = {};
                    break;
                case DataType.Vehicle:
                    this.vehicle_links = this.vehicle_links.filter(l => l != id);
                    break;
            }
        };
        this.save_links = () => __awaiter(this, void 0, void 0, function* () {
            if (!this.user_info)
                return;
            try {
                for (const link of Object.values(this.user_info)) {
                    const data = {
                        from: link.from,
                        location: link.location,
                    };
                    yield this.link_service.patch(link.id, data);
                }
            }
            catch (error) {
                console.error("Failed to save object", this, error);
                return;
            }
        });
        makeObservable(this, {
            office_id: observable,
            name: observable,
            date: observable,
            tour_links: observable,
            user_links: observable,
            vehicle_links: observable,
            user_info: observable,
            setUserId: action,
            toggleNotified: action,
            setName: action,
            addLink: action,
            removeLink: action,
            setUserLinkInfo: action,
            getTeamTours: computed,
            getTeamUsers: computed,
            getTeamVehicles: computed,
        });
        this.fromData(data);
    }
    static create(data) {
        return new Team(data);
    }
    fromData(data) {
        super.fromData(data);
        this.name = data.name;
        this.office_id = Number(data.office_id);
        this.user_id = Number(data.user_id) || null;
        this.notified = data.notified || false;
        this.date = data.date && new Date(data.date);
        this.vehicle_links = data.vehicle_links && data.vehicle_links.map(l => Number(l)) || [];
        this.user_links = data.user_links && data.user_links.map(l => Number(l)) || [];
        this.tour_links = data.tour_links && data.tour_links.map(l => Number(l)) || [];
        this.user_info = data.user_info || {};
    }
    toData() {
        return Object.assign(Object.assign({}, super.toData()), { name: this.name, office_id: this.office_id, user_id: this.user_id || null, notified: this.notified || false, date: this.date && formatISO(this.date, { representation: "date" }), updated_by: undefined, created_by: undefined });
    }
    get getTeamVehicles() {
        return this.vehicle_links.map(l => rootStore.dataStore.getVehicle(l)).filter(l => Boolean(l)) || [];
    }
    get getTeamTours() {
        return this.tour_links.map(l => rootStore.dataStore.getTour(l)).filter(l => Boolean(l)) || [];
    }
    get getTeamUsers() {
        return this.user_links.map(l => rootStore.userStore.getUser(l)).filter(l => Boolean(l)) || [];
    }
}
