import { useEffect, useState } from "react";
export const viewportSize = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);
    const isMobile = width <= 768;
    const isDesktop = width > 768;
    return { isMobile, isDesktop };
};
