import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { inject, observer } from "mobx-react";
import { List, Box, Paragraph, Text } from "grommet";
import { useTranslation } from "react-i18next";
import { runInAction } from "mobx";
export const LimeDataList = inject("dataStore")(observer((props) => {
    const { t } = useTranslation();
    const { dataStore, prop } = props;
    const [overwriteBlock, setOverwriteBlock] = React.useState({});
    const activeTour = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour;
    const cur = activeTour ?
        (prop === "from" ? activeTour.from :
            (prop === "to" ? activeTour.to : activeTour)) || {}
        : {};
    const limeData = !(activeTour === null || activeTour === void 0 ? void 0 : activeTour.lime_data) ? {} : (activeTour === null || activeTour === void 0 ? void 0 : activeTour.lime_data[prop]) || {};
    const data = Object.keys(limeData).map(k => {
        const value = limeData[k];
        if (!value)
            return null;
        if (prop === "details" && !["volume", "invoiced"].includes(k))
            return null;
        return { key: k, value };
    }).filter(d => d !== null);
    const isDifferent = cur[data["key"]] && (cur[data["key"]] !== limeData[data["key"]]);
    React.useEffect(() => {
        runInAction(() => {
            if (prop && activeTour) {
                activeTour.overwrite_block = Object.assign(Object.assign({}, activeTour.overwrite_block), { [prop]: overwriteBlock });
            }
        });
    }, [overwriteBlock, activeTour, prop]);
    return (_jsx(List, { className: "limeDataList", pad: 'none', data: data, children: (data) => (_jsxs(Box, Object.assign({ direction: "row", justify: "between", pad: { left: "small" } }, { children: [_jsx(Box, Object.assign({ width: "100px", pad: { top: "xsmall" } }, { children: _jsx(Text, Object.assign({ weight: "bold" }, { children: t(data["key"]) })) })), _jsx(ListEntry, { old: false, isDifferent: isDifferent, blocked: overwriteBlock[data["key"]], text: data["value"], onBlock: () => setOverwriteBlock(prev => (Object.assign(Object.assign({}, prev), { [data["key"]]: !prev[data["key"]] }))) }), _jsx(ListEntry, { old: true, isDifferent: isDifferent, blocked: overwriteBlock[data["key"]], text: cur[data["key"]] || '', onBlock: () => setOverwriteBlock(prev => (Object.assign(Object.assign({}, prev), { [data["key"]]: !prev[data["key"]] }))) })] }))) }));
}));
const ListEntry = observer((props) => {
    const { text, blocked, isDifferent, old, onBlock } = props;
    const enabled = isDifferent && ((old && !blocked) || (!old && blocked));
    return (_jsx(Box, Object.assign({ hoverIndicator: enabled, onClick: enabled ? onBlock : undefined, round: "4px", width: "small", pad: "xsmall", background: (isDifferent && !old && !blocked) ?
            "status-critical-light" :
            (blocked && old ? "status-ok-light" : undefined) }, { children: _jsx(Paragraph, Object.assign({ style: { overflowWrap: "break-word" }, margin: "none" }, { children: text })) })));
});
