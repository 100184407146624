import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Navigate, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { LoadingView } from './views';
export var AppRoute;
(function (AppRoute) {
    AppRoute["Calendar"] = "/calendar";
    AppRoute["EmailDebug"] = "/email-debug";
    AppRoute["ForgotPassword"] = "/auth/forgot-password";
    AppRoute["Home"] = "/home";
    AppRoute["Invite"] = "/auth/invite";
    AppRoute["LimeDebug"] = "/lime-debug";
    AppRoute["Login"] = "/auth/login";
    AppRoute["Planner"] = "/planner";
    AppRoute["Profile"] = "/profile";
    AppRoute["ResetPassword"] = "/auth/reset-password";
    AppRoute["Resources"] = "/resources/*";
    AppRoute["Scheduler"] = "/scheduler";
    AppRoute["Settings"] = "/settings";
    AppRoute["SignUpView"] = "/auth/signup";
    AppRoute["TimeReport"] = "/timeReport";
    AppRoute["Auth"] = "/auth/*";
})(AppRoute || (AppRoute = {}));
;
export const PrivateRoute = inject('userStore')(observer(({ redirectTo, userStore, adminOnly, element }) => {
    const location = useLocation();
    if (userStore.isAuthenticating) {
        return _jsx(LoadingView, {});
    }
    if (userStore.isLoggedIn && (!adminOnly || userStore.isAdmin)) {
        return _jsx(_Fragment, { children: element });
    }
    return (_jsx(Navigate, { to: redirectTo || "/", state: { from: location.pathname } }));
}));
