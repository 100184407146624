var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Header, Layer, ResponsiveContext, Text } from "grommet";
import { Close } from "grommet-icons";
import { inject, observer } from "mobx-react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogType } from "../dialog";
import { EditNoteFooter } from "./editNoteFooter";
import { EditNoteForm } from "./editNoteForm";
export const EditNote = inject('dataStore', 'userStore')(observer((props) => {
    const { t, i18n } = useTranslation();
    const { dataStore } = props;
    const size = useContext(ResponsiveContext);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    if (!(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote))
        return null;
    return (_jsx(Layer, Object.assign({ onEsc: () => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveNote(null), onClickOutside: () => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveNote(null) }, { children: _jsxs(Box, Object.assign({ fill: "horizontal", overflow: "auto", width: !['large'].includes(size) ? 'medium' : undefined }, { children: [_jsx(Box, Object.assign({ height: { min: "650px" }, width: { min: size === "small" ? "360px" : "560px" }, justify: "between", flex: false }, { children: _jsxs(Box, Object.assign({ fill: "horizontal" }, { children: [_jsxs(Header, Object.assign({ justify: "between", pad: { top: "medium" }, style: { paddingLeft: size === "small" ? "1.5rem" : "2rem", paddingRight: "0.3rem" }, align: "center", direction: "row", fill: "horizontal" }, { children: [_jsx(Text, Object.assign({ size: "var(--FormComponent-font-lg)", weight: "bold" }, { children: t("note") })), _jsx(Button, { icon: _jsx(Close, {}), onClick: () => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveNote(null) })] })), _jsxs(Box, Object.assign({ overflow: "auto", style: { padding: size === "small" ? "1rem 1.5rem" : "2rem" }, fill: "horizontal" }, { children: [_jsx(EditNoteForm, {}), _jsx(EditNoteFooter, { onDelete: () => setShowDeleteDialog(true) })] }))] })) })), _jsx(Dialog, { message: t("confirm_delete_note"), show: showDeleteDialog, title: t("confirm_delete_dialog_title"), type: DialogType.Warning, confirmButtonLabel: t("delete"), cancelButtonLabel: t("cancel"), onCancel: () => setShowDeleteDialog(false), onConfirm: () => __awaiter(void 0, void 0, void 0, function* () {
                        if (dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeNote)
                            yield (dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeNote.remove());
                        setShowDeleteDialog(false);
                        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveNote(null);
                    }) })] })) })));
}));
