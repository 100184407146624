import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from 'grommet';
import { Document } from 'grommet-icons';
import { inject, observer } from 'mobx-react';
import { Cancel01Icon } from 'hugeicons-react';
export const FilePreview = inject('dataStore')(observer((props) => {
    const { dataStore } = props;
    if (!(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCommentFile))
        return null;
    const file = dataStore.getCommentFile;
    const getSizeString = () => {
        if (file.size === null)
            return "";
        if (file.size < 1000)
            return `${file.size} B`;
        else if (file.size < 1000000)
            return `${(file.size / 1000).toFixed(1)} kB`;
        else if (file.size < 1000 * 1000 * 1000)
            return `${(file.size / (1000 * 1000)).toFixed(1)} MB`;
        else
            return `${(file.size / (1000 * 1000 * 1000)).toFixed(1)} GB`;
    };
    return (_jsxs(Box, Object.assign({ className: "comment-file", align: "center", background: "light-4", direction: "row", pad: "xsmall", justify: "between", round: "4px", margin: { bottom: "xsmall" } }, { children: [_jsxs(Box, Object.assign({ direction: "row", align: "center", gap: "xsmall" }, { children: [_jsxs(Box, Object.assign({ align: "center" }, { children: [_jsx(Document, {}), _jsx(Text, Object.assign({ truncate: true, size: "xsmall" }, { children: getSizeString() }))] })), _jsx(Text, Object.assign({ truncate: true, size: "small" }, { children: file.name }))] })), _jsx(Box, Object.assign({ hoverIndicator: true, className: "comment-input-reply-cancel", onClick: () => dataStore.setCommentFile(null), align: "center", pad: "xsmall" }, { children: _jsx(Cancel01Icon, { size: 20, color: "#053162" }) }))] })));
}));
