import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from 'react';
import { CommentBase } from '.';
import { Box, ResponsiveContext } from 'grommet';
import { Down } from 'grommet-icons';
import { Comment } from '../../models';
import { observer, inject } from 'mobx-react';
export const CommentList = inject('userStore', 'dataStore')(observer((props) => {
    var _a, _b, _c, _d;
    const { dataStore, userStore } = props;
    const size = useContext(ResponsiveContext);
    const commentList = React.useRef(null);
    const [showDownButton, setShowDownButton] = React.useState(false);
    React.useEffect(() => {
        scrollToBottom();
    }, [Object.values((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getComments) || {}).length]);
    React.useEffect(() => {
        if (!commentList.current)
            return;
        setShowDownButton((commentList.current.clientHeight - (commentList.current.scrollHeight - commentList.current.scrollTop)) < 0);
    }, []);
    const scrollToBottom = () => {
        if (commentList.current) {
            commentList.current.scrollTop = commentList.current.scrollHeight;
        }
    };
    const created_comment = Comment.create({
        id: 0,
        text: "created",
        created_at: (_b = (_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour) === null || _a === void 0 ? void 0 : _a.created_at) !== null && _b !== void 0 ? _b : new Date(),
        item_id: (_d = (_c = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveTour) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : null,
        item_type: "tour",
        system: true,
        reply_id: null,
        location: null,
        file_id: null,
        file: null,
        reply_comment: null
    });
    return (_jsxs(Box, Object.assign({ ref: commentList, className: "comment-list-container", onScroll: () => {
            if (!commentList.current)
                return;
            setShowDownButton((commentList.current.clientHeight - (commentList.current.scrollHeight - Math.ceil(commentList.current.scrollTop))) < 0);
        }, width: { min: size === "small" ? undefined : "525px" }, round: "4px", pad: "xsmall", flex: false, height: "400px", overflow: {
            horizontal: "hidden",
            vertical: "auto"
        } }, { children: [_jsx(Box, Object.assign({ className: "comment-list-comments", gap: "small", flex: false }, { children: [created_comment].concat(Object.values((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getComments) || {})).map(d => {
                    var _a;
                    return (d && _jsx(CommentBase, { comment: d, left: d.created_by !== ((_a = userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) === null || _a === void 0 ? void 0 : _a.id) }, d.id));
                }) }), "comment-list-comments"), _jsx("div", Object.assign({ onClick: scrollToBottom, className: "comment-list-down-button" + (showDownButton ? "" : "-hidden") }, { children: _jsx(Down, {}) }), "scroll-button")] })));
}));
