var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Header, Layer, Box, Button, Text, Footer } from "grommet";
import { FormComponent } from "../../formComponents/formComponent";
import { Close, Save, Trash } from "grommet-icons";
import { inject, observer } from "mobx-react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { UserProps, DataType, UserStatus, UserRole } from "../../../types";
import { DriverLicenseOptions } from "../../../utils";
import { runInAction } from "mobx";
import toast from "react-hot-toast";
export const UserEdit = inject("dataStore", "userStore")(observer((props) => {
    const { t, i18n } = useTranslation();
    const close = () => userStore === null || userStore === void 0 ? void 0 : userStore.setActiveUser(null);
    const { dataStore, userStore, lock_office } = props;
    const user = (userStore === null || userStore === void 0 ? void 0 : userStore.getActiveUser) || null;
    const currentUser = (userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) || null;
    ;
    const UserRolesOptions = [
        {
            label: t("admin"),
            value: UserRole.Admin,
        },
        {
            label: t("sales"),
            value: UserRole.Sales,
        },
        {
            label: t("default"),
            value: UserRole.Default,
        },
    ];
    const UserStatusOptions = [
        {
            label: t("active"),
            value: UserStatus.Active,
        },
        {
            label: t("suspended"),
            value: UserStatus.Suspended,
        }
    ];
    const renderMultiSelectValue = (user) => {
        const office_strings = user.getLinkedOffices.map(o => o && o.name);
        return (_jsx(Box, Object.assign({ pad: "11px", flex: false, width: { max: "280px" } }, { children: _jsx(Text, Object.assign({ size: "18px", color: office_strings.length ? undefined : "dark-5", truncate: true }, { children: office_strings.length ? office_strings.join(", ") : t("select_office") })) })));
    };
    if (!user)
        return null;
    const leftColumn = [
        {
            prop: UserProps.FirstName,
            label: t("first_name"),
            autoFocus: true,
        },
        {
            prop: UserProps.LastName,
            label: t("last_name"),
        },
        {
            prop: UserProps.Alias,
            label: t("alias"),
        },
        {
            prop: UserProps.Phone,
            label: t("phone"),
        },
    ];
    return (_jsx(Layer, Object.assign({ onEsc: close, onClickOutside: close }, { children: _jsxs(Box, Object.assign({ round: "4px", style: { minHeight: "550px", minWidth: "400px" }, justify: "between" }, { children: [_jsxs(Box, Object.assign({ fill: "horizontal" }, { children: [_jsx(Header, Object.assign({ justify: "between", direction: "row", fill: "horizontal" }, { children: _jsx(Button, { hoverIndicator: true, icon: _jsx(Close, {}), onClick: close }) })), _jsxs(Box, Object.assign({ direction: "row", justify: "between" }, { children: [_jsx(Box, Object.assign({ fill: "horizontal" }, { children: leftColumn.map(c => (_jsx(Box, Object.assign({ pad: { vertical: "small", horizontal: "large" }, fill: "horizontal" }, { children: _jsx(FormComponent, { type: DataType.User, prop: c.prop, label: c.label, inputCmpProps: {
                                                autoFocus: c.autoFocus,
                                                value: user ? (user[c.prop] || '') : '',
                                                onChange: (event) => {
                                                    runInAction(() => user[c.prop] = event.target.value);
                                                },
                                            } }) }), c.prop))) })), _jsxs(Box, Object.assign({ fill: "horizontal" }, { children: [_jsx(Box, Object.assign({ pad: { vertical: "small", horizontal: "large" }, fill: "horizontal" }, { children: _jsx(FormComponent, { type: DataType.User, prop: UserProps.Email, label: t("email"), inputCmpProps: {
                                                    disabled: !((currentUser === null || currentUser === void 0 ? void 0 : currentUser.role) === UserRole.Admin && user.role === UserRole.Default),
                                                    value: user.email || '',
                                                    onChange: (event) => {
                                                        runInAction(() => user.email = event.target.value);
                                                    },
                                                } }) })), _jsx(Box, Object.assign({ pad: { vertical: "small", horizontal: "large" }, fill: "horizontal" }, { children: _jsx(FormComponent, { type: DataType.User, prop: UserProps.License, label: t("select_license"), inputType: "select", inputCmpProps: {
                                                    value: DriverLicenseOptions.find((v) => v.value == user.license),
                                                    labelKey: "label",
                                                    valueKey: "value",
                                                    placeholder: t("select_license"),
                                                    options: DriverLicenseOptions,
                                                    onChange: ({ option }) => {
                                                        runInAction(() => user.license = option.value);
                                                    },
                                                } }) })), _jsx(Box, Object.assign({ pad: { vertical: "small", horizontal: "large" }, fill: "horizontal" }, { children: _jsx(FormComponent, { type: DataType.User, prop: UserProps.Role, label: t("type"), inputType: "select", inputCmpProps: {
                                                    value: user.role === UserRole.Temporary ? UserRole.Temporary : UserRolesOptions.find((v) => v.value == user.role),
                                                    labelKey: "label",
                                                    valueKey: "value",
                                                    disabled: user.role === UserRole.Temporary,
                                                    placeholder: t("select_type"),
                                                    options: UserRolesOptions,
                                                    onChange: ({ option }) => {
                                                        runInAction(() => user.role = option.value);
                                                    },
                                                } }) })), _jsx(Box, Object.assign({ pad: { vertical: "small", horizontal: "large" }, fill: "horizontal" }, { children: _jsx(FormComponent, { type: DataType.User, inputType: "select", label: t("office"), inputCmpProps: {
                                                    autoFocus: false,
                                                    multiple: true,
                                                    closeOnChange: false,
                                                    options: Object.values((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOfficesUnfiltered) || {}),
                                                    labelKey: "name",
                                                    onChange: (event) => user.setOfficeLinks(event.value),
                                                    valueKey: "id",
                                                    disabled: lock_office,
                                                    value: user.getLinkedOffices,
                                                    valueLabel: renderMultiSelectValue(user),
                                                    placeholder: t("select_office"),
                                                } }) })), _jsx(Box, Object.assign({ pad: { vertical: "small", horizontal: "large" }, fill: "horizontal" }, { children: _jsx(FormComponent, { type: DataType.User, prop: UserProps.Status, label: t("status"), inputType: "select", inputCmpProps: {
                                                    value: UserStatusOptions.find((v) => v.value == user.status),
                                                    labelKey: "label",
                                                    valueKey: "value",
                                                    disabled: user.role === UserRole.Temporary,
                                                    placeholder: t("set_status"),
                                                    options: UserStatusOptions,
                                                    onChange: ({ option }) => {
                                                        runInAction(() => user.status = option.value);
                                                    },
                                                } }) })), (user.status === UserStatus.Invited) && (_jsx(Box, Object.assign({ pad: { vertical: "medium", horizontal: "large" }, fill: "horizontal" }, { children: _jsx(Button, { primary: true, icon: _jsx(Save, {}), label: t("resend_invite"), onClick: () => userStore === null || userStore === void 0 ? void 0 : userStore.resendInvite(user) }) })))] }))] }))] })), _jsxs(Footer, Object.assign({ pad: "large", justify: "between" }, { children: [_jsxs(Box, Object.assign({ direction: "row", gap: "small" }, { children: [_jsx(Button, { primary: true, icon: _jsx(Save, {}), label: t("save"), disabled: !user || !user.validate, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        try {
                                            yield user.save();
                                            toast.success(t("saved_user"));
                                        }
                                        catch (error) {
                                            toast.error(t("could_not_save_user"));
                                        }
                                        userStore === null || userStore === void 0 ? void 0 : userStore.setActiveUser(null);
                                    }) }), _jsx(Button, { hoverIndicator: true, icon: _jsx(Trash, {}), disabled: user.id === 0, tip: t("remove_user"), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        yield user.remove().then(() => toast.success(t("deleted_user"))).catch(() => toast.error(t("could_not_delete_user")));
                                        userStore === null || userStore === void 0 ? void 0 : userStore.setActiveUser(null);
                                    }) })] })), _jsxs(Box, { children: [_jsxs(Text, Object.assign({ style: { textAlign: "right" }, size: "small" }, { children: [t("created_at"), " ", format(user.created_at || new Date(), "yyyy-LL-dd HH:mm")] })), _jsxs(Text, Object.assign({ style: { textAlign: "right" }, size: "small" }, { children: [t("last_edited"), " ", format(user.updated_at || user.created_at || new Date(), "yyyy-LL-dd HH:mm")] }))] })] }))] })) })));
}));
