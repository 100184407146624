import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Text } from "grommet";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { AdvancedInfoList } from "./advancedInfoList";
import { Edit02Icon, EditOffIcon } from "hugeicons-react";
export const EditTourAdv = inject("dataStore")(observer((props) => {
    const { t, i18n } = useTranslation();
    const { dataStore } = props;
    const [enableEditFrom, setEnableEditFrom] = React.useState(true);
    const [enableEditTo, setEnableEditTo] = React.useState(true);
    const editFrom = (_jsx(Box, Object.assign({ round: true, hoverIndicator: true, pad: "xsmall", onClick: () => setEnableEditFrom(!enableEditFrom) }, { children: enableEditFrom ? (_jsx(EditOffIcon, {})) : (_jsx(Edit02Icon, { color: "white" })) })));
    const editTo = (_jsx(Box, Object.assign({ round: true, hoverIndicator: true, pad: "xsmall", onClick: () => setEnableEditTo(!enableEditTo) }, { children: enableEditTo ? (_jsx(EditOffIcon, {})) : (_jsx(Edit02Icon, { color: "white" })) })));
    return (_jsxs(Box, Object.assign({ width: "medium", gap: "xsmall", fill: "horizontal", style: { position: "relative" } }, { children: [_jsxs(Box, Object.assign({ fill: true, overflow: "auto", border: { side: "all" }, round: "4px", pad: "", onDoubleClick: () => setEnableEditFrom(true) }, { children: [_jsxs(Box, Object.assign({ background: "brand", pad: "xsmall", flex: false, direction: "row", align: "center", justify: "between" }, { children: [_jsx(Text, Object.assign({ weight: "bold" }, { children: t("from") })), editFrom] })), _jsx(AdvancedInfoList, { target: "from", editable: enableEditFrom })] })), _jsxs(Box, Object.assign({ fill: true, overflow: "auto", border: { side: "all" }, round: "4px", pad: "", onDoubleClick: () => setEnableEditTo(true) }, { children: [_jsxs(Box, Object.assign({ background: "brand", pad: "xsmall", flex: false, direction: "row", align: "center", justify: "between" }, { children: [_jsx(Text, Object.assign({ weight: "bold" }, { children: t("to") })), editTo] })), _jsx(AdvancedInfoList, { target: "to", editable: enableEditTo })] }))] })));
}));
