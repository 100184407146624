var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from "mobx-react";
import { Box, Button, Select } from "grommet";
import styled from "styled-components";
import { ArrowLeft01Icon, ArrowRight01Icon, MoreHorizontalIcon } from "hugeicons-react";
import { useTranslation } from "react-i18next";
export const PaginationFooter = observer((props) => {
    const { page, hideWhenOnlyOnePage, onChangePage, ellipsisStartBlockSize, totalPages = page.max_page + 1, selectPageSize } = props;
    const { t, i18n } = useTranslation();
    const _showEllipsis = ellipsisStartBlockSize !== undefined && totalPages > Math.max(6, ellipsisStartBlockSize + 2);
    const _blockSize = ellipsisStartBlockSize;
    const ellipsisEl = _jsx(MoreHorizontalIcon, {});
    const pageSizeOptions = selectPageSize === true ? [10, 20, 50, 100, page.total].filter(x => x <= page.total) : selectPageSize;
    const handlePageSizeChange = (size) => __awaiter(void 0, void 0, void 0, function* () {
        yield page.setPageSize(size);
        onChangePage && onChangePage(0);
    });
    let startBlock, endBlock, centerBlock;
    if (_showEllipsis) {
        startBlock =
            page.page < _blockSize - 1 ? (Array.from(Array(_blockSize).keys()).map((n) => (_jsx(PaginationButton, { page: page, pageNumber: n, onChangePage: onChangePage }, "pagination_button_start_" + n)))) : (_jsx(PaginationButton, { page: page, pageNumber: 0, onChangePage: onChangePage }));
        endBlock =
            page.page > totalPages - 3 ? (Array.from(Array(4).keys()).map((n) => (_jsx(PaginationButton, { page: page, pageNumber: totalPages - 4 + n, onChangePage: onChangePage }, "pagination_button_end_" + n)))) : (_jsx(PaginationButton, { page: page, pageNumber: totalPages - 1, onChangePage: onChangePage }));
        centerBlock =
            page.page < _blockSize - 1 || page.page > totalPages - 3 ? (ellipsisEl) : (_jsxs(_Fragment, { children: [ellipsisEl, Array.from(Array(3).keys()).map((n) => (_jsx(PaginationButton, { page: page, pageNumber: page.page - 1 + n, onChangePage: onChangePage }, "pagination_button_center_" + n))), ellipsisEl] }));
    }
    if (hideWhenOnlyOnePage && totalPages === 1)
        return null;
    return (_jsxs(Box, Object.assign({ "aria-label": "Pagination", className: "pagination-footer", direction: "row", justify: "between", align: "center", width: "100%", gap: "small" }, { children: [selectPageSize && (_jsx(Box, Object.assign({ width: "100px", className: "pagination-footer-size" }, { children: _jsx(Select, Object.assign({ options: pageSizeOptions || [], value: page.page_size, onChange: ({ option }) => handlePageSizeChange(option), plain: true }, { children: (option) => (_jsx(StyledButton, Object.assign({ style: { width: "100%" }, active: page.page_size === option }, { children: option }), option)) })) }))), _jsxs(Box, Object.assign({ direction: "row", align: "end", gap: "xsmall", pad: { vertical: "xsmall", horizontal: "xsmall" }, className: "pagination-footer-controls" }, { children: [_jsx(StyledButton, Object.assign({ style: { display: "flex" }, disabled: !page.page, onClick: () => {
                            page.previous();
                            onChangePage && onChangePage(page.page - 1);
                        } }, { children: _jsx(ArrowLeft01Icon, {}) })), _showEllipsis ? (_jsxs(_Fragment, { children: [startBlock, centerBlock, endBlock] })) : (Array.from(Array(totalPages).keys()).map((n) => (_jsx(PaginationButton, { page: page, pageNumber: n, onChangePage: onChangePage }, "pagination_button_" + n)))), _jsx(StyledButton, Object.assign({ style: { display: "flex" }, disabled: page.isLastPage, onClick: () => {
                            page.next();
                            onChangePage && onChangePage(page.page + 1);
                        } }, { children: _jsx(ArrowRight01Icon, {}) }))] }))] })));
});
export const PaginationButton = observer((props) => {
    const { page, onChangePage, pageNumber } = props;
    return (_jsx(StyledButton, Object.assign({ active: pageNumber === page.page, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
            yield page.loadPage(pageNumber);
            onChangePage && onChangePage(pageNumber);
        }) }, { children: pageNumber + 1 })));
});
const StyledButton = styled(Button) `
  text-decoration-line : ${props => props.active ? 'underline' : undefined};
  text-underline-offset: 0.2rem;
  padding-inline       : 0.75rem;
  padding-block        : 0.5rem;
  border-radius        : 6px;

  &:hover {
    background: ${props => props.disabled ? undefined : '#b9b9b96f'};
  }
`;
