var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { format } from "date-fns";
import { Box, Button, Footer, ResponsiveContext, Text } from "grommet";
import { Save, Trash } from "grommet-icons";
import { Tip } from "grommet/components/Tip";
import { inject, observer } from "mobx-react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
export const EditNoteFooter = inject("dataStore", "userStore")(observer((props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    const { t } = useTranslation();
    const { dataStore, userStore, onDelete } = props;
    const size = useContext(ResponsiveContext);
    return (_jsxs(Footer, Object.assign({ pad: { top: "medium" }, align: "flex-end", direction: size === "small" ? "column-reverse" : "row-reverse", justify: size === "small" ? "center" : "between" }, { children: [_jsx(Box, Object.assign({ style: { width: size === "small" && "100%" || undefined }, direction: "row-reverse", gap: "small" }, { children: _jsx(Button, { primary: true, style: { width: size === "small" && "100%" || undefined }, icon: _jsx(Save, {}), label: t("save"), onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        var _s, _t;
                        yield ((_s = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _s === void 0 ? void 0 : _s.save());
                        yield ((_t = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _t === void 0 ? void 0 : _t.save_user_links());
                        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveNote(null);
                    }), disabled: !((_a = dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeNote) === null || _a === void 0 ? void 0 : _a.validate) }) })), _jsxs(Box, Object.assign({ width: "100%", style: { paddingRight: size === "small" ? "none" : "1rem" }, direction: "row-reverse", justify: "between" }, { children: [((_b = dataStore === null || dataStore === void 0 ? void 0 : dataStore.activeNote) === null || _b === void 0 ? void 0 : _b.created_by) ? (_jsx(Button, { hoverIndicator: true, icon: _jsx(Trash, {}), onClick: onDelete, tip: t("remove_note") })) : (""), ((_c = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _c === void 0 ? void 0 : _c.created_by) ? (_jsxs(Box, Object.assign({ width: "100%", justify: "center", align: "flex-start" }, { children: [_jsx(Tip, Object.assign({ content: t("created_by") +
                                    " " +
                                    ((_e = userStore === null || userStore === void 0 ? void 0 : userStore.getUser((_d = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _d === void 0 ? void 0 : _d.created_by)) === null || _e === void 0 ? void 0 : _e.fullName) }, { children: _jsxs(Text, Object.assign({ size: "small", style: { textAlign: "right" } }, { children: [t("created_by"), _jsx("strong", { children: " " +
                                                ((_g = userStore === null || userStore === void 0 ? void 0 : userStore.getUser((_f = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _f === void 0 ? void 0 : _f.created_by)) === null || _g === void 0 ? void 0 : _g.displayAlias) +
                                                " " }), ((_h = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _h === void 0 ? void 0 : _h.created_at) ? format((_j = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _j === void 0 ? void 0 : _j.created_at, "yyyy-LL-dd HH:mm") : null] })) })), !!((_k = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _k === void 0 ? void 0 : _k.updated_by) && (_jsx(Tip, Object.assign({ content: t("updated_by") +
                                    " " +
                                    ((_m = userStore === null || userStore === void 0 ? void 0 : userStore.getUser((_l = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _l === void 0 ? void 0 : _l.updated_by)) === null || _m === void 0 ? void 0 : _m.fullName) }, { children: _jsxs(Text, Object.assign({ size: "small", style: { textAlign: "right" } }, { children: [t("updated_by"), _jsx("strong", { children: " " + ((_p = userStore === null || userStore === void 0 ? void 0 : userStore.getUser((_o = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _o === void 0 ? void 0 : _o.updated_by)) === null || _p === void 0 ? void 0 : _p.displayAlias) + " " }), ((_q = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _q === void 0 ? void 0 : _q.updated_at) ? format((_r = dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveNote) === null || _r === void 0 ? void 0 : _r.updated_at, "yyyy-LL-dd HH:mm") : null] })) })))] }))) : ("")] }))] })));
}));
