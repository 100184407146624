var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Grommet, ResponsiveContext, Box, Grid } from 'grommet';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AppRoute, PrivateRoute } from "./routes";
import { createRoot } from 'react-dom/client';
import 'intl-pluralrules';
import "./i18n";
import { Provider, observer } from 'mobx-react';
import { RootStore } from "./stores";
import { theme } from "./theme";
import { AppBar, Scheduler, Auth, CalendarView, LimeDebug, ResourceView, Settings, Profile } from "./views";
import './styles/main.scss';
import { EmailDebug } from "./views/emailDebug";
import { Planner } from "./views/planner";
import { Toaster } from "react-hot-toast";
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { sv } from 'date-fns/esm/locale';
import { createBrowserHistory } from 'history';
const browserHistory = createBrowserHistory();
setDefaultOptions({
    locale: sv,
    weekStartsOn: 1,
    firstWeekContainsDate: 3
});
export const rootStore = new RootStore();
const ResponsiveGrid = (_a) => {
    var { children, areas } = _a, props = __rest(_a, ["children", "areas"]);
    const size = React.useContext(ResponsiveContext);
    return (_jsx(Grid, Object.assign({ areas: areas[size] }, props, { fill: 'vertical' }, { children: children })));
};
const App = observer(() => {
    return (_jsx(Grommet, Object.assign({ theme: theme }, { children: _jsx(ResponsiveContext.Consumer, { children: size => (_jsx(ResponsiveGrid, Object.assign({ style: { minHeight: '100vh', minWidth: '100vw' }, responsive: true, columns: ['full'], rows: ['full'], gap: "none", areas: {
                    large: [
                        { name: 'headnav', start: [0, 0], end: [1, 0] },
                        { name: 'main', start: [1, 0], end: [0, 1] },
                    ],
                } }, { children: _jsx(Provider, Object.assign({}, rootStore, { children: _jsx(Router, { children: _jsxs("div", { children: [_jsx(Toaster, { position: "top-center", reverseOrder: false, toastOptions: {
                                        style: {
                                            color: "black",
                                            fontWeight: "bold",
                                        },
                                        success: {
                                            duration: 1500,
                                            iconTheme: {
                                                primary: "#053162",
                                                secondary: "white",
                                            }
                                        },
                                        error: {
                                            duration: 3000,
                                        }
                                    } }), _jsx(Box, Object.assign({ gridArea: "headnav", height: "4rem" }, { children: _jsx(AppBar, {}) })), _jsx(Box, Object.assign({ gridArea: "main", fill: true }, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(PrivateRoute, { redirectTo: AppRoute.Login, element: _jsx(CalendarView, {}) }) }), _jsx(Route, { path: AppRoute.Calendar, element: _jsx(PrivateRoute, { redirectTo: AppRoute.Login, element: _jsx(CalendarView, {}) }) }), _jsx(Route, { path: AppRoute.Scheduler, element: _jsx(PrivateRoute, { redirectTo: AppRoute.Login, adminOnly: true, element: _jsx(Scheduler, {}) }) }), _jsx(Route, { path: AppRoute.Planner, element: _jsx(PrivateRoute, { redirectTo: AppRoute.Login, element: _jsx(Planner, {}) }) }), _jsx(Route, { path: AppRoute.LimeDebug, element: _jsx(PrivateRoute, { redirectTo: AppRoute.Login, adminOnly: true, element: _jsx(LimeDebug, {}) }) }), _jsx(Route, { path: AppRoute.Resources, element: _jsx(PrivateRoute, { redirectTo: AppRoute.Login, element: _jsx(ResourceView, {}) }) }), _jsx(Route, { path: AppRoute.EmailDebug, element: _jsx(PrivateRoute, { redirectTo: AppRoute.Login, element: _jsx(EmailDebug, {}) }) }), _jsx(Route, { path: AppRoute.Settings, element: _jsx(PrivateRoute, { redirectTo: AppRoute.Login, element: _jsx(Settings, {}) }) }), _jsx(Route, { path: AppRoute.Profile, element: _jsx(PrivateRoute, { redirectTo: AppRoute.Login, element: _jsx(Profile, {}) }) }), _jsx(Route, { path: AppRoute.Auth, element: _jsx(Auth, {}) }), _jsx(Route, { path: "*", element: _jsx("h1", { children: "404: page not found" }) })] }) }))] }) }) })) }))) }) })));
});
const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(_jsx(App, {}));
}
