import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Anchor, Box, Button, Layer } from 'grommet';
import { Calendar01Icon, Calendar02Icon, Cancel01Icon, Logout01Icon } from 'hugeicons-react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AppRoute } from '../routes';
import { UserRole } from '../types';
import { useNavigate } from 'react-router-dom';
export const MobileMenu = inject("userStore", "dataStore")(observer((props) => {
    var _a;
    const { closeMenu, dataStore, userStore } = props;
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const menuRoutes = [
        {
            icon: _jsx(Calendar01Icon, { color: "white" }),
            label: t("calendar"),
            route: AppRoute.Calendar,
            onClick: () => {
                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveOffice(null);
                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveNote(null);
                dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(null);
            },
            allowedRoles: Object.values(UserRole),
        },
        {
            icon: _jsx(Calendar02Icon, { color: "white" }),
            label: t("planner"),
            route: AppRoute.Planner,
            allowedRoles: [UserRole.Admin, UserRole.Sales, UserRole.Default],
        },
        {
            icon: _jsx(Logout01Icon, { color: "white" }),
            label: t("logout"),
            onClick: () => { userStore === null || userStore === void 0 ? void 0 : userStore.logout(); },
            allowedRoles: Object.values(UserRole)
        },
    ];
    return (_jsx(Layer, Object.assign({ animation: "fadeIn" }, { children: _jsxs(Box, Object.assign({ background: "brand", height: "100%" }, { children: [_jsx(Box, Object.assign({ align: "end", pad: { top: "0.5rem" } }, { children: _jsx(Button, { icon: _jsx(Cancel01Icon, { color: "white" }), onClick: closeMenu }) })), ((userStore === null || userStore === void 0 ? void 0 : userStore.isLoggedIn) && ((_a = userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) === null || _a === void 0 ? void 0 : _a.role)) &&
                    menuRoutes.map((r) => {
                        var _a;
                        if (!r.allowedRoles.includes((_a = userStore === null || userStore === void 0 ? void 0 : userStore.getCurUser) === null || _a === void 0 ? void 0 : _a.role))
                            return null;
                        return (_jsx(StyledAnchor, { icon: r.icon, label: r.label, onClick: () => {
                                r.onClick && r.onClick();
                                navigate(r.route);
                                closeMenu();
                            }, className: location.pathname === r.route
                                ? "nav-link current"
                                : "nav-link" }, r.route));
                    })] })) })));
}));
const StyledAnchor = styled(Anchor) `
  :last-child {
    margin-top: 2rem;
  }
`;
