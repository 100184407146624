import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { inject, observer } from "mobx-react";
import { Teams } from "../components/scheduler/teams";
import { Box } from "grommet";
import { FormComponent } from "../components/formComponents/formComponent";
import { DataType, TourProps } from "../types";
import { useTranslation } from "react-i18next";
import { DateInput } from "../components/formComponents/dateInput";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
export const PlannerPrintView = inject('dataStore', 'userStore')(observer(React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { dataStore, userStore } = props;
    const selected_office = (dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveOffice) || (userStore === null || userStore === void 0 ? void 0 : userStore.getDefaultOffice) || null;
    React.useEffect(() => {
        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveOffice(selected_office);
    }, [dataStore === null || dataStore === void 0 ? void 0 : dataStore.getActiveOffice, userStore === null || userStore === void 0 ? void 0 : userStore.getDefaultOffice]);
    return (_jsx(DndProvider, Object.assign({ backend: HTML5Backend }, { children: _jsxs(Box, Object.assign({ pad: "medium", gap: "medium", ref: ref }, { children: [_jsxs(Box, Object.assign({ justify: "between", direction: "row", fill: "horizontal", pad: { horizontal: "small" } }, { children: [_jsx(Box, { children: _jsx(FormComponent, { type: DataType.Office, inputType: "select", prop: TourProps.Office_Id, label: t("office"), containerProps: {
                                    fill: "horizontal",
                                    gap: "small",
                                    direction: "row",
                                    align: "center",
                                }, labelProps: {
                                    size: "var(--FormComponent-font-lg)",
                                    weight: "bold"
                                }, inputCmpProps: {
                                    options: Object.values((dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOffices) || {}),
                                    labelKey: "name",
                                    valueKey: "id",
                                    placeholder: t("select_office"),
                                    value: selected_office || undefined,
                                    onChange: (event) => {
                                        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveTour(null);
                                        dataStore === null || dataStore === void 0 ? void 0 : dataStore.setActiveOffice(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getOfficeByID(event.option.id));
                                    },
                                } }) }), _jsx(Box, Object.assign({ direction: "row", align: "center", justify: "center", gap: "medium", alignContent: "center" }, { children: _jsx(Box, Object.assign({ width: "160px" }, { children: _jsx(DateInput, { selected: dataStore === null || dataStore === void 0 ? void 0 : dataStore.getCurDate, onChange: (date) => dataStore === null || dataStore === void 0 ? void 0 : dataStore.setDate(date), disabled: Boolean(dataStore === null || dataStore === void 0 ? void 0 : dataStore.searchString) }) })) }))] })), _jsx(Box, Object.assign({ fill: true, pad: "medium", overflow: "auto", className: "planner-teams", background: "white", width: { min: "1200px" } }, { children: _jsx(Teams, { readonly: true }) }))] })) })));
})));
