var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Box, Text, List, TextInput } from "grommet";
import { useTranslation } from "react-i18next";
import { DataType } from "../../types";
import { useDrag } from "react-dnd";
import { TimePicker } from "../timePicker/timePicker";
import { inject, observer } from "mobx-react";
import { parse } from "date-fns";
const spacing = [[],
    ["full"],
    ["3/4", "1/4"],
    ["1/2", "1/4", "1/4"],
    ["1/4", "1/4", "1/4", "1/4"],
];
const RowEntry = inject("teamStore")(observer((props) => {
    const { t, i18n } = useTranslation();
    const { row, data, columns, columnTypes, readonly } = props;
    const [{ isDragging }, drag] = useDrag(() => ({
        type: DataType.Link,
        item: { item: data, type: DataType.Link },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));
    const [isLeader, setLeader] = useState(false);
    useEffect(() => {
        if (data.type !== "users")
            return;
        if (data.team.getTeamUsers.length === 1) {
            data.team.setUserId(data.data.id);
            setLeader(true);
            return;
        }
        if (data.team.user_id === data.data.id) {
            setLeader(true);
            return;
        }
        const userIds = data.team.getTeamUsers.map((u) => u.id);
        if (!userIds.includes(data.team.user_id)) {
            data.team.setUserId(userIds[0]);
            return;
        }
        else {
            setLeader(false);
            return;
        }
    }, [data.team.user_id, data.team.getTeamUsers.length]);
    const renderCell = (c, idx) => {
        if (!columnTypes)
            return _jsx(Text, Object.assign({ truncate: true }, { children: data[c] }));
        switch (columnTypes[idx]) {
            case "time":
                return (_jsx(TimePicker, { cancelLabel: t("cancel"), confirmLabel: t("ok"), value: data[c], onChange: (time) => {
                        data.team.setUserLinkInfo(data.data.id, parse(time, "HH:mm", new Date()), "from");
                        data.team.save_links();
                    }, hideIcon: true }));
            case "input":
                return (_jsx(TextInput, { textAlign: "end", value: data[c] || "", onChange: (e) => data.team.setUserLinkInfo(data.data.id, e.target.value, "location"), onBlur: () => data.team.save_links(), style: {
                        border: "none",
                        padding: "0px",
                        backgroundColor: "inherit"
                    } }));
            default:
                return _jsxs(Text, Object.assign({ style: (idx === 0 && isLeader) ? { textDecorationLine: "underline" } : { textDecorationLine: "none" }, onDoubleClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        if (!readonly && idx === 0)
                            data.team.setUserId(data.data.id);
                    }), truncate: true }, { children: [data[c], idx === 0 && isLeader && "★"] }));
        }
    };
    return (_jsx(Box, Object.assign({ fill: "horizontal", direction: "row", onClick: () => { }, ref: readonly ? undefined : drag, hoverIndicator: true }, { children: columns.map((c, idx) => (_jsx(Box, Object.assign({ basis: spacing[columns.length][idx], align: Boolean(idx) ? "end" : undefined }, { children: renderCell(c, idx) }), "team-card-list-entry-" + data.type + "-" + data.team.id + "-" + data.data.id + "-" + c))) }), "team-card-list-row-" + data.type + "-" + data.team.id + "-" + data.data.id));
}));
export const TeamCardSection = inject("teamStore")(observer((props) => {
    const { t, i18n } = useTranslation();
    const { columns, columnLabels, columnTypes, data, readonly } = props;
    const isEmpty = !Boolean(data.length);
    return (_jsxs(Box, Object.assign({ className: "team-card-tours", flex: false, margin: { bottom: "xsmall" } }, { children: [_jsx(Box, Object.assign({ fill: "horizontal", direction: "row", pad: { top: "xsmall", horizontal: "small" } }, { children: columnLabels.map((c, idx) => (_jsx(Box, Object.assign({ basis: spacing[columns.length][idx], align: Boolean(idx) ? "end" : undefined }, { children: _jsx(Text, Object.assign({ weight: "bold" }, { children: c })) }), "team-card-label-" + c))) })), isEmpty ?
                _jsx(Box, Object.assign({ pad: { left: "15px" } }, { children: _jsx(Text, { children: "-" }) }))
                : _jsx(List, { pad: {
                        horizontal: "small",
                        top: "xsmall"
                    }, border: false, data: data, children: (d, row) => (_jsx(RowEntry, { row: row, data: d, columns: columns, columnTypes: columnTypes, readonly: readonly }, "parent" + d.type + "-" + d.team.id + "-" + d.data.id)) })] })));
}));
