import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Box } from "grommet";
import { TourLabel } from "../../types";
import { AirplaneLanding01Icon, AirplaneTakeOff01Icon, BrushIcon, CleaningBucketIcon, CursorLoading01Icon, Database01Icon, DeliveryDelay01Icon, EarthIcon, EuroIcon, House01Icon, PackageIcon, PackageMovingIcon, PackageReceiveIcon, StoreAdd01Icon, StoreRemove01Icon, TaskDone01Icon, Time01Icon, Link01Icon, LinkBackwardIcon, Notification01Icon } from "hugeicons-react";
const sizes = {
    small: 10,
    medium: 14,
    large: 16,
};
const icons = {
    domestic: EarthIcon,
    europe: EuroIcon,
    import: AirplaneLanding01Icon,
    export: AirplaneTakeOff01Icon,
    local: House01Icon,
    assist: CursorLoading01Icon,
    internal: Database01Icon,
    art: BrushIcon,
    inspection: TaskDone01Icon,
    delivery: PackageIcon,
    cleaning: CleaningBucketIcon,
    extradition: Time01Icon,
    add_store: StoreAdd01Icon,
    remove_store: StoreRemove01Icon,
    boxes_in: PackageReceiveIcon,
    boxes_out: PackageMovingIcon,
    delayed: DeliveryDelay01Icon,
    linked: Link01Icon,
    unlinked: LinkBackwardIcon,
    notified: Notification01Icon,
};
const getContrastingColor = (hex) => {
    const hexColor = hex.replace('#', '');
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance > 0.5 ? 'black' : 'white';
};
const backgroundColors = {
    local: "#000FFF",
    europe: "#008216",
    import: "#FF0000",
    export: "#FF8700",
    domestic: "#008BFF",
    add_store: "#23FF00",
    remove_store: "#920808",
    boxes_in: "#6A6A6A",
    boxes_out: "#373737",
    assist: "#06FEF6",
    internal: "#06FEF6",
    art: "#FF00C1",
    delivery: "#4C007A",
    inspection: "#4C007A",
    extradition: "#873600",
    cleaning: "#873600",
    delayed: "#ff0000",
    linked: "#000000",
    unlinked: "#ffffff",
    notified: "#ffffff",
};
const iconColor = Object.keys(backgroundColors).reduce((acc, key) => {
    const bgColor = backgroundColors[key];
    acc[key] = getContrastingColor(bgColor);
    return acc;
}, {});
const MyTourIcon = ({ size, color, type: IconComponent, className, }) => {
    if (IconComponent === null)
        return _jsx(_Fragment, {});
    return _jsx(IconComponent, { size: size, color: color, className: className });
};
export const TourIcon = ({ size = 'medium', type, }) => {
    if (type === TourLabel.None) {
        return (_jsx(Box, { width: "18px", height: "18px", background: "white", round: "4px", border: {
                size: "xsmall",
                side: "all"
            } }));
    }
    const IconComponent = icons[type];
    return (_jsx(Box, Object.assign({ background: backgroundColors[type], round: "4px", width: "18px", height: "18px", align: "center", justify: "center" }, { children: _jsx(MyTourIcon, { size: sizes[size], color: iconColor[type], type: IconComponent || null, className: `${size} ${type}` }) })));
};
