import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Box, Button } from "grommet";
const ClockButtonStyled = styled(Button) `
    position: absolute;
    top:      50%; 
    left:     50%;
    --rot:    calc(${props => props.idx} * 1turn / ${props => props.numItems});
    transform: 
        translate(-50%, -50%)
        rotate(var(--rot)) 
        translate(0px, -${props => props.radius})
        rotate(calc(-1 * var(--rot)));
`;
export const ClockButton = (props) => {
    const { idx, radius, numItems, onClick, selected, children } = props;
    return (_jsx(ClockButtonStyled, Object.assign({ idx: idx % 12, radius: `${radius}px`, numItems: numItems }, { children: _jsx(Box, Object.assign({ hoverIndicator: true, pad: "xsmall", round: "full", onClick: onClick, width: "2em", height: "2em", justify: "center", align: "center", background: selected ? "brand" : "inherit" }, { children: children })) })));
};
