import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text } from "grommet";
import { format } from 'date-fns';
import { inject, observer } from "mobx-react";
import { Cancel01Icon, MailReply01Icon } from "hugeicons-react";
export const ReplyPreview = inject('dataStore')(observer((props) => {
    var _a;
    const { dataStore } = props;
    if (!(dataStore === null || dataStore === void 0 ? void 0 : dataStore.getReplyComment) || dataStore.getCommentFile)
        return null;
    return (_jsxs(Box, Object.assign({ className: "comment-input-reply", direction: "row", justify: "between", pad: "xsmall", alignContent: "center", background: "light-2" }, { children: [_jsxs(Box, Object.assign({ direction: "row", gap: "xsmall", align: "center" }, { children: [_jsx(MailReply01Icon, { size: 20, color: "#053162" }), _jsxs(Box, { children: [_jsx(Text, Object.assign({ weight: "bold", size: "xsmall" }, { children: format((_a = dataStore.getReplyComment.created_at) !== null && _a !== void 0 ? _a : new Date(), "yyyy-LL-dd HH:mm") })), _jsx(Text, Object.assign({ size: "xsmall", truncate: true }, { children: dataStore.getReplyComment.text }))] })] })), _jsx(Box, Object.assign({ hoverIndicator: true, className: "comment-input-reply-cancel", onClick: () => dataStore.setReplyComment(null), align: "center", pad: "xsmall" }, { children: _jsx(Cancel01Icon, { size: 20, stroke: "#053162" }) }))] })));
}));
